import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Close from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import Grid2 from "@mui/material/Grid2";
import PriceList from "./PriceList";
import React from "react";
import Typography from "@mui/material/Typography";

export default function PriceDrawer({
  open,
  onClose,
  billings,
  onDelete,
  onReset,
  count,
  isModification,
}) {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box sx={{ maxWidth: 400, padding: 2 }}>
        <Grid2 container spacing={2}>
          <Grid2 size={10}>
            <Typography variant="h6">Tarifs</Typography>
          </Grid2>
          <Grid2 size={2} sx={{ textAlign: "right" }}>
            <Button onClick={onClose}>
              <Close />
            </Button>
          </Grid2>
          <Grid2 size={12} />
          <Grid2 size={12} sx={{ textAlign: "center" }}>
            <PriceList
              billings={billings}
              onDelete={onDelete}
              count={count}
              onReset={onReset}
              isModification={isModification}
            />
          </Grid2>
        </Grid2>
      </Box>
    </Drawer>
  );
}
