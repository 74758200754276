import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useBillSubscription,
  useBillingDetails,
  useDecrementSubscriptionCategory,
  useDeleteSubscription,
  useDeleteSubscriptionBilling,
  useRefundSubscription,
  useUpdateSubscriptionCategory,
  useValidateSubscription,
} from "../../../Features/Mutations/clubSubscription";
import { useGet, usePatch } from "../../../Features/apiFetch";
import {
  BILLING_STATE,
  CONTEST_STATE,
  SUBSCRIPTION_STATE,
} from "../../../Features/enums";
import {
  useGetUser,
  useIsAdmin,
  useIsModerator,
} from "../../../Features/login";

import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid2 from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import moment from "moment";
import { useRecoilState } from "recoil";
import { loadingAtom } from "../../../Features/atom";
import { getLastName } from "../../../Features/utils";
import ModalDialog from "../../ModalDialog/ModalDialog";
import ContestManageBar from "./ContestManageBar";
import SubscriptionCard from "./SubscriptionCard";

export default function ContestManage() {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(null);
  const [filters, setFilters] = useState([]);
  const [folds, setFolds] = useState(false);
  const [sort, setSort] = useState("sub_date_desc");
  const user = useGetUser();
  const navigate = useNavigate();
  const { contestGuid } = useParams();
  const get = useGet();
  const isAdmin = useIsAdmin(user);
  const isModerator = useIsModerator(user);
  const [loading, setLoading] = useRecoilState(loadingAtom); // eslint-disable-line no-unused-vars

  useEffect(() => {
    if (user.isLoading) return;

    if (!isModerator) {
      navigate("/403");
    }
  }, [user, navigate, isModerator]);

  const contestQuery = useQuery({
    queryKey: [`contest_${contestGuid}`],
    queryFn: () => {
      return get(`/contest/${contestGuid}`);
    },
  });

  const contest = useMemo(() => {
    return contestQuery?.data?.data;
  }, [contestQuery]);

  const subscriptionsQuery = useQuery({
    queryKey: [`subscriptions_${contestGuid}`],
    queryFn: () => {
      return get(`/contest/${contestGuid}/subscriptions`);
    },
  });

  const subscriptions = useMemo(() => {
    if (subscriptionsQuery?.isLoading || subscriptionsQuery?.isError) {
      return [];
    }
    if (subscriptionsQuery?.data.statusCode === 200) {
      const sortedSubscriptions = subscriptionsQuery?.data?.data;
      sortedSubscriptions.sort((a, b) => {
        if (sort === "sub_date_asc") {
          return moment(a.creationDateTime) - moment(b.creationDateTime);
        } else if (sort === "sub_date_desc") {
          return moment(b.creationDateTime) - moment(a.creationDateTime);
        } else if (sort === "first_name_asc") {
          return a.subscriberName.localeCompare(b.subscriberName);
        } else if (sort === "first_name_desc") {
          return b.subscriberName.localeCompare(a.subscriberName);
        } else if (sort === "last_name_asc") {
          const aLastName =
            getLastName(a.subscriberName) +
            " " +
            a.subscriberName.split(" ")[0];
          const bLastName =
            getLastName(b.subscriberName) +
            " " +
            b.subscriberName.split(" ")[0];
          return aLastName.localeCompare(bLastName);
        } else if (sort === "last_name_desc") {
          const aLastName =
            getLastName(a.subscriberName) +
            " " +
            a.subscriberName.split(" ")[0];
          const bLastName =
            getLastName(b.subscriberName) +
            " " +
            b.subscriberName.split(" ")[0];
          return bLastName.localeCompare(aLastName);
        } else {
          return a;
        }
      });
      return sortedSubscriptions;
    } else {
      return [];
    }
  }, [subscriptionsQuery, sort]);

  const counters = useMemo(() => {
    const counters = {};
    if (subscriptionsQuery?.isLoading || subscriptionsQuery?.isError) {
      return counters;
    }

    const subscriptions = subscriptionsQuery?.data?.data;
    if (subscriptions) {
      counters["toConfirm"] = subscriptions.filter(
        (s) => s.subscriptionState === SUBSCRIPTION_STATE.CREATED
      ).length;
      counters["confirmed"] = subscriptions.filter(
        (s) => s.subscriptionState === SUBSCRIPTION_STATE.ACCEPTED
      ).length;

      // count distinct subscriberName
      counters["riders"] = new Set(
        subscriptions.map((s) => s.subscriberName)
      ).size;

      counters["subscriptions"] = subscriptions.filter(
        (s) =>
          s.subscriptionState === SUBSCRIPTION_STATE.CREATED ||
          s.subscriptionState === SUBSCRIPTION_STATE.ACCEPTED
      ).length;

      // count in each subcriptions the count of categories
      let totalReprises = 0;
      for (const subscription of subscriptions) {
        if (
          subscription.subscriptionState === SUBSCRIPTION_STATE.ACCEPTED ||
          subscription.subscriptionState === SUBSCRIPTION_STATE.CREATED
        ) {
          for (const dateKey in subscription.categories) {
            for (const category of subscription.categories[dateKey]) {
              totalReprises += category.count;
            }
          }
        }
      }
      counters["reprises"] = totalReprises;

      console.log("counters", counters);
    }
    return counters;
  }, [subscriptionsQuery]);

  const mutationCategory = useUpdateSubscriptionCategory(
    contestGuid,
    subscriptions
  );
  const mutationBill = useBillSubscription(contestGuid, subscriptions);
  const mutationRefund = useRefundSubscription(contestGuid, subscriptions);
  const mutationBillingDetails = useBillingDetails(contestGuid, subscriptions);
  const mutationDecrementCategory = useDecrementSubscriptionCategory(
    contestGuid,
    subscriptions
  );
  const mutationDeleteSubscription = useDeleteSubscription(
    contestGuid,
    subscriptions
  );
  const mutationValidateSubscription = useValidateSubscription(
    contestGuid,
    subscriptions
  );
  const mutateDeleteSubscriptionBilling = useDeleteSubscriptionBilling(
    contestGuid,
    subscriptions
  );

  const isUserAllowedToArchive = useMemo(() => {
    return user && isAdmin;
  }, [user, isAdmin]);

  const patch = usePatch();
  const queryClient = useQueryClient();
  const performCancelContest = useCallback(async () => {
    try {
      setLoading(true);
      await patch(`/contest/${contestGuid}/cancel`, null, false);
      queryClient.invalidateQueries({ queryKey: ["contests"] });
      queryClient.invalidateQueries({ queryKey: [`contest_${contestGuid}`] });
      queryClient.invalidateQueries({
        queryKey: [`subscriptions_${contestGuid}`],
      });
    } finally {
      setLoading(false);
    }
  }, [setLoading, patch, contestGuid, queryClient]);

  const handleConfirmCancelContest = useCallback(async () => {
    setConfirmDialogOpen(null);
    await performCancelContest();
  }, [performCancelContest]);

  const handleClose = useCallback(() => {
    setConfirmDialogOpen(null);
  }, []);

  const handleCancelContest = useCallback(() => {
    if (
      subscriptions?.filter(
        (s) =>
          s.subscriptionState === SUBSCRIPTION_STATE.CREATED ||
          s.subscriptionState === SUBSCRIPTION_STATE.ACCEPTED
      ).length > 0
    ) {
      let detail = "Des personnes se sont déjà inscrites à ce concours.";
      if (
        contest.payment?.mode === "online" &&
        subscriptions?.filter((s) => s.billingState === BILLING_STATE.PAID)
          .length > 0
      ) {
        detail +=
          " Des inscriptions ont déjà été facturées, elles seront toutes remboursées.";
      }

      detail += " Êtes-vous sûr de vouloir le déplanifier?";

      setConfirmDialogOpen({
        title: "Annuler ce concours?",
        content: detail,
        onConfirm: handleConfirmCancelContest,
      });
    } else {
      performCancelContest(contestGuid);
    }
  }, [
    subscriptions,
    contest,
    handleConfirmCancelContest,
    performCancelContest,
    contestGuid,
  ]);

  const performArchiveContest = useCallback(async () => {
    try {
      setConfirmDialogOpen(null);
      setLoading(true);
      await patch(`/contest/${contestGuid}/archive`, null, false);
      queryClient.invalidateQueries({ queryKey: ["contests"] });
      queryClient.invalidateQueries({ queryKey: [`contest_${contestGuid}`] });
      queryClient.invalidateQueries({
        queryKey: [`subscriptions_${contestGuid}`],
      });
    } finally {
      setLoading(false);
      navigate("/contests");
    }
  }, [setLoading, patch, contestGuid, queryClient, navigate]);

  const handleArchiveContest = useCallback(async () => {
    const toBillCount = subscriptions?.filter(
      (s) => s.billingState === BILLING_STATE.TO_BILL
    ).length;

    const failedCount = subscriptions?.filter(
      (s) =>
        s.billingState === BILLING_STATE.FAILED ||
        s.billingState === BILLING_STATE.PROCESSING ||
        s.billingState === BILLING_STATE.REQUIRES_ACTION ||
        s.billingState === BILLING_STATE.REQUIRES_PAYMENT_METHOD ||
        s.billingState === BILLING_STATE.REQUIRES_CAPTURE ||
        s.billingState === BILLING_STATE.REQUIRES_CONFIRMATION
    ).length;

    if (toBillCount > 0 && failedCount === 0) {
      setConfirmDialogOpen({
        content:
          contest.payment?.mode === "online"
            ? "Il y a des inscriptions à ce concours qui n'ont pas encore été facturées, elles le seront toutes automatiquement. Si certains paiements échouent vous pourrez les retrouver dans la section Facturation de l'onglet Administration."
            : "Il y a des inscriptions à ce concours qui n'ont pas encore été facturées, elles le seront toutes automatiquement.",
        onConfirm: performArchiveContest,
      });
    } else if (toBillCount === 0 && failedCount > 0) {
      setConfirmDialogOpen({
        content:
          "Il y a des inscriptions à ce concours qui sont en cours de paiement ou en erreur. Vous pourrez les retrouver dans la section Facturation de l'onglet Administration afin de faire le recouvrement.",
        onConfirm: performArchiveContest,
      });
    } else if (toBillCount > 0 && failedCount > 0) {
      setConfirmDialogOpen({
        content:
          "Il y a des inscriptions à ce concours qui n'ont pas encore été facturées et d'autres qui sont en erreur. Nous allons tenter de les facturer automatiquement. Vous pourrez retrouver les détails dans la section Facturation de l'onglet Administration afin de faire le recouvrement si nécessaire.",
        onConfirm: performArchiveContest,
      });
    } else if (
      contest.state === CONTEST_STATE.CANCELED &&
      subscriptions?.filter((s) => s.billingState === BILLING_STATE.PAID)
        .length > 0
    ) {
      setConfirmDialogOpen({
        content:
          "Il y a des inscriptions à ce concours qui ont été facturées alors qu'il a été annulé. Il n'est pas possible de l'archiver pour le moment. Vous pouvez les retrouver dans la section Facturation de l'onglet Administration.",
      });
    } else {
      setConfirmDialogOpen({
        title: "Archiver ce concours?",
        content:
          "Êtes-vous sûr de vouloir archiver ce concours? Une fois archivé, il ne sera plus possible de le modifier.",
        onConfirm: performArchiveContest,
      });
    }
  }, [contest, subscriptions, setConfirmDialogOpen, performArchiveContest]);

  const handleFiltersChange = useCallback(
    (filters) => {
      setFilters(filters);
    },
    [setFilters]
  );

  const handleChangeMailingMode = useCallback(async () => {
    try {
      setLoading(true);
      await patch(`/contest/${contestGuid}/mailing-mode`, null, false);
      queryClient.invalidateQueries({ queryKey: [`contest_${contestGuid}`] });
    } finally {
      setLoading(false);
    }
  }, [setLoading, patch, contestGuid, queryClient]);

  const handleChangeFoldings = useCallback(() => {
    setFolds(!folds);
  }, [folds]);

  const handleSort = useCallback(
    (newSort) => {
      setSort(newSort);
    },
    [setSort]
  );

  const handleCloseRegistrations = useCallback(async () => {
    try {
      setLoading(true);
      await patch(`/contest/${contestGuid}/close-registrations`, null, false);
      queryClient.invalidateQueries({ queryKey: [`contest_${contestGuid}`] });
      queryClient.invalidateQueries({ queryKey: [`contests`] });
    } finally {
      setLoading(false);
    }
  }, [setLoading, patch, contestGuid, queryClient]);

  useEffect(() => {
    if (!contestQuery.isLoading && contest.status && contest.status >= 400) {
      navigate(`/404`);
    }
  }, [contest, navigate, contestQuery]);

  return (
    <>
      <ModalDialog config={confirmDialogOpen} onClose={handleClose} />
      <Grid2
        container
        spacing={0}
        sx={{
          maxWidth: "100%",
          marginTop: "86px",
        }}
      >
        <ContestManageBar
          contest={contest}
          onCancelContest={handleCancelContest}
          onArchiveContest={handleArchiveContest}
          onChangeMailingMode={handleChangeMailingMode}
          isUserAllowedToArchive={isUserAllowedToArchive}
          onChange={handleFiltersChange}
          folds={folds}
          onChangeFoldings={handleChangeFoldings}
          onCloseRegistrations={handleCloseRegistrations}
          onSort={handleSort}
          counters={counters}
        />
      </Grid2>
      <Container sx={{ mx: "auto", mt: 4, mb: 4 }}>
        <Stack spacing={1} sx={{ width: "100%" }}>
          {subscriptions?.map((subscription) => (
            <SubscriptionCard
              subscription={subscription}
              contest={contest}
              key={subscription.guid}
              updateCategoryMutation={mutationCategory}
              billSubscriptionMutation={mutationBill}
              refundSubscriptionMutation={mutationRefund}
              billingDetailsMutation={mutationBillingDetails}
              decrementCategoryMutation={mutationDecrementCategory}
              deleteSubscriptionMutation={mutationDeleteSubscription}
              validateSubscriptionMutation={mutationValidateSubscription}
              deleteSubscriptionBillingMutation={
                mutateDeleteSubscriptionBilling
              }
              filters={filters}
              folds={folds}
            />
          ))}
          {(contestQuery.isLoading || subscriptionsQuery?.isLoading) && (
            <Box sx={{ display: "flex" }}>
              <CircularProgress sx={{ margin: "auto" }} />
            </Box>
          )}
          {!contestQuery.isLoading &&
            !subscriptionsQuery?.isLoading &&
            subscriptions?.length === 0 && (
              <Typography
                variant="h5"
                align="center"
                color="text.secondary"
                sx={{
                  p: 2,
                }}
              >
                Aucune inscription pour le moment.
              </Typography>
            )}
        </Stack>
      </Container>
    </>
  );
}
