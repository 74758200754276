import React, { useCallback, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import Copyright from "../Copyright/Copyright";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid2 from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { errorAtom } from "../../../Features/atom";
import { useGetConfig } from "../../../Features/config";
import { useLocalStorage } from "../../../Features/localStorage";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";

const defaultTheme = createTheme();

export default function CreateAccount() {
  const [passwordMatchError, setPasswordMatchError] = useState(false);
  const [invalidEmailError, setErrorEmail] = useState(false);
  const [waitingValidation, setWaitingValidation] = useState(false);
  const [accountEmail, setAccountEmail] = useState("");
  const [waitingCode, setWaitingCode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  const [accessToken, setAccessToken] = useLocalStorage("accessToken", null); // eslint-disable-line no-unused-vars
  const navigate = useNavigate();
  const config = useGetConfig();

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const formData = new FormData(event.currentTarget);
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailRegex.test(formData.get("email"))) {
          setErrorEmail(true);
          return;
        } else {
          setErrorEmail(false);
        }

        if (formData.get("password") !== formData.get("password2")) {
          setPasswordMatchError(true);
          return;
        }

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        if (!passwordRegex.test(formData.get("password"))) {
          setPasswordMatchError(true);
          return;
        } else {
          setPasswordMatchError(false);
        }

        if (formData.get("password") === formData.get("password2")) {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/account?club=${config?.club.guid}`,
              {
                method: "PUT",
                body: JSON.stringify({
                  email: formData.get("email"),
                  password: formData.get("password"),
                  firstName: formData.get("firstName"),
                  lastName: formData.get("lastName"),
                  allowEmails: formData.get("allowExtraEmails") !== null,
                }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );

            if (response.status !== 200) {
              setError({
                message:
                  "Une erreur est survenue lors de la création du compte",
                severity: "error",
              });
              return;
            } else {
              setAccountEmail(formData.get("email"));
              setWaitingCode(true);
            }
          } catch (e) {
            console.log(e);
            setError({
              message: "Une erreur est survenue lors de la création du compte",
              severity: "error",
            });
          }
        }
      } finally {
        setIsLoading(false);
      }
    },
    [setError, setIsLoading, setWaitingCode, config]
  );

  const handleSubmitCode = useCallback(
    async (event) => {
      event.preventDefault();
      setIsLoading(true);
      try {
        const formData = new FormData(event.currentTarget);

        try {
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/account/activate-account`,
            {
              method: "POST",
              body: JSON.stringify({
                email: accountEmail,
                code: formData.get("code"),
                club: config?.club.guid,
              }),
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (response.status !== 200) {
            setError({
              message: "Une erreur est survenue lors de la validation du code",
              severity: "error",
            });
            return;
          } else {
            const data = await response.json();
            setAccessToken(data.accessToken);

            if (config?.connectionMode !== 2) {
              navigate("/");
            } else {
              setWaitingValidation(true);
              setTimeout(() => {
                navigate("/");
              }, 5000);
            }
          }
        } catch (e) {
          console.log(e);
          setError({
            message: "Une erreur est survenue lors de la validation du code",
            severity: "error",
          });
        }
      } finally {
        setIsLoading(false);
        setWaitingCode(false);
      }
    },
    [config, navigate, setAccessToken, setError, accountEmail]
  );

  const handleLogin = React.useCallback(() => {
    navigate("/login");
  }, [navigate]);

  const handleHome = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid2 container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid2
          size={{ xs: false, sm: 4, md: 7 }}
          sx={{
            backgroundImage: `url(${config?.homeBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid2
          size={{ xs: 12, sm: 8, md: 5 }}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ m: 1, border: "1px solid #000" }}
              src={config?.logo}
              onClick={handleHome}
            />
            <Typography component="h1" variant="h5">
              Créer un compte
            </Typography>

            {!waitingCode && !waitingValidation && (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid2 container spacing={2}>
                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <TextField
                      autoComplete="given-name"
                      name="firstName"
                      required
                      fullWidth
                      id="firstName"
                      label="Prénom"
                      autoFocus
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, sm: 6 }}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      label="Nom"
                      name="lastName"
                      autoComplete="family-name"
                    />
                  </Grid2>
                  <Grid2 size={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      label="Adresse email"
                      name="email"
                      autoComplete="email"
                      error={invalidEmailError}
                    />
                  </Grid2>
                  <Grid2 size={12}>
                    <TextField
                      required
                      fullWidth
                      name="password"
                      label="Mot de passe"
                      type="password"
                      id="password"
                      autoComplete="new-password"
                    />
                  </Grid2>
                  <Grid2 size={12}>
                    <TextField
                      required
                      fullWidth
                      name="password2"
                      label="Confirmer le mot de passe"
                      type="password"
                      id="password2"
                      autoComplete="new-password"
                      error={passwordMatchError}
                    />
                    {passwordMatchError && (
                      <Typography color="error">
                        Les mots de passe ne se correspondent pas ou ne
                        respectent pas les critères de sécurité (8 caractères
                        minimum, 1 majuscule, 1 minuscule, 1 chiffre)
                      </Typography>
                    )}
                  </Grid2>
                  <Grid2 size={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="allowExtraEmails"
                          color="primary"
                          name="allowExtraEmails"
                        />
                      }
                      label="Je veux être notifié par email lors de l'ajout d'un concours."
                    />
                  </Grid2>
                </Grid2>
                <Box sx={{ position: "relative" }}>
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={isLoading}
                  >
                    Créer mon compte
                  </Button>
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-6px",
                        marginLeft: "-10px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            )}
            {waitingValidation && (
              <Grid2 container spacing={2}>
                <Grid2 size={12}>
                  <Typography color="success.main" sx={{ mt: 3 }}>
                    Votre compte a été créé. Vous devez attendre la validation
                    de votre compte par le club avant de pouvoir accéder aux
                    concours.
                  </Typography>
                  <Typography color="success.main" sx={{ mb: 3 }}>
                    Vous allez être redirigé vers la page d'accueil dans 5
                    secondes.
                  </Typography>
                </Grid2>
              </Grid2>
            )}
            {waitingCode && (
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmitCode}
                sx={{ mt: 3 }}
              >
                <Grid2 container spacing={2}>
                  <Grid2 size={12}>
                    <Typography color="success.main">
                      Votre compte a été créé. Vous allez recevoir un email
                      contenant un code de validation.
                      <br /> Si vous ne recevez pas l'email, vérifiez vos spams.
                    </Typography>
                  </Grid2>
                  <Grid2 size={12}>
                    <TextField
                      required
                      fullWidth
                      name="code"
                      label="Code de validation"
                      type="text"
                      id="code"
                      autoComplete="new-code"
                    />
                  </Grid2>
                  <Grid2 size={12}>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{ mt: 3, mb: 2 }}
                      disabled={isLoading}
                    >
                      Valider mon compte
                    </Button>
                    {isLoading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-6px",
                          marginLeft: "-10px",
                        }}
                      />
                    )}
                  </Grid2>
                </Grid2>
              </Box>
            )}
            <Grid2 container justifyContent="flex-end">
              <Grid2>
                <Link href="#" variant="body2" onClick={handleLogin}>
                  Vous avez déjà un compte ? Connectez-vous
                </Link>
              </Grid2>
            </Grid2>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Grid2>
      </Grid2>
    </ThemeProvider>
  );
}
