import {
  amber,
  blue,
  blueGrey,
  brown,
  cyan,
  deepOrange,
  deepPurple,
  green,
  indigo,
  lightBlue,
  lightGreen,
  orange,
  pink,
  purple,
  red,
  teal,
} from "@mui/material/colors";
import { useCallback, useMemo } from "react";

import { useGetConfig } from "./config";

const chipColors = [
  red,
  pink,
  green,
  blue,
  orange,
  purple,
  cyan,
  indigo,
  teal,
  deepOrange,
  deepPurple,
  lightBlue,
  lightGreen,
  amber,
  brown,
  blueGrey,
];

function GetConfigurationTemplate() {
  return {
    pony: {
      isChecked: false,
      isVisible: false,
      attelage: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_attelage_club_a2", isChecked: false },
            { label: "p_attelage_club_a1", isChecked: false },
            { label: "p_attelage_gp_enfant_poney_solo", isChecked: false },
            { label: "p_attelage_gp_junior_poney_solo", isChecked: false },
            { label: "p_attelage_gp_junior_poney_paire", isChecked: false },
            { label: "p_attelage_club_poney_1", isChecked: false },
            { label: "p_attelage_club_poney_2", isChecked: false },
            {
              label: "p_attelage_derby_marathon_club_poney_solo",
              isChecked: false,
            },
            {
              label: "p_attelage_derby_marathon_club_poney_paire",
              isChecked: false,
            },
          ],
        },
      },
      cce: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_cce_club_e_elite", isChecked: false },
            { label: "p_cce_club_e1", isChecked: false },
            { label: "p_cce_club_e2", isChecked: false },
            { label: "p_cce_as_1", isChecked: false },
            { label: "p_cce_as_2", isChecked: false },
            { label: "p_cce_poney_elite", isChecked: false },
            { label: "p_cce_poney_1", isChecked: false },
            { label: "p_cce_poney_1_b", isChecked: false },
            { label: "p_cce_poney_2", isChecked: false },
            { label: "p_cce_poney_2_b", isChecked: false },
            { label: "p_cce_poney_3", isChecked: false },
            { label: "p_cce_poney_3_b", isChecked: false },
          ],
        },
        amateur: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_cce_club_e_elite", isChecked: false },
            { label: "p_cce_club_e1", isChecked: false },
            { label: "p_cce_club_e2", isChecked: false },
            { label: "p_cce_as_1", isChecked: false },
            { label: "p_cce_as_2", isChecked: false },
            { label: "p_cce_poney_elite", isChecked: false },
            { label: "p_cce_poney_1", isChecked: false },
            { label: "p_cce_poney_1_b", isChecked: false },
            { label: "p_cce_poney_2", isChecked: false },
            { label: "p_cce_poney_2_b", isChecked: false },
            { label: "p_cce_poney_3", isChecked: false },
            { label: "p_cce_poney_3_b", isChecked: false },
          ],
        },
      },
      course: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_course_poney_a1", isChecked: false },
            { label: "p_course_poney_b_elite", isChecked: false },
            { label: "p_course_poney_b1", isChecked: false },
            { label: "p_course_poney_b2", isChecked: false },
            { label: "p_course_poney_c_elite", isChecked: false },
            { label: "p_course_poney_c1", isChecked: false },
            { label: "p_course_poney_c2", isChecked: false },
            { label: "p_course_poney_d_elite", isChecked: false },
            { label: "p_course_poney_d1", isChecked: false },
            { label: "p_course_poney_d2", isChecked: false },
            { label: "p_course_poney_e", isChecked: false },
            { label: "p_course_poney_b_elite_cross_steeple", isChecked: false },
            { label: "p_course_poney_b1_cross_steeple", isChecked: false },
            { label: "p_course_poney_c_elite_cross_steeple", isChecked: false },
            { label: "p_course_poney_c1_cross_steeple", isChecked: false },
            { label: "p_course_poney_d_elite_cross_steeple", isChecked: false },
            { label: "p_course_poney_e_cross_steeple", isChecked: false },
          ],
        },
      },
      jumping: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_cso_poney_a1", isChecked: false },
            { label: "p_cso_poney_a_elite", isChecked: false },
            { label: "p_cso_poney_4", isChecked: false },
            { label: "p_cso_poney_3", isChecked: false },
            { label: "p_cso_poney_2", isChecked: false },
            { label: "p_cso_poney_1", isChecked: false },
            { label: "p_cso_poney_elite", isChecked: false },
            { label: "p_cso_poney_as_1", isChecked: false },
            { label: "p_cso_poney_as_2", isChecked: false },
            { label: "p_cso_poney_as_elite", isChecked: false },
            { label: "h_cso_club_e3", isChecked: false },
            { label: "h_cso_club_e2", isChecked: false },
            { label: "h_cso_club_e1", isChecked: false },
          ],
        },
        amateur: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_cso_poney_4", isChecked: false },
            { label: "p_cso_poney_3", isChecked: false },
            { label: "p_cso_poney_2", isChecked: false },
            { label: "p_cso_poney_1", isChecked: false },
            { label: "p_cso_poney_elite", isChecked: false },
            { label: "p_cso_poney_as_1", isChecked: false },
            { label: "p_cso_poney_as_2", isChecked: false },
            { label: "p_cso_poney_as_elite", isChecked: false },
          ],
        },
      },
      derbeventing: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_derbeventing_poney_a1", isChecked: false },
            { label: "p_derbeventing_poney_a_elite", isChecked: false },
            { label: "p_derbeventing_club_e_elite", isChecked: false },
            { label: "p_derbeventing_club_e1", isChecked: false },
            { label: "p_derbeventing_club_e2", isChecked: false },
            { label: "p_derbeventing_poney_3", isChecked: false },
            { label: "p_derbeventing_poney_2", isChecked: false },
            { label: "p_derbeventing_poney_1", isChecked: false },
            { label: "p_derbeventing_poney_elite", isChecked: false },
            { label: "p_derbeventing_poney_2_c", isChecked: false },
            { label: "p_derbeventing_poney_2_d", isChecked: false },
          ],
        },
      },
      derbycross: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_derbycross_poney_a1", isChecked: false },
            { label: "p_derbycross_poney_a_elite", isChecked: false },
            { label: "p_derbycross_club_e_elite", isChecked: false },
            { label: "p_derbycross_club_e1", isChecked: false },
            { label: "p_derbycross_club_e2", isChecked: false },
            { label: "p_derbycross_poney_3", isChecked: false },
            { label: "p_derbycross_poney_2", isChecked: false },
            { label: "p_derbycross_poney_1", isChecked: false },
            { label: "p_derbycross_poney_elite", isChecked: false },
            { label: "p_derbycross_poney_2_c", isChecked: false },
            { label: "p_derbycross_poney_2_d", isChecked: false },
          ],
        },
      },
      dressage: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_dress_3", isChecked: false },
            { label: "p_dress_2", isChecked: false },
            { label: "p_dress_1", isChecked: false },
            { label: "p_dress_elite", isChecked: false },
            { label: "p_dress_prel_3", isChecked: false },
            { label: "p_dress_prel_2", isChecked: false },
            { label: "p_dress_prel_1", isChecked: false },
            { label: "p_dress_prel_elite", isChecked: false },
            { label: "p_dress_3_rlm", isChecked: false },
            { label: "p_dress_2_rlm", isChecked: false },
            { label: "p_dress_1_rlm", isChecked: false },
            { label: "p_dress_elite_rlm", isChecked: false },
            { label: "p_dress_prel_3_rlm", isChecked: false },
            { label: "p_dress_prel_2_rlm", isChecked: false },
            { label: "p_dress_prel_1_rlm", isChecked: false },
            { label: "p_dress_prel_elite_rlm", isChecked: false },
          ],
        },
        amateur: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_dress_3", isChecked: false },
            { label: "p_dress_2", isChecked: false },
            { label: "p_dress_1", isChecked: false },
            { label: "p_dress_elite", isChecked: false },
            { label: "p_dress_prel_3", isChecked: false },
            { label: "p_dress_prel_2", isChecked: false },
            { label: "p_dress_prel_1", isChecked: false },
            { label: "p_dress_prel_elite", isChecked: false },
            { label: "p_dress_3_rlm", isChecked: false },
            { label: "p_dress_2_rlm", isChecked: false },
            { label: "p_dress_1_rlm", isChecked: false },
            { label: "p_dress_elite_rlm", isChecked: false },
            { label: "p_dress_prel_3_rlm", isChecked: false },
            { label: "p_dress_prel_2_rlm", isChecked: false },
            { label: "p_dress_prel_1_rlm", isChecked: false },
            { label: "p_dress_prel_elite_rlm", isChecked: false },
          ],
        },
      },
      endurance: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_endu_initi_1", isChecked: false },
            { label: "p_endu_club_a_indiv", isChecked: false },
            { label: "p_endu_club_poney_4_indiv", isChecked: false },
          ],
        },
      },
      endurance_att: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_endu_att_club_solo", isChecked: false },
            { label: "p_endu_att_club_solo_elite", isChecked: false },
            { label: "p_endu_att_club_paire", isChecked: false },
            { label: "p_endu_att_club_paire_elite", isChecked: false },
            { label: "p_endu_att_club_team", isChecked: false },
            { label: "p_endu_att_club_team_elite", isChecked: false },
            { label: "p_endu_att_club_a", isChecked: false },
            { label: "p_endu_att_club_a_elite", isChecked: false },
          ],
        },
      },
      equifeel: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_equifeel_club_a", isChecked: false },
            { label: "p_equifeel_poney1", isChecked: false },
          ],
        },
      },
      equifun: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_equifun_club_a2", isChecked: false },
            { label: "p_equifun_club_a1", isChecked: false },
            { label: "p_equifun_club_benjamin", isChecked: false },
            { label: "p_equifun_club_benjamin_equipe", isChecked: false },
            { label: "p_equifun_club_minime", isChecked: false },
            { label: "p_equifun_club_minime_equipe", isChecked: false },
            { label: "p_equifun_club_cadet", isChecked: false },
            { label: "p_equifun_club_cadet_equipe", isChecked: false },
            { label: "p_equifun_club_junior", isChecked: false },
            { label: "p_equifun_club_junior_equipe", isChecked: false },
          ],
        },
      },
      western: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_west_club_a_pole_bending", isChecked: false },
            { label: "p_west_club_poney_pole_bending", isChecked: false },
            { label: "p_west_club_a_barrel_race", isChecked: false },
            { label: "p_west_club_poney_barrel_race", isChecked: false },
            { label: "p_west_club_poney_trail", isChecked: false },
            { label: "p_west_club_poney_showmanship", isChecked: false },
            { label: "p_west_club_poney_ranch_riding", isChecked: false },
            { label: "p_west_club_poney_ranch_trail", isChecked: false },
            { label: "p_west_club_poney_reining", isChecked: false },
            { label: "p_west_club_poney_trail_in_hand", isChecked: false },
          ],
        },
      },
      hunter: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_hunter_poney_a1_maniabilite", isChecked: false },
            { label: "p_hunter_poney_a2_maniabilite", isChecked: false },
            { label: "p_hunter_poney_1_maniabilite", isChecked: false },
            { label: "p_hunter_poney_2_maniabilite", isChecked: false },
            { label: "p_hunter_poney_3_maniabilite", isChecked: false },
            { label: "p_hunter_poney_4_maniabilite", isChecked: false },
            { label: "p_hunter_poney_a_elite_maniabilite", isChecked: false },
            { label: "p_hunter_poney_elite_maniabilite", isChecked: false },
            { label: "p_hunter_poney_a1_fig_imp", isChecked: false },
            { label: "p_hunter_poney_a2_fig_imp", isChecked: false },
            { label: "p_hunter_poney_1_fig_imp", isChecked: false },
            { label: "p_hunter_poney_2_fig_imp", isChecked: false },
            { label: "p_hunter_poney_3_fig_imp", isChecked: false },
            { label: "p_hunter_poney_4_fig_imp", isChecked: false },
            { label: "p_hunter_poney_a_elite_fig_imp", isChecked: false },
            { label: "p_hunter_poney_elite_fig_imp", isChecked: false },
            { label: "p_hunter_poney_a1_mixte_mania_imp", isChecked: false },
            { label: "p_hunter_poney_a2_mixte_mania_imp", isChecked: false },
            { label: "p_hunter_poney_1_mixte_mania_imp", isChecked: false },
            { label: "p_hunter_poney_2_mixte_mania_imp", isChecked: false },
            { label: "p_hunter_poney_3_mixte_mania_imp", isChecked: false },
            { label: "p_hunter_poney_4_mixte_mania_imp", isChecked: false },
            {
              label: "p_hunter_poney_a_elite_mixte_mania_imp",
              isChecked: false,
            },
            { label: "p_hunter_poney_elite_mixte_mania_imp", isChecked: false },
          ],
        },
        amateur: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_hunter_poney_a1_maniabilite", isChecked: false },
            { label: "p_hunter_poney_a2_maniabilite", isChecked: false },
            { label: "p_hunter_poney_1_maniabilite", isChecked: false },
            { label: "p_hunter_poney_2_maniabilite", isChecked: false },
            { label: "p_hunter_poney_3_maniabilite", isChecked: false },
            { label: "p_hunter_poney_4_maniabilite", isChecked: false },
            { label: "p_hunter_poney_a_elite_maniabilite", isChecked: false },
            { label: "p_hunter_poney_elite_maniabilite", isChecked: false },
            { label: "p_hunter_poney_a1_fig_imp", isChecked: false },
            { label: "p_hunter_poney_a2_fig_imp", isChecked: false },
            { label: "p_hunter_poney_1_fig_imp", isChecked: false },
            { label: "p_hunter_poney_2_fig_imp", isChecked: false },
            { label: "p_hunter_poney_3_fig_imp", isChecked: false },
            { label: "p_hunter_poney_4_fig_imp", isChecked: false },
            { label: "p_hunter_poney_a_elite_fig_imp", isChecked: false },
            { label: "p_hunter_poney_elite_fig_imp", isChecked: false },
            { label: "p_hunter_poney_a1_mixte_mania_imp", isChecked: false },
            { label: "p_hunter_poney_a2_mixte_mania_imp", isChecked: false },
            { label: "p_hunter_poney_1_mixte_mania_imp", isChecked: false },
            { label: "p_hunter_poney_2_mixte_mania_imp", isChecked: false },
            { label: "p_hunter_poney_3_mixte_mania_imp", isChecked: false },
            { label: "p_hunter_poney_4_mixte_mania_imp", isChecked: false },
            {
              label: "p_hunter_poney_a_elite_mixte_mania_imp",
              isChecked: false,
            },
            { label: "p_hunter_poney_elite_mixte_mania_imp", isChecked: false },
          ],
        },
      },
      mountain: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_mountain_poney_a_b_main", isChecked: false },
            { label: "p_mountain_poney_a_b_selle", isChecked: false },
            { label: "p_mountain_poney_main", isChecked: false },
            { label: "p_mountain_poney_selle", isChecked: false },
          ],
        },
      },
      ponygames: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_pg_club_a1_poussin", isChecked: false },
            { label: "p_pg_club_a1_poussin_paire", isChecked: false },
            { label: "p_pg_club_a2_moustique", isChecked: false },
            { label: "p_pg_club_a2_moustique_paire", isChecked: false },
            { label: "p_pg_club_a2_poussin", isChecked: false },
            { label: "p_pg_club_a2_poussin_paire", isChecked: false },
            {
              label: "p_pg_club_elite_benjamin",
              isChecked: false,
            },
            {
              label: "p_pg_club_elite_benjamin_paire",
              isChecked: false,
            },
            { label: "p_pg_club_1_benjamin", isChecked: false },
            {
              label: "p_pg_club_1_benjamin_paire",
              isChecked: false,
            },
            { label: "p_pg_club_2_benjamin", isChecked: false },
            {
              label: "p_pg_club_2_benjamin_paire",
              isChecked: false,
            },
            { label: "p_pg_club_elite_cadet", isChecked: false },
            {
              label: "p_pg_club_elite_cadet_paire",
              isChecked: false,
            },
            { label: "p_pg_club_1_cadet", isChecked: false },
            {
              label: "p_pg_club_1_cadet_paire",
              isChecked: false,
            },
            { label: "p_pg_club_2_cadet", isChecked: false },
            {
              label: "p_pg_club_2_cadet_paire",
              isChecked: false,
            },
            { label: "p_pg_club_elite_junior", isChecked: false },
            {
              label: "p_pg_club_elite_junior_paire",
              isChecked: false,
            },
            { label: "p_pg_club_1_junior", isChecked: false },
            {
              label: "p_pg_club_1_junior_paire",
              isChecked: false,
            },
            { label: "p_pg_club_2_junior", isChecked: false },
            {
              label: "p_pg_club_2_junior_paire",
              isChecked: false,
            },
            { label: "p_pg_club_elite_minime", isChecked: false },
            {
              label: "p_pg_club_elite_minime_paire",
              isChecked: false,
            },
            { label: "p_pg_club_1_minime", isChecked: false },
            {
              label: "p_pg_club_1_minime_paire",
              isChecked: false,
            },
            { label: "p_pg_club_2_minime", isChecked: false },
            {
              label: "p_pg_club_2_minime_paire",
              isChecked: false,
            },
            { label: "p_pg_club_3_minime", isChecked: false },
            {
              label: "p_pg_club_3_minime_paire",
              isChecked: false,
            },
          ],
        },
      },
      rideandbike: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_rideandbike_club_a_duo_femme", isChecked: false },
            { label: "p_rideandbike_club_a_duo_homme", isChecked: false },
            { label: "p_rideandbike_club_a_duo_mixte", isChecked: false },
            { label: "p_rideandbike_club_2_duo_femme", isChecked: false },
            { label: "p_rideandbike_club_2_duo_homme", isChecked: false },
            { label: "p_rideandbike_club_2_duo_mixte", isChecked: false },
            { label: "p_rideandbike_club_1_duo_femme", isChecked: false },
            { label: "p_rideandbike_club_1_duo_homme", isChecked: false },
            { label: "p_rideandbike_club_1_duo_mixte", isChecked: false },
            { label: "p_rideandbike_club_elite_duo_mixte", isChecked: false },
          ],
        },
      },
      rideandrun: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_rideandrun_club_a_duo_femme", isChecked: false },
            { label: "p_rideandrun_club_a_duo_homme", isChecked: false },
            { label: "p_rideandrun_club_a_duo_mixte", isChecked: false },
            { label: "p_rideandrun_club_2_duo_femme", isChecked: false },
            { label: "p_rideandrun_club_2_duo_homme", isChecked: false },
            { label: "p_rideandrun_club_2_duo_mixte", isChecked: false },
            { label: "p_rideandrun_club_1_duo_femme", isChecked: false },
            { label: "p_rideandrun_club_1_duo_homme", isChecked: false },
            { label: "p_rideandrun_club_1_duo_mixte", isChecked: false },
            { label: "p_rideandrun_club_elite_duo_femme", isChecked: false },
            { label: "p_rideandrun_club_elite_duo_homme", isChecked: false },
            { label: "p_rideandrun_club_elite_duo_mixte", isChecked: false },
            { label: "p_rideandrun_open_2_a_b_femme", isChecked: false },
            { label: "p_rideandrun_open_2_a_b_homme", isChecked: false },
            { label: "p_rideandrun_open_2_a_b_mixte", isChecked: false },
            { label: "p_rideandrun_open_1_a_b_femme", isChecked: false },
            { label: "p_rideandrun_open_1_a_b_homme", isChecked: false },
            { label: "p_rideandrun_open_1_a_b_mixte", isChecked: false },
          ],
        },
      },
      arc: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_arc_club_a2_raid", isChecked: false },
            { label: "p_arc_club_a2_tower", isChecked: false },
            { label: "p_arc_club_a2_hunt", isChecked: false },
            { label: "p_arc_club_a2_combine_2_epreuves", isChecked: false },
            { label: "p_arc_club_a1_raid", isChecked: false },
            { label: "p_arc_club_a1_tower", isChecked: false },
            { label: "p_arc_club_a1_hunt", isChecked: false },
            { label: "p_arc_club_a1_combine_2_epreuves", isChecked: false },
            { label: "p_arc_club_a1_combine_3_epreuves", isChecked: false },
            { label: "p_arc_club_poney_3_raid", isChecked: false },
            { label: "p_arc_club_poney_3_tower", isChecked: false },
            { label: "p_arc_club_poney_3_hunt", isChecked: false },
            {
              label: "p_arc_club_poney_3_combine_2_epreuves",
              isChecked: false,
            },
            { label: "p_arc_club_poney_2_raid", isChecked: false },
            { label: "p_arc_club_poney_2_tower", isChecked: false },
            { label: "p_arc_club_poney_2_hunt", isChecked: false },
            {
              label: "p_arc_club_poney_2_combine_2_epreuves",
              isChecked: false,
            },
            {
              label: "p_arc_club_poney_2_combine_3_epreuves",
              isChecked: false,
            },
            { label: "p_arc_club_poney_2_combi_raid_tower", isChecked: false },
            { label: "p_arc_club_poney_2_combi_tower_hunt", isChecked: false },
            { label: "p_arc_club_poney_1_raid", isChecked: false },
            { label: "p_arc_club_poney_1_tower", isChecked: false },
            { label: "p_arc_club_poney_1_hunt", isChecked: false },
            {
              label: "p_arc_club_poney_1_combine_2_epreuves",
              isChecked: false,
            },
            {
              label: "p_arc_club_poney_1_combine_3_epreuves",
              isChecked: false,
            },
            { label: "p_arc_club_poney_1_combi_raid_tower", isChecked: false },
            { label: "p_arc_club_poney_1_combi_tower_hunt", isChecked: false },
          ],
        },
      },
      trec_attelage: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_trec_attelage_club_a1_solo", isChecked: false },
          ],
        },
      },
      pmg: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "p_pmg_12_ind_1", isChecked: false },
            { label: "p_pmg_15_ind_1", isChecked: false },
            { label: "p_pmg_18_ind_1", isChecked: false },
            { label: "p_pmg_open_ind_1", isChecked: false },
            { label: "p_pmg_12_ind_elite", isChecked: false },
            { label: "p_pmg_15_ind_elite", isChecked: false },
            { label: "p_pmg_18_ind_elite", isChecked: false },
            { label: "p_pmg_open_ind_elite", isChecked: false },
          ],
        },
      },
    },
    horse: {
      isChecked: false,
      isVisible: false,
      attelage: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_attelage_club_elite_solo", isChecked: false },
            { label: "h_attelage_club_elite_solo_gp", isChecked: false },
            { label: "h_attelage_club_elite_paire", isChecked: false },
            { label: "h_attelage_club_elite_paire_gp", isChecked: false },
            { label: "h_attelage_club_elite_team", isChecked: false },
            { label: "h_attelage_club_elite_team_gp", isChecked: false },
            { label: "h_attelage_club_1_solo", isChecked: false },
            { label: "h_attelage_club_1_team", isChecked: false },
            { label: "h_attelage_club_prepa_solo", isChecked: false },
            { label: "h_attelage_gp_jeune_meneur_solo", isChecked: false },
            { label: "h_attelage_club_1_solo", isChecked: false },
            { label: "h_attelage_club_1_paire", isChecked: false },
            { label: "h_attelage_club_1_team", isChecked: false },
            { label: "h_attelage_club_2_solo", isChecked: false },
            { label: "h_attelage_derby_marathon_club_solo", isChecked: false },
            { label: "h_attelage_derby_marathon_club_paire", isChecked: false },
            {
              label: "h_attelage_club_prepa_paire_maniabilite",
              isChecked: false,
            },
            {
              label: "h_attelage_club_prepa_solo_maniabilite",
              isChecked: false,
            },
          ],
        },
      },
      cce: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_cce_club_elite", isChecked: false },
            { label: "h_cce_club_1", isChecked: false },
            { label: "h_cce_club_2", isChecked: false },
            { label: "h_cce_club_3", isChecked: false },
            { label: "h_cce_club_prepa", isChecked: false },
          ],
        },
        amateur: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_cce_amateur_elite", isChecked: false },
            { label: "h_cce_amateur_1", isChecked: false },
            { label: "h_cce_amateur_2", isChecked: false },
            { label: "h_cce_amateur_3", isChecked: false },
            { label: "h_cce_amateur_prepa", isChecked: false },
          ],
        },
      },
      course: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            {
              label: "h_course_club",
              isChecked: false,
            },
          ],
        },
      },
      jumping: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_cso_club_4", isChecked: false },
            { label: "h_cso_club_3", isChecked: false },
            { label: "h_cso_club_2", isChecked: false },
            { label: "h_cso_club_1", isChecked: false },
            { label: "h_cso_club_elite", isChecked: false },
            { label: "h_cso_club_prepa_4", isChecked: false },
            { label: "h_cso_club_prepa_3", isChecked: false },
            { label: "h_cso_club_prepa_2", isChecked: false },
            { label: "h_cso_club_prepa_1", isChecked: false },
            { label: "h_cso_club_prepa_elite", isChecked: false },
            { label: "h_cso_club_e3", isChecked: false },
            { label: "h_cso_club_e2", isChecked: false },
            { label: "h_cso_club_e1", isChecked: false },
            { label: "h_cso_club_e_elite", isChecked: false },
          ],
        },
        amateur: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_cso_amateur_3", isChecked: false },
            { label: "h_cso_amateur_2", isChecked: false },
            { label: "h_cso_amateur_1", isChecked: false },
            { label: "h_cso_amateur_elite", isChecked: false },
            { label: "h_cso_amateur_prepa_3", isChecked: false },
            { label: "h_cso_amateur_prepa_2", isChecked: false },
            { label: "h_cso_amateur_prepa_1", isChecked: false },
            { label: "h_cso_amateur_prepa_elite", isChecked: false },
          ],
        },
      },
      derbeventing: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_derbeventing_club_elite", isChecked: false },
            { label: "h_derbeventing_club_1", isChecked: false },
            { label: "h_derbeventing_club_2", isChecked: false },
            { label: "h_derbeventing_club_3", isChecked: false },
          ],
        },
      },
      derbycross: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_derbycross_club_elite", isChecked: false },
            { label: "h_derbycross_club_1", isChecked: false },
            { label: "h_derbycross_club_2", isChecked: false },
            { label: "h_derbycross_club_3", isChecked: false },
          ],
        },
      },
      dressage: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_dress_3", isChecked: false },
            { label: "h_dress_2", isChecked: false },
            { label: "h_dress_1", isChecked: false },
            { label: "h_dress_elite", isChecked: false },
            { label: "h_dress_prel_3", isChecked: false },
            { label: "h_dress_prel_2", isChecked: false },
            { label: "h_dress_prel_1", isChecked: false },
            { label: "h_dress_prel_elite", isChecked: false },
            { label: "h_dress_3_rlm", isChecked: false },
            { label: "h_dress_2_rlm", isChecked: false },
            { label: "h_dress_1_rlm", isChecked: false },
            { label: "h_dress_elite_rlm", isChecked: false },
            { label: "h_dress_prel_3_rlm", isChecked: false },
            { label: "h_dress_prel_2_rlm", isChecked: false },
            { label: "h_dress_prel_1_rlm", isChecked: false },
            { label: "h_dress_prel_elite_rlm", isChecked: false },
          ],
        },
        amateur: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_dress_3", isChecked: false },
            { label: "h_dress_2", isChecked: false },
            { label: "h_dress_1", isChecked: false },
            { label: "h_dress_elite", isChecked: false },
            { label: "h_dress_prel_3", isChecked: false },
            { label: "h_dress_prel_2", isChecked: false },
            { label: "h_dress_prel_1", isChecked: false },
            { label: "h_dress_prel_elite", isChecked: false },
            { label: "h_dress_3_rlm", isChecked: false },
            { label: "h_dress_2_rlm", isChecked: false },
            { label: "h_dress_1_rlm", isChecked: false },
            { label: "h_dress_elite_rlm", isChecked: false },
            { label: "h_dress_prel_3_rlm", isChecked: false },
            { label: "h_dress_prel_2_rlm", isChecked: false },
            { label: "h_dress_prel_1_rlm", isChecked: false },
            { label: "h_dress_prel_elite_rlm", isChecked: false },
          ],
        },
      },
      endurance: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_endu_club_4_indiv", isChecked: false },
            { label: "h_endu_club_3_indiv", isChecked: false },
            { label: "h_endu_club_2_indiv", isChecked: false },
            { label: "h_endu_club_2_speciale", isChecked: false },
            { label: "h_endu_club_1_indiv", isChecked: false },
            { label: "h_endu_club_elite_indiv", isChecked: false },
            { label: "h_endu_club_2_duo", isChecked: false },
          ],
        },
      },
      endurance_att: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_endu_att_club_solo", isChecked: false },
            { label: "h_endu_att_club_solo_elite", isChecked: false },
            { label: "h_endu_att_club_paire", isChecked: false },
            { label: "h_endu_att_club_paire_elite", isChecked: false },
            { label: "h_endu_att_club_team", isChecked: false },
            { label: "h_endu_att_club_team_elite", isChecked: false },
            { label: "h_endu_att_club_trait_solo", isChecked: false },
            { label: "h_endu_att_club_trait_solo_elite", isChecked: false },
            { label: "h_endu_att_club_trait_paire", isChecked: false },
            { label: "h_endu_att_club_trait_paire_elite", isChecked: false },
            { label: "h_endu_att_club_trait_team", isChecked: false },
            { label: "h_endu_att_club_trait_team_elite", isChecked: false },
            { label: "h_endu_att_club_sulky", isChecked: false },
            { label: "h_endu_att_club_sulky_elite", isChecked: false },
          ],
        },
      },
      equifeel: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_equifeel_club_elite", isChecked: false },
            { label: "h_equifeel_club_1", isChecked: false },
            { label: "h_equifeel_club_2", isChecked: false },
            { label: "h_equifeel_club_prepa_elite", isChecked: false },
            { label: "h_equifeel_club_prepa_1", isChecked: false },
            { label: "h_equifeel_club_prepa_2", isChecked: false },
          ],
        },
      },
      equifun: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_equifun_club_elite", isChecked: false },
            { label: "h_equifun_club_elite_equipe", isChecked: false },
            { label: "h_equifun_club", isChecked: false },
            { label: "h_equifun_club_equipe", isChecked: false },
          ],
        },
      },
      western: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_west_club_1_barrel_race", isChecked: false },
            { label: "h_west_club_2_barrel_race", isChecked: false },
            { label: "h_west_club_1_combine", isChecked: false },
            { label: "h_west_club_2_combine", isChecked: false },
            { label: "h_west_club_1_horsemanship", isChecked: false },
            { label: "h_west_club_2_horsemanship", isChecked: false },
            { label: "h_west_club_1_pole_bending", isChecked: false },
            { label: "h_west_club_2_pole_bending", isChecked: false },
            { label: "h_west_club_1_ranch_reining", isChecked: false },
            { label: "h_west_club_2_ranch_reining", isChecked: false },
            { label: "h_west_club_1_ranch_riding", isChecked: false },
            { label: "h_west_club_2_ranch_riding", isChecked: false },
            { label: "h_west_club_1_ranch_sorting", isChecked: false },
            { label: "h_west_club_2_ranch_sorting", isChecked: false },
            { label: "h_west_club_1_ranch_trail", isChecked: false },
            { label: "h_west_club_2_ranch_trail", isChecked: false },
            { label: "h_west_club_1_reining", isChecked: false },
            { label: "h_west_club_2_reining", isChecked: false },
            { label: "h_west_club_1_showmanship", isChecked: false },
            { label: "h_west_club_2_showmanship", isChecked: false },
            { label: "h_west_club_1_trail", isChecked: false },
            { label: "h_west_club_2_trail", isChecked: false },
            { label: "h_west_club_1_trail_in_hand", isChecked: false },
            { label: "h_west_club_2_trail_in_hand", isChecked: false },
            { label: "h_west_club_1_western_pleasure", isChecked: false },
            { label: "h_west_club_2_western_pleasure", isChecked: false },
          ],
        },
      },
      hunter: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_hunter_club_4_mixte_mania_imp", isChecked: false },
            { label: "h_hunter_club_3_mixte_mania_imp", isChecked: false },
            { label: "h_hunter_club_2_mixte_mania_imp", isChecked: false },
            { label: "h_hunter_club_1_mixte_mania_imp", isChecked: false },
            {
              label: "h_hunter_club_elite_mixte_mania_imp",
              isChecked: false,
            },
            {
              label: "h_hunter_club_prepa_1m_mixte_mania_imp",
              isChecked: false,
            },
            {
              label: "h_hunter_club_prepa_1m10_mixte_mania_imp",
              isChecked: false,
            },

            { label: "h_hunter_club_4_maniabilite", isChecked: false },
            { label: "h_hunter_club_3_maniabilite", isChecked: false },
            { label: "h_hunter_club_2_maniabilite", isChecked: false },
            { label: "h_hunter_club_1_maniabilite", isChecked: false },
            { label: "h_hunter_club_elite_maniabilite", isChecked: false },
            { label: "h_hunter_club_prepa_1m_maniabilite", isChecked: false },
            { label: "h_hunter_club_prepa_1m10_maniabilite", isChecked: false },

            { label: "h_hunter_club_4_fig_imp", isChecked: false },
            { label: "h_hunter_club_3_fig_imp", isChecked: false },
            { label: "h_hunter_club_2_fig_imp", isChecked: false },
            { label: "h_hunter_club_1_fig_imp", isChecked: false },
            { label: "h_hunter_club_elite_fig_imp", isChecked: false },
            { label: "h_hunter_club_prepa_1m_fig_imp", isChecked: false },
            { label: "h_hunter_club_prepa_1m10_fig_imp", isChecked: false },
          ],
        },
        amateur: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_hunter_club_4_mixte_mania_imp", isChecked: false },
            { label: "h_hunter_club_3_mixte_mania_imp", isChecked: false },
            { label: "h_hunter_club_2_mixte_mania_imp", isChecked: false },
            { label: "h_hunter_club_1_mixte_mania_imp", isChecked: false },
            {
              label: "h_hunter_club_elite_mixte_mania_imp",
              isChecked: false,
            },
            {
              label: "h_hunter_club_prepa_1m_mixte_mania_imp",
              isChecked: false,
            },
            {
              label: "h_hunter_club_prepa_1m10_mixte_mania_imp",
              isChecked: false,
            },

            { label: "h_hunter_club_4_maniabilite", isChecked: false },
            { label: "h_hunter_club_3_maniabilite", isChecked: false },
            { label: "h_hunter_club_2_maniabilite", isChecked: false },
            { label: "h_hunter_club_1_maniabilite", isChecked: false },
            { label: "h_hunter_club_elite_maniabilite", isChecked: false },
            { label: "h_hunter_club_prepa_1m_maniabilite", isChecked: false },
            { label: "h_hunter_club_prepa_1m10_maniabilite", isChecked: false },

            { label: "h_hunter_club_4_fig_imp", isChecked: false },
            { label: "h_hunter_club_3_fig_imp", isChecked: false },
            { label: "h_hunter_club_2_fig_imp", isChecked: false },
            { label: "h_hunter_club_1_fig_imp", isChecked: false },
            { label: "h_hunter_club_elite_fig_imp", isChecked: false },
            { label: "h_hunter_club_prepa_1m_fig_imp", isChecked: false },
            { label: "h_hunter_club_prepa_1m10_fig_imp", isChecked: false },
          ],
        },
      },
      mountain: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_mountain_club_3", isChecked: false },
            { label: "h_mountain_club_2_main", isChecked: false },
            { label: "h_mountain_club_2_selle", isChecked: false },
            { label: "h_mountain_club_1_main", isChecked: false },
            { label: "h_mountain_club_1_selle", isChecked: false },
            { label: "h_mountain_club_elite_main", isChecked: false },
            { label: "h_mountain_club_elite_selle", isChecked: false },
          ],
        },
        amateur: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_mountain_amateur_3_main", isChecked: false },
            { label: "h_mountain_amateur_3_selle", isChecked: false },
            { label: "h_mountain_amateur_2_main", isChecked: false },
            { label: "h_mountain_amateur_2_selle", isChecked: false },
            { label: "h_mountain_amateur_1_main", isChecked: false },
            { label: "h_mountain_amateur_1_selle", isChecked: false },
            { label: "h_mountain_amateur_elite_main", isChecked: false },
            { label: "h_mountain_amateur_elite_selle", isChecked: false },
          ],
        },
      },
      ponygames: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_pg_club_elite", isChecked: false },
            { label: "h_pg_club_elite_paire", isChecked: false },
            { label: "h_pg_club_elite_senior", isChecked: false },
            { label: "h_pg_club_elite_senior_paire", isChecked: false },
            { label: "h_pg_club_elite_major", isChecked: false },
            { label: "h_pg_club_elite_major_paire", isChecked: false },
            { label: "h_pg_club_1", isChecked: false },
            { label: "h_pg_club_1_paire", isChecked: false },
            { label: "h_pg_club_1_major", isChecked: false },
            { label: "h_pg_club_1_major_paire", isChecked: false },
            { label: "h_pg_club_1_senior", isChecked: false },
            { label: "h_pg_club_1_senior_paire", isChecked: false },
            { label: "h_pg_club_2", isChecked: false },
            { label: "h_pg_club_2_paire", isChecked: false },
            { label: "h_pg_club_2_major", isChecked: false },
            { label: "h_pg_club_2_major_paire", isChecked: false },
            { label: "h_pg_club_2_senior", isChecked: false },
            { label: "h_pg_club_2_senior_paire", isChecked: false },
            { label: "h_pg_club_3", isChecked: false },
            { label: "h_pg_club_3_paire", isChecked: false },
          ],
        },
      },
      rideandbike: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_rideandbike_open_2_mixte", isChecked: false },
            { label: "h_rideandbike_open_1_mixte", isChecked: false },
            { label: "h_rideandbike_club_3_duo_mixte", isChecked: false },
            { label: "h_rideandbike_club_2_duo_femme", isChecked: false },
            { label: "h_rideandbike_club_2_duo_homme", isChecked: false },
            { label: "h_rideandbike_club_2_duo_mixte", isChecked: false },
            { label: "h_rideandbike_club_1_duo_femme", isChecked: false },
            { label: "h_rideandbike_club_1_duo_homme", isChecked: false },
            { label: "h_rideandbike_club_1_duo_mixte", isChecked: false },
            { label: "h_rideandbike_club_elite_duo_mixte", isChecked: false },
          ],
        },
      },
      rideandrun: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_rideandrun_club_3_duo_femme", isChecked: false },
            { label: "h_rideandrun_club_3_duo_homme", isChecked: false },
            { label: "h_rideandrun_club_3_duo_mixte", isChecked: false },
            { label: "h_rideandrun_club_2_duo_femme", isChecked: false },
            { label: "h_rideandrun_club_2_duo_homme", isChecked: false },
            { label: "h_rideandrun_club_2_duo_mixte", isChecked: false },
            { label: "h_rideandrun_club_1_duo_femme", isChecked: false },
            { label: "h_rideandrun_club_1_duo_homme", isChecked: false },
            { label: "h_rideandrun_club_1_duo_mixte", isChecked: false },
            { label: "h_rideandrun_club_elite_duo_femme", isChecked: false },
            { label: "h_rideandrun_club_elite_duo_homme", isChecked: false },
            { label: "h_rideandrun_club_elite_duo_mixte", isChecked: false },
            { label: "h_rideandrun_open_2_mixte", isChecked: false },
            { label: "h_rideandrun_open_1_mixte", isChecked: false },
          ],
        },
      },
      arc: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_arc_club_3_raid", isChecked: false },
            { label: "h_arc_club_3_tower", isChecked: false },
            { label: "h_arc_club_3_hunt", isChecked: false },
            { label: "h_arc_club_3_combine_2_epreuves", isChecked: false },
            { label: "h_arc_club_2_raid", isChecked: false },
            { label: "h_arc_club_2_tower", isChecked: false },
            { label: "h_arc_club_2_hunt", isChecked: false },
            { label: "h_arc_club_2_combine_2_epreuves", isChecked: false },
            { label: "h_arc_club_2_combine_3_epreuves", isChecked: false },
            { label: "h_arc_club_2_combi_raid_tower", isChecked: false },
            { label: "h_arc_club_2_combi_tower_hunt", isChecked: false },
            { label: "h_arc_club_1_raid", isChecked: false },
            { label: "h_arc_club_1_tower", isChecked: false },
            { label: "h_arc_club_1_hunt", isChecked: false },
            { label: "h_arc_club_1_combine_2_epreuves", isChecked: false },
            { label: "h_arc_club_1_combine_3_epreuves", isChecked: false },
            { label: "h_arc_club_1_combi_raid_tower", isChecked: false },
            { label: "h_arc_club_1_combi_tower_hunt", isChecked: false },
            { label: "h_arc_club_elite_raid", isChecked: false },
            { label: "h_arc_club_elite_tower", isChecked: false },
            { label: "h_arc_club_elite_hunt", isChecked: false },
            { label: "h_arc_club_elite_skirmish", isChecked: false },
            { label: "h_arc_club_elite_turkish_tabla", isChecked: false },
            { label: "h_arc_club_elite_combine_2_epreuves", isChecked: false },
            { label: "h_arc_club_elite_combine_3_epreuves", isChecked: false },
          ],
        },
      },
      trec_attelage: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_trec_attelage_club_elite_solo", isChecked: false },
            { label: "h_trec_attelage_club_elite_paire", isChecked: false },
            { label: "h_trec_attelage_club_elite_team", isChecked: false },
            {
              label: "h_trec_attelage_club_elite_speciale_ptv",
              isChecked: false,
            },
            {
              label: "h_trec_attelage_club_elite_speciale_por",
              isChecked: false,
            },
            { label: "h_trec_attelage_club_1_solo", isChecked: false },
            { label: "h_trec_attelage_club_1_paire", isChecked: false },
            { label: "h_trec_attelage_club_1_speciale_ptv", isChecked: false },
            { label: "h_trec_attelage_club_1_speciale_por", isChecked: false },
          ],
        },
      },
      tradition: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_trad_club_elite_combine_4", isChecked: false },
            { label: "h_trad_club_3_combine_3", isChecked: false },
            { label: "h_trad_club_2_combine_3", isChecked: false },
            { label: "h_trad_club_1_combine_3", isChecked: false },
            { label: "h_trad_club_elite_combine_3", isChecked: false },
            { label: "h_trad_club_3_combine_2", isChecked: false },
            { label: "h_trad_club_2_combine_2", isChecked: false },
            { label: "h_trad_club_1_combine_2", isChecked: false },
            { label: "h_trad_club_elite_combine_2", isChecked: false },
            {
              label: "h_trad_club_3_reprise_de_trav",
              isChecked: false,
            },
            {
              label: "h_trad_club_2_reprise_de_trav",
              isChecked: false,
            },
            {
              label: "h_trad_club_1_reprise_de_trav",
              isChecked: false,
            },
            {
              label: "h_trad_club_elite_reprise_de_trav",
              isChecked: false,
            },
            { label: "h_trad_club_3_parc_de_pays", isChecked: false },
            { label: "h_trad_club_2_parc_de_pays", isChecked: false },
            { label: "h_trad_club_1_parc_de_pays", isChecked: false },
            {
              label: "h_trad_club_elite_parc_de_pays",
              isChecked: false,
            },
            {
              label: "h_trad_club_3_mania_a_points",
              isChecked: false,
            },
            {
              label: "h_trad_club_2_mania_a_points",
              isChecked: false,
            },
            {
              label: "h_trad_club_1_mania_a_points",
              isChecked: false,
            },
            {
              label: "h_trad_club_elite_mania_a_points",
              isChecked: false,
            },
            {
              label: "h_trad_club_3_mania_chronometree",
              isChecked: false,
            },
            {
              label: "h_trad_club_2_mania_chronometree",
              isChecked: false,
            },
            {
              label: "h_trad_club_1_mania_chronometree",
              isChecked: false,
            },
            {
              label: "h_trad_club_elite_mania_chronometree",
              isChecked: false,
            },
            {
              label: "h_trad_club_3_mania_technique",
              isChecked: false,
            },
            {
              label: "h_trad_club_2_mania_technique",
              isChecked: false,
            },
            {
              label: "h_trad_club_1_mania_technique",
              isChecked: false,
            },
            {
              label: "h_trad_club_elite_mania_technique",
              isChecked: false,
            },
            { label: "h_trad_club_3_ranch_sorting", isChecked: false },
            { label: "h_trad_club_2_ranch_sorting", isChecked: false },
            { label: "h_trad_club_1_ranch_sorting", isChecked: false },
            { label: "h_trad_club_elite_ranch_sorting", isChecked: false },
            {
              label: "h_trad_club_elite_tri_techn_domestique",
              isChecked: false,
            },
            {
              label: "h_trad_club_elite_tri_chronometre",
              isChecked: false,
            },
          ],
        },
        amateur: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_trad_amat_3_prepa_3_tests", isChecked: false },
            { label: "h_trad_amat_3_3_tests", isChecked: false },
            { label: "h_trad_amat_2_3_tests", isChecked: false },
            { label: "h_trad_amat_1_3_tests", isChecked: false },
            { label: "h_trad_amat_1_junior_3_tests", isChecked: false },
            { label: "h_trad_amat_elite_3_tests", isChecked: false },
            { label: "h_trad_amat_3_prepa_2_tests", isChecked: false },
            { label: "h_trad_amat_3_2_tests", isChecked: false },
            { label: "h_trad_amat_2_2_tests", isChecked: false },
            { label: "h_trad_amat_1_2_tests", isChecked: false },
            { label: "h_trad_amat_1_junior_2_tests", isChecked: false },
            { label: "h_trad_amat_elite_2_tests", isChecked: false },
            { label: "h_trad_amat_3_prepa_4_tests", isChecked: false },
            { label: "h_trad_amat_3_4_tests", isChecked: false },
            { label: "h_trad_amat_2_4_tests", isChecked: false },
            { label: "h_trad_amat_1_4_tests", isChecked: false },
            { label: "h_trad_amat_1_junior_4_tests", isChecked: false },
            { label: "h_trad_amat_elite_4_tests", isChecked: false },
            {
              label: "h_trad_amat_3_prepa_mania_a_points",
              isChecked: false,
            },
            {
              label: "h_trad_amat_3_mania_a_points",
              isChecked: false,
            },
            {
              label: "h_trad_amat_2_mania_a_points",
              isChecked: false,
            },
            {
              label: "h_trad_amat_1_mania_a_points",
              isChecked: false,
            },
            {
              label: "h_trad_amat_elite_mania_a_points",
              isChecked: false,
            },
            {
              label: "h_trad_amat_3_prepa_mania_chronometree",
              isChecked: false,
            },
            {
              label: "h_trad_amat_3_mania_chronometree",
              isChecked: false,
            },
            {
              label: "h_trad_amat_2_mania_chronometree",
              isChecked: false,
            },
            {
              label: "h_trad_amat_1_mania_chronometree",
              isChecked: false,
            },
            {
              label: "h_trad_amat_elite_mania_chronometree",
              isChecked: false,
            },

            {
              label: "h_trad_amat_3_prepa_parc_de_pays",
              isChecked: false,
            },
            { label: "h_trad_amat_3_parc_de_pays", isChecked: false },
            { label: "h_trad_amat_2_parc_de_pays", isChecked: false },
            { label: "h_trad_amat_1_parc_de_pays", isChecked: false },
            {
              label: "h_trad_amat_elite_parc_de_pays",
              isChecked: false,
            },

            { label: "h_trad_amat_2_tri_tech_camarg", isChecked: false },
            { label: "h_trad_amat_1_tri_tech_camarg", isChecked: false },
            {
              label: "h_trad_amat_elite_tri_tech_camarg",
              isChecked: false,
            },
          ],
        },
      },
      paraatt: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_paraatt_club_2_solo_grd_I_II", isChecked: false },
            { label: "h_paraatt_club_1_solo_grd_I", isChecked: false },
            { label: "h_paraatt_club_1_solo_grd_II", isChecked: false },
            { label: "h_paraatt_club_elite_solo_grd_I", isChecked: false },
            { label: "h_paraatt_club_elite_solo_grd_II", isChecked: false },
            {
              label: "h_paraatt_club_elite_solo_gp_grd_I",
              isChecked: false,
            },
            {
              label: "h_paraatt_club_elite_solo_gp_grd_II",
              isChecked: false,
            },
            { label: "h_paraatt_derby_marathon_solo_grd_I", isChecked: false },
            { label: "h_paraatt_derby_marathon_solo_grd_II", isChecked: false },
          ],
        },
      },
      paradress: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_paradress_club_3_nv_1", isChecked: false },
            { label: "h_paradress_club_3_nv_2", isChecked: false },
            { label: "h_paradress_club_3_nv_3", isChecked: false },
            { label: "h_paradress_club_2_nv_1_imposee", isChecked: false },
            { label: "h_paradress_club_2_nv_2_imposee", isChecked: false },
            { label: "h_paradress_club_2_nv_3_imposee", isChecked: false },
            { label: "h_paradress_grade_I_club_1_imposee", isChecked: false },
            { label: "h_paradress_grade_II_club_1_imposee", isChecked: false },
            { label: "h_paradress_grade_III_club_1_imposee", isChecked: false },
            { label: "h_paradress_grade_IV_club_1_imposee", isChecked: false },
            { label: "h_paradress_grade_V_club_1_imposee", isChecked: false },
            { label: "h_paradress_club_2_nv_1_libre", isChecked: false },
            { label: "h_paradress_club_2_nv_2_libre", isChecked: false },
            { label: "h_paradress_club_2_nv_3_libre", isChecked: false },
            { label: "h_paradress_grade_I_club_1_libre", isChecked: false },
            { label: "h_paradress_grade_II_club_1_libre", isChecked: false },
            { label: "h_paradress_grade_III_club_1_libre", isChecked: false },
            { label: "h_paradress_grade_IV_club_1_libre", isChecked: false },
            { label: "h_paradress_grade_V_club_1_libre", isChecked: false },
          ],
        },
      },
      pied: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_pied_club_2_parcours_2_1", isChecked: false },
            { label: "h_pied_club_2_parcours_2_2", isChecked: false },
            { label: "h_pied_club_2_parcours_2_3", isChecked: false },
            { label: "h_pied_club_2_parcours_2_4", isChecked: false },
            { label: "h_pied_club_1_reprise_1_1", isChecked: false },
            { label: "h_pied_club_1_reprise_1_2", isChecked: false },
            { label: "h_pied_club_1_reprise_1_3", isChecked: false },
            { label: "h_pied_club_1_reprise_libre", isChecked: false },
            { label: "h_pied_club_elite_reprise_1", isChecked: false },
            { label: "h_pied_club_elite_reprise_2", isChecked: false },
            { label: "h_pied_club_elite_reprise_3", isChecked: false },
            { label: "h_pied_club_elite_reprise_4", isChecked: false },
            { label: "h_pied_club_elite_reprise_libre", isChecked: false },
          ],
        },
      },
      trec: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_trec_club_elite_speciale_indiv_ptv", isChecked: false },
            { label: "h_trec_club_1_speciale_indiv_ptv", isChecked: false },
            { label: "h_trec_club_2_speciale_indiv_ptv", isChecked: false },
            { label: "h_trec_club_3_speciale_indiv_ptv", isChecked: false },
            {
              label: "h_trec_club_elite_speciale_equipe_ptv",
              isChecked: false,
            },
            { label: "h_trec_club_1_speciale_equipe_ptv", isChecked: false },
            { label: "h_trec_club_2_speciale_equipe_ptv", isChecked: false },
            { label: "h_trec_club_3_speciale_equipe_ptv", isChecked: false },
            { label: "h_trec_club_elite_speciale_indiv_por", isChecked: false },
            { label: "h_trec_club_1_speciale_indiv_por", isChecked: false },
            { label: "h_trec_club_2_speciale_indiv_por", isChecked: false },
            { label: "h_trec_club_3_speciale_indiv_por", isChecked: false },
            {
              label: "h_trec_club_elite_speciale_equipe_por",
              isChecked: false,
            },
            { label: "h_trec_club_1_speciale_equipe_por", isChecked: false },
            { label: "h_trec_club_2_speciale_equipe_por", isChecked: false },
            { label: "h_trec_club_3_speciale_equipe_por", isChecked: false },
            { label: "h_trec_club_elite_indiv", isChecked: false },
            { label: "h_trec_club_elite_equipe", isChecked: false },
            { label: "h_trec_club_1_indiv", isChecked: false },
            { label: "h_trec_club_1_equipe", isChecked: false },
            { label: "h_trec_club_2_indiv", isChecked: false },
            { label: "h_trec_club_2_equipe", isChecked: false },
            { label: "h_trec_club_3_indiv", isChecked: false },
            { label: "h_trec_club_3_equipe", isChecked: false },
          ],
        },
        amateur: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_trec_amateur_1_speciale_indiv_ptv", isChecked: false },
            { label: "h_trec_amateur_2_speciale_indiv_ptv", isChecked: false },
            { label: "h_trec_amateur_3_speciale_indiv_ptv", isChecked: false },
            { label: "h_trec_amateur_1_speciale_equipe_por", isChecked: false },
            { label: "h_trec_amateur_2_speciale_equipe_por", isChecked: false },
            { label: "h_trec_amateur_3_speciale_equipe_por", isChecked: false },
            { label: "h_trec_amateur_1_indiv", isChecked: false },
            { label: "h_trec_amateur_1_duo", isChecked: false },
            { label: "h_trec_amateur_2_indiv", isChecked: false },
            { label: "h_trec_amateur_2_duo", isChecked: false },
            { label: "h_trec_amateur_3_indiv", isChecked: false },
            { label: "h_trec_amateur_3_duo", isChecked: false },
          ],
        },
      },
      tri_ride: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_tri_ride_club_3_trio_femme", isChecked: false },
            { label: "h_tri_ride_club_3_trio_homme", isChecked: false },
            { label: "h_tri_ride_club_3_trio_mixte", isChecked: false },
            { label: "h_tri_ride_club_2_trio_femme", isChecked: false },
            { label: "h_tri_ride_club_2_trio_homme", isChecked: false },
            { label: "h_tri_ride_club_2_trio_mixte", isChecked: false },
            { label: "h_tri_ride_club_1_trio_femme", isChecked: false },
            { label: "h_tri_ride_club_1_trio_homme", isChecked: false },
            { label: "h_tri_ride_club_1_trio_mixte", isChecked: false },
          ],
        },
      },
      voltige: {
        isChecked: false,
        isVisible: false,
        club: {
          isChecked: false,
          isVisible: false,
          categories: [
            { label: "h_voltige_club_elite_individuel", isChecked: false },
            { label: "h_voltige_club_1_individuel", isChecked: false },
            { label: "h_voltige_club_2_individuel", isChecked: false },
            { label: "h_voltige_club_elite_pas_de_2", isChecked: false },
            { label: "h_voltige_club_1_pas_de_2", isChecked: false },
            { label: "h_voltige_club_2_pas_de_2", isChecked: false },
          ],
        },
      },
    },
  };
}

function InitConfiguration(windowConfig, stateConfig) {
  let isChanged = false;
  for (const key in stateConfig) {
    if (
      typeof stateConfig[key] === "object" &&
      !Array.isArray(stateConfig[key])
    ) {
      if (windowConfig[key] !== undefined) {
        const keys = Object.keys(windowConfig[key]);
        if (keys.length > 0) {
          stateConfig[key].isChecked = true;
          isChanged = true;
        }
        if (typeof stateConfig[key] === "object") {
          if (InitConfiguration(windowConfig[key], stateConfig[key])) {
            isChanged = true;
          }
        }
      }
    } else if (
      typeof stateConfig[key] === "object" &&
      Array.isArray(stateConfig[key])
    ) {
      if (windowConfig !== undefined && Array.isArray(windowConfig)) {
        for (const category of windowConfig) {
          const stateCategory = stateConfig[key].find(
            (discipline) => discipline.label === category
          );

          if (stateCategory !== undefined) {
            stateCategory.isChecked = true;
            isChanged = true;
          }
        }
      }
    }
  }
  return isChanged;
}

export function ignoreObjectProperty(obj, property) {
  Object.keys(obj).forEach((key) => {
    if (key === property) {
      delete obj[key];
    } else if (typeof obj[key] === "object") {
      ignoreObjectProperty(obj[key], property);
    }
  });
}

export function resetConfiguration(stateConfig, initialStateConfig) {
  for (const key in stateConfig) {
    if (key === "isChecked") {
      stateConfig[key] = initialStateConfig[key];
    } else if (
      typeof stateConfig[key] === "object" &&
      !Array.isArray(stateConfig[key])
    ) {
      if (typeof stateConfig[key] === "object") {
        resetConfiguration(stateConfig[key], initialStateConfig[key]);
      }
    } else if (
      typeof stateConfig[key] === "object" &&
      Array.isArray(stateConfig[key])
    ) {
      for (const category of stateConfig[key]) {
        const initialStateCategory = initialStateConfig[key].find(
          (discipline) => discipline.label === category.label
        );
        if (initialStateCategory === undefined) {
          category.isChecked = false;
        } else {
          category.isChecked = initialStateCategory.isChecked;
        }
      }
    }
  }
}

export function useGetAllCategories() {
  const template = GetConfigurationTemplate();

  return useMemo(() => {
    const categories = [];
    for (const key in template) {
      if (typeof template[key] === "object") {
        for (const discipline in template[key]) {
          if (typeof template[key][discipline] === "object") {
            for (const type in template[key][discipline]) {
              if (typeof template[key][discipline][type] === "object") {
                for (const category of template[key][discipline][type]
                  .categories) {
                  if (!categories.includes(category.label)) {
                    categories.push(category.label);
                  }
                }
              }
            }
          }
        }
      }
    }
    return categories;
  }, [template]);
}

export function useGetCurrentConfiguration() {
  return useCallback((categories) => {
    const config = GetConfigurationTemplate();
    InitConfiguration(categories, config);
    return config;
  }, []);
}

export function sortCategories(equide, discipline, type, categories) {
  if (categories.length === 0) {
    return [];
  }
  try {
    const categoriesArray =
      window.config.categories[equide][discipline][type] || [];

    //Sort categories by order of categoriesArray
    const sortedCategories = categories.sort((a, b) => {
      const aIndex = categoriesArray.indexOf(a.category);
      const bIndex = categoriesArray.indexOf(b.category);
      if (aIndex < bIndex) {
        return -1;
      }
      if (aIndex > bIndex) {
        return 1;
      }
      return 0;
    });
    return sortedCategories;
  } catch {
    return [];
  }
}

export const useGetAllDisciplines = () => {
  return useMemo(() => {
    const template = GetConfigurationTemplate();
    const disciplines = [];
    for (const key in template) {
      if (typeof template[key] === "object") {
        for (const discipline in template[key]) {
          if (!disciplines.includes(discipline)) {
            disciplines.push(discipline);
          }
        }
      }
    }
    return disciplines;
  }, []);
};

export const useGetChipColor = () => {
  const disciplines = useGetAllDisciplines();
  return useCallback(
    (discipline) => {
      const categoryIndex = disciplines.findIndex(
        (category) => category === discipline
      );

      let color = null;
      //if the category is not in the list, return a random color
      if (categoryIndex === -1) {
        console.log("category not found", discipline);
        color = chipColors[Math.floor(Math.random() * chipColors.length)];
      } else {
        //if the category is in the list, return the color of the category with modulo
        color = chipColors[categoryIndex % chipColors.length];
      }

      return color;
    },
    [disciplines]
  );
};

export const useUsedCategories = () => {
  const config = useGetConfig();

  const categories = [];

  // get all object keys
  Object.keys(config.categories["horse"]).forEach((category) => {
    categories.push(category);
  });

  Object.keys(config.categories["pony"]).forEach((category) => {
    if (!categories.includes(category)) {
      categories.push(category);
    }
  });

  return categories;
};
