import { Grid2, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import Box from "@mui/material/Box";
import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";
import CircularProgress from "@mui/material/CircularProgress";
import SubscriptionCard from "./SubscriptionCard";
import SubscriptionsFilter from "./SubscriptionsFilter";
import moment from "moment";
import { useCancelSubscription } from "../../../Features/Mutations/userSubscription";
import { useGetConfig } from "../../../Features/config";
import { useListUserSubscriptions } from "../../../Features/apiFetch";

export default function Subscriptions() {
  const [filters, setFilters] = useState(["contest_incoming"]);
  const [sort, setSort] = useState("contest_date_asc");
  const subscriptions = useListUserSubscriptions();
  const config = useGetConfig();
  const handleFiltersChange = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  const mainGridSx = useMemo(() => {
    if (subscriptions?.length === 0) {
      return {
        minHeight: "calc(100vh - 86px - 140px)",
        backgroundImage: `url(${config?.notFoundBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) =>
          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
    }
  }, [subscriptions, config?.notFoundBackground]);

  const mutationCancel = useCancelSubscription(subscriptions);

  const handleSortChange = useCallback((newSort) => {
    setSort(newSort);
  }, []);

  const subscriptionsSorted = useMemo(() => {
    const sortedSubscriptions = subscriptions;
    if (!sortedSubscriptions) return null;
    sortedSubscriptions.sort((a, b) => {
      if (sort === "contest_date_desc") {
        return moment(b.contest.date) - moment(a.contest.date);
      } else if (sort === "contest_date_asc") {
        return moment(a.contest.date) - moment(b.contest.date);
      } else if (sort === "contest_name_desc") {
        return b.contest.place.localeCompare(a.contest.place);
      } else if (sort === "contest_name_asc") {
        return a.contest.place.localeCompare(b.contest.place);
      } else if (sort === "subscriber_name_desc") {
        return b.subscriberName.localeCompare(a.subscriberName);
      }
      return a.subscriberName.localeCompare(b.subscriberName);
    });
    return sortedSubscriptions;
  }, [subscriptions, sort]);

  const breadcrumbItems = useMemo(() => {
    return [
      {
        label: "Accueil",
        link: "/",
      },
      {
        label: "Inscriptions",
      },
    ];
  }, []);

  return (
    <>
      <Grid2
        container
        spacing={0}
        sx={{
          maxWidth: "100%",
          marginTop: "86px",
          ...mainGridSx,
        }}
      >
        <Grid2
          size={{ xs: 12, sm: 4 }}
          sx={{
            display: "flex",
            alignItems: "baseline",
            xs: { display: "none" },
          }}
        >
          <BreadcrumbComponent items={breadcrumbItems} />
        </Grid2>
        {subscriptionsSorted?.length > 0 && (
          <Grid2
            textAlign={"right"}
            sx={{ px: 4, marginTop: "14px" }}
            size={{ xs: 12, sm: 8 }}
          >
            <SubscriptionsFilter
              onChange={handleFiltersChange}
              onSort={handleSortChange}
            />
          </Grid2>
        )}
        {subscriptionsSorted === null && (
          <Grid2
            size={12}
            sx={{ margin: "auto", height: "30vh", display: "flex" }}
          >
            <Box sx={{ display: "flex", margin: "auto" }}>
              <CircularProgress />
            </Box>
          </Grid2>
        )}
        {subscriptionsSorted?.length === 0 && (
          <Grid2
            textAlign={"center"}
            sx={{ px: 4, mb: 4, display: "flex", justifyContent: "center" }}
            alignItems="flex-end"
            size={12}
          >
            <Typography
              variant="h5"
              align="center"
              sx={{
                p: 2,
                color: "#eeeeee",
              }}
            >
              Vous n&apos;êtes inscrit à aucun concours
            </Typography>
          </Grid2>
        )}
      </Grid2>
      {subscriptionsSorted != null && subscriptionsSorted?.length > 0 && (
        <Typography
          variant="h6"
          align="center"
          sx={{
            p: 2,
          }}
        >
          Inscriptions des 12 derniers mois
        </Typography>
      )}
      {subscriptionsSorted != null &&
        subscriptionsSorted?.map((subscription) => (
          <SubscriptionCard
            key={subscription.guid}
            subscription={subscription}
            filters={filters}
            cancelSubscriptionMutation={mutationCancel}
          />
        ))}
    </>
  );
}
