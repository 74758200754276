import { useCanRegisterToContest, useGetUser } from "./login";

import { useQuery } from "@tanstack/react-query";
import { useGet } from "./apiFetch";

export const usePaymentMetods = () => {
  const user = useGetUser();
  const canRegisterToContest = useCanRegisterToContest(user);
  const get = useGet();
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["payment-method"],
    queryFn: async () => {
      return get(`/billing/account/payment-method`);
    },
    enabled: canRegisterToContest,
  });

  return {
    data: data?.data,
    isLoading,
    isError,
    error,
  };
};
