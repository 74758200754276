import React, { useEffect } from "react";
import { useGetUser, useIsAdmin } from "../../Features/login";

import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";

export default function Administration() {
  const user = useGetUser();
  const isAdmin = useIsAdmin(user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isLoading) return;

    if (!isAdmin) {
      navigate("/403");
    }
  }, [user, navigate, isAdmin]);

  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Outlet />
      <Footer id="FOOTER" />
    </>
  );
}
