import Box from "@mui/material/Box";
import BreadcrumbComponent from "../BreadcrumbComponent/BreadcrumbComponent";
import CssBaseline from "@mui/material/CssBaseline";
import Footer from "../Footer/Footer";
import Grid2 from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import React from "react";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";
import { useGetConfig } from "../../Features/config";

export default function Layout({ breadcrumb, children }) {
  const config = useGetConfig();

  return (
    <>
      <ResponsiveAppBar />
      <Grid2
        container
        component="main"
        sx={{ height: "100%", marginTop: "86px" }}
      >
        <CssBaseline />
        <Grid2 size={12}>
          <BreadcrumbComponent items={breadcrumb} />
        </Grid2>
        <Grid2
          size={{ xs: false, sm: false, md: 4, lg: 6, xl: 7 }}
          sx={{
            backgroundImage: `url(${config?.createContestBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "calc(100vh - 86px - 192px)",
            display: {
              xs: "none",
              sm: "none",
              md: "inherit",
            },
          }}
        />
        <Grid2
          size={{ xs: 12, sm: 12, md: 8, lg: 6, xl: 5 }}
          component={Paper}
          elevation={0}
          square
        >
          <Box
            sx={{
              my: "auto",
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
            }}
          >
            {children}
          </Box>
        </Grid2>
      </Grid2>
      <Footer />
    </>
  );
}
