import {
  List,
  ListItem,
  ListItemButton,
  ListSubheader,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";

import HorseCalendarDetailItem from "./HorseCalendarDetailItem";
import moment from "moment";
import { useListCalendarEvents } from "../../../Features/Mutations/horsecalendar";
import { useNavigate } from "react-router-dom";

const HorseCalendarDetail = ({ horse, startDate, canEdit }) => {
  const isMobile = useMediaQuery("(max-width:900px)");
  const { events, isLoading } = useListCalendarEvents(horse?.guid, startDate);

  const navigate = useNavigate();

  const formatDate = useCallback((date) => {
    return moment(date).format("DD MMMM YYYY");
  }, []);

  const isToday = useCallback((date) => {
    return moment(date).isSame(moment(), "day");
  }, []);

  const nearestDate = useCallback(() => {
    // events is a dictionary with date as key at format YYYY-MM-DD
    // get the today date if it exists in the events
    // or the date just before today
    if (!events) {
      return null;
    }

    const today = moment().format("YYYY-MM-DD");
    if (events[today]) {
      return today;
    }
    // get the date just before today
    const dates = Object.keys(events);
    const index = dates.findIndex((date) => date > today);
    if (index === -1) {
      return dates[dates.length - 1];
    }
    return dates[index - 1];
  }, [events]);

  const handleShowEntry = useCallback(
    (entryGuid, date) => {
      navigate(`/horse-calendar/${horse.guid}/edit/${entryGuid}?date=${date}`);
    },
    [horse, navigate]
  );

  useEffect(() => {
    if (events) {
      const date = nearestDate();
      if (date) {
        if (isMobile) {
          // Apply a offset to the scroll to take into account the sticky header
          const element = document.getElementById(date);
          if (element) {
            const offset = 300;
            const bodyRect = document.body.getBoundingClientRect().top;
            const elementRect = element.getBoundingClientRect().top;
            const elementPosition = elementRect - bodyRect;
            window.scrollTo({
              top: elementPosition - offset,
              behavior: "smooth",
            });
          }
        } else {
          const element = document.getElementById(date);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    }
  }, [events, isMobile, nearestDate]);

  return (
    <>
      {!isLoading && events && Object.keys(events).length !== 0 && (
        <List>
          {Object.keys(events).map((date) => (
            <React.Fragment key={date}>
              <ListSubheader
                color={isToday(date) ? "primary" : "default"}
                id={date}
                key={date}
              >
                {formatDate(date)}
              </ListSubheader>
              {events[date].map((event) => (
                <ListItem key={event.eventGuid}>
                  {canEdit ? (
                    <ListItemButton
                      onClick={() => handleShowEntry(event.eventGuid, date)}
                    >
                      <HorseCalendarDetailItem event={event} />
                    </ListItemButton>
                  ) : (
                    <HorseCalendarDetailItem event={event} />
                  )}
                </ListItem>
              ))}
            </React.Fragment>
          ))}
        </List>
      )}
      {isLoading && (
        <Typography sx={{ mt: 4, textAlign: "center" }}>
          Chargement en cours... Veuillez patienter
        </Typography>
      )}
      {!isLoading && events && Object.keys(events).length === 0 && (
        <Typography sx={{ mt: 4, textAlign: "center" }}>
          Aucun événement pour les 30 prochains jours
        </Typography>
      )}
    </>
  );
};

export default HorseCalendarDetail;
