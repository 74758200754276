import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid2,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useGetUser, useIsModerator } from "../../../Features/login";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import { errorAtom } from "../../../Features/atom";
import moment from "moment";
import { useGetConfig } from "../../../Features/config";
import { useNavigate } from "react-router-dom";
import { usePostFormData } from "../../../Features/apiFetch";
import { useRecoilState } from "recoil";

const HorseCard = ({ horse, userRiders, isFiltered, onDelete, onEdit }) => {
  const user = useGetUser();
  const isModerator = useIsModerator(user);
  const postData = usePostFormData();
  const navigate = useNavigate();
  const config = useGetConfig();
  const [horsePicture, setHorsePicture] = useState(
    horse && horse.pictureUrl ? horse.pictureUrl : null
  );
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  const avatarColor = useCallback((name) => {
    const colors = [
      "#FF5733",
      "#33FF57",
      "#3357FF",
      "#FF33A1",
      "#FF8C33",
      "#33FFF5",
      "#8C33FF",
      "#FF3333",
      "#33FF8C",
      "#5733FF",
      "#FF5733",
      "#33FF57",
      "#3357FF",
      "#FF33A1",
      "#FF8C33",
      "#33FFF5",
      "#8C33FF",
      "#FF3333",
      "#33FF8C",
      "#5733FF",
      "#FF5733",
      "#33FF57",
      "#3357FF",
      "#FF33A1",
      "#FF8C33",
      "#33FFF5",
    ];
    const index = name.charCodeAt(0) % colors.length;
    return colors[index];
  }, []);

  const handleDelete = useCallback(() => {
    onDelete(horse);
  }, [onDelete, horse]);

  const handleEdit = useCallback(() => {
    onEdit(horse);
  }, [onEdit, horse]);

  const handleUpload = useCallback(
    async (event) => {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const res = await postData(
        `/horse/upload?horseGuid=${horse.guid}`,
        formData
      );
      if (res.statusCode === 200) {
        setHorsePicture(res.data.url);
      } else {
        setError({
          message: "Erreur lors de l'enregistrement de la photo du cheval.",
          severity: "error",
        });
        return;
      }
    },
    [horse.guid, postData, setError]
  );

  const handleShowCalendar = useCallback(() => {
    navigate(`/horse-calendar/${horse.guid}`);
  }, [navigate, horse.guid]);

  const age = useMemo(() => {
    if (!horse.birthDate) return "-"; // Handle case where birthDate is not available
    const birthDate = moment(horse.birthDate, "yyyy-MM-dd");
    const currentAge = moment().diff(birthDate, "years");
    return currentAge;
  }, [horse]);

  const riders = useMemo(() => {
    let riders = [];

    if (horse.ownerName) {
      riders.push(horse.ownerName);
    }

    if (horse.riders) {
      riders = [...riders, ...horse.riders.map((rider) => rider.name)];
    }

    return riders;
  }, [horse]);

  const isHorseManager = useMemo(() => {
    return horse.userIsOwner || isModerator;
  }, [horse, isModerator]);

  const canEdit = useMemo(() => {
    return isHorseManager || config?.horseManagement === 1;
  }, [config?.horseManagement, isHorseManager]);

  const canEditPicture = useMemo(() => {
    if (isHorseManager) return true;
    // search if horse.riders match one of the userRiders
    // or one of userRiders's parent

    if (!userRiders) return false;

    for (let i = 0; i < userRiders.length; i++) {
      const userRider = userRiders[i];
      if (horse.ownerGuid === userRider.guid) return true;

      if (horse.riders) {
        for (let j = 0; j < horse.riders.length; j++) {
          const horseRider = horse.riders[j];
          if (
            horseRider.guid === userRider.guid ||
            horseRider.guid === userRider.parentGuid
          )
            return true;
        }
      }
    }

    return false;
  }, [isHorseManager, horse.ownerGuid, horse.riders, userRiders]);

  const picture = useMemo(() => {
    if (horsePicture) {
      return `${process.env.REACT_APP_CLOUDFRONT}/${horsePicture}`;
    }
    return `${process.env.REACT_APP_CLOUDFRONT}/placeholder.webp`;
  }, [horsePicture]);

  return (
    <Card
      sx={{
        minWidth: 275,
        ml: 4,
        mr: 4,
        mt: 1,
        mb: 1,
        display: isFiltered ? "none" : "flex",
      }}
    >
      <Box sx={{ minWidth: 150, minHeight: 150, position: "relative" }}>
        {canEditPicture && (
          <input
            accept="image/*"
            style={{ display: "none" }}
            id={`upload-${horse.guid}`}
            onChange={handleUpload}
            type="file"
          />
        )}
        <CardMedia component="img" sx={{ width: 150 }} image={picture} />
        {canEditPicture && (
          <label htmlFor={`upload-${horse.guid}`}>
            <IconButton
              color="white"
              aria-label="upload picture"
              component="span"
              sx={{
                position: "absolute",
                left: "10px",
                top: "0px",
                color: "grey",
                filter: "drop-shadow(2px 2px 2px rgb(255 255 255))",
              }}
            >
              <UploadIcon />
            </IconButton>
          </label>
        )}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <CardContent sx={{ flex: "1 0 auto" }}>
          <Grid2 container>
            <Grid2 size={{ xs: 12, sm: 7 }} sx={{ textAlign: "left" }}>
              <Stack direction="column" spacing={0}>
                <Typography variant="body1" component="div">
                  {horse.name}
                </Typography>
              </Stack>
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 5 }} sx={{ textAlign: "right" }}>
              <Stack
                direction="row"
                spacing={1}
                sx={{
                  justifyContent: "flex-end",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <AvatarGroup max={4}>
                  {riders.map((rider) => (
                    <Avatar
                      key={rider}
                      sx={{
                        width: 28,
                        height: 28,
                        bgcolor: avatarColor(rider),
                      }}
                      title={rider}
                    >
                      {rider[0]}
                    </Avatar>
                  ))}
                </AvatarGroup>
              </Stack>
            </Grid2>
            <Grid2 size={12} sx={{ textAlign: "left" }}>
              <Typography
                variant="body2"
                noWrap
                component="div"
                sx={{ minWidth: "50px" }}
              >
                {age}&nbsp;ans
              </Typography>
              <Typography variant="body2" component="div">
                ration : {horse.ration?.replace(/\s+/g, "")}
                {horse.hayAddon && " - Supplément foin"}
              </Typography>
            </Grid2>
          </Grid2>
        </CardContent>
        <CardActions disableSpacing>
          {canEdit && (
            <IconButton onClick={handleEdit}>
              <EditIcon />
            </IconButton>
          )}

          <IconButton>
            <CalendarMonthIcon onClick={handleShowCalendar} />
          </IconButton>

          <Box sx={{ flexGrow: 1 }} />
          {isHorseManager && (
            <IconButton onClick={handleDelete}>
              <DeleteIcon />
            </IconButton>
          )}
        </CardActions>
      </Box>
    </Card>
  );
};

export default HorseCard;
