import { CssBaseline, Grid2, Typography } from "@mui/material";
import React, { useMemo } from "react";

import BreadcrumbComponent from "../BreadcrumbComponent/BreadcrumbComponent";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";
import { useGetConfig } from "../../Features/config";

export default function ServiceConditions() {
  const config = useGetConfig();

  const breadcrumbItems = useMemo(() => {
    return [
      { label: "Accueil", link: "/" },
      { label: "Conditions de service" },
    ];
  }, []);

  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Grid2
        container
        spacing={0}
        sx={{
          maxWidth: "100%",
          marginTop: "86px",
        }}
      >
        <CssBaseline />
        <Grid2 size={12}>
          <BreadcrumbComponent items={breadcrumbItems} />
        </Grid2>
        <Grid2
          size={{ xs: false, sm: false, md: 4, lg: 5, xl: 6 }}
          sx={{
            pl: 4,
            backgroundImage: `url(${config?.homeBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "calc(100vh - 86px - 192px)",
            display: {
              xs: "none",
              sm: "none",
              md: "inherit",
            },
          }}
        ></Grid2>
        <Grid2
          sx={{
            pl: 4,
            pr: { md: 0, lg: 0, xl: 0, sm: 4, xs: 4 },
            margin: "auto",
            mt: 3,
            overflow: { md: "scroll" },
            height: { md: "calc(100vh - 86px - 218px)" },
          }}
          size={{ xs: 12, sm: 12, md: 8, lg: 7, xl: 6 }}
        >
          <Typography variant="h5" component="div">
            Conditions de service
          </Typography>
          <Typography
            variant="h6"
            color="text.primary"
            component="div"
            sx={{ mt: 2 }}
          >
            Dernière mise à jour : 23 avril 2024
          </Typography>
          <Typography variant="body" sx={{ mr: 2 }}>
            Bienvenue sur {window.location.hostname} ! Nous sommes ravis de vous
            accueillir sur notre plateforme de gestion des inscriptions aux
            concours équestres. L'utilisation de nos services est soumise aux
            conditions suivantes, veuillez les lire attentivement.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            1. Acceptation des Conditions
          </Typography>
          <Typography variant="body">
            En utilisant les services de {window.location.hostname}, vous
            acceptez sans réserve ces Conditions de Service. Si vous n'acceptez
            pas ces conditions, veuillez ne pas utiliser notre site.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            2. Utilisation du Service
          </Typography>
          <Typography variant="body">
            Vous êtes responsable de l'utilisation du service de manière légale
            et conforme aux présentes conditions. Vous vous engagez à ne pas
            utiliser le service à des fins illégales ou interdites par la loi.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            3. Propriété et Gestion du Site
          </Typography>
          <Typography variant="body">
            Le service {window.location.hostname} est géré par VelvetHoof.
            Toutes les questions, préoccupations ou demandes de support doivent
            être adressées à VelvetHoof à l'adresse{" "}
            {process.env.REACT_APP_EMAIL}.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            4. Inscriptions aux Concours
          </Typography>
          <Typography variant="body">
            Notre plateforme facilite le processus d'inscription aux concours
            équestres pour les clubs d'équitation. En utilisant ce service, vous
            acceptez de fournir des informations précises et complètes lors de
            l'inscription. Vous êtes responsable du maintien de la
            confidentialité de votre compte et du mot de passe associé.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            5. Annulations et Remboursements
          </Typography>
          <Typography variant="body">
            Les conditions d'annulation et de remboursement pour les
            inscriptions aux concours sont déterminées par les règles
            spécifiques de chaque concours. Veuillez contacter le club équestre
            concerné pour toute question relative à l'annulation ou au
            remboursement.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            6. Responsabilités
          </Typography>
          <Typography variant="body">
            Nous nous efforçons de fournir un service fiable, mais nous ne
            pouvons garantir l'absence d'interruptions ou d'erreurs. Nous ne
            sommes pas responsables des pertes, dommages ou préjudices résultant
            de l'utilisation de notre service.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            7. Paiements en ligne
          </Typography>
          <Typography variant="body">
            Nous utilisons Stripe, un prestataire de services de paiement tiers,
            pour traiter les transactions effectuées sur notre site. En
            utilisant notre plateforme pour effectuer des paiements en ligne,
            vous acceptez également les conditions d'utilisation et la politique
            de confidentialité de Stripe. Nous ne conservons pas les détails de
            votre carte de crédit sur nos serveurs; ceux-ci sont directement
            traités par Stripe conformément à leurs normes de sécurité et de
            confidentialité.
            <br />
            <Link to="https://stripe.com/fr/legal/consumer" target="_blank">
              Stripe - Conditions d'utilisation du service pour les
              consommateurs
            </Link>
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            8. Modification des Conditions
          </Typography>
          <Typography variant="body">
            Nous nous réservons le droit de modifier ces Conditions de Service à
            tout moment. Les modifications prendront effet immédiatement après
            leur publication sur le site. Il est de votre responsabilité de
            consulter régulièrement les conditions pour être informé(e) des
            éventuelles mises à jour.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            9. Résiliation du Service
          </Typography>
          <Typography variant="body" sx={{ mt: 2 }} component="div">
            <b>9.1. Résiliation par VelvetHoof / le club</b> : Nous nous
            réservons le droit de résilier ou de suspendre votre accès au
            service à tout moment, sans préavis, en cas de violation de ces
            conditions ou pour toute autre raison que nous jugerions appropriée.
          </Typography>
          <Typography variant="body" sx={{ mt: 2 }} component="div">
            <b>9.2. Résiliation par l'utilisateur</b> : Vous pouvez résilier
            votre compte à tout moment en éditant les paramètres de votre
            compte. Une fois votre compte résilié, nous supprimerons toutes les
            informations associées à votre compte, à l'exception des données
            nécessaires à des fins de comptabilité ou de conformité.
            <br />
            Pour supprimer votre compte vous devez être à jour dans vos
            paiements.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            10. Contact
          </Typography>
          <Typography variant="body" sx={{ mt: 2 }} component="div">
            Si vous avez des questions ou des préoccupations concernant ces
            Conditions de Service, veuillez nous contacter à{" "}
            {process.env.REACT_APP_EMAIL}
            <br />
            Merci de choisir {window.location.hostname} pour la gestion de vos
            inscriptions aux concours équestres.
          </Typography>
        </Grid2>
      </Grid2>
      <Footer id="FOOTER" />
    </>
  );
}
