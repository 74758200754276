import React, { useEffect, useMemo } from "react";

import { Helmet } from "react-helmet";
import { useRecoilState } from "recoil";
import { isStripeEnabledAtom } from "../../Features/atom";
import { useGetConfig } from "../../Features/config";

export default function AppHelmet() {
  const config = useGetConfig();
  const [_, setIsStripeEnabled] = useRecoilState(isStripeEnabledAtom); // eslint-disable-line no-unused-vars

  const title = useMemo(() => {
    let title = "";
    if (config?.club?.shortName) {
      title = config?.club?.shortName;
    } else if (config?.club?.name) {
      title = config?.club?.name;
    }

    return `${title} - Gestion des concours`;
  }, [config]);

  const baseUrl = useMemo(() => {
    return `${process.env.REACT_APP_CLOUDFRONT}/${window.location.hostname}`;
  }, []);

  const relUrl = useMemo(() => {
    return window.location.hostname.replace(".concours-equestre.fr", "");
  }, []);

  useEffect(() => {
    window.tarteaucitron.services.stripe = {
      key: "stripe",
      type: "api",
      name: "Stripe",
      needConsent: true,
      cookies: ["m", "__stripe_mid", "__stripe_sid"],
      readmoreLink: "https://stripe.com/privacy",
      js: function () {
        console.log("AppHelmet - enable stripe");
        setIsStripeEnabled(true);
      },
      fallback: function () {
        console.log("AppHelmet - disable stripe");
        setIsStripeEnabled(false);
      },
    };
    (window.tarteaucitron.job = window.tarteaucitron.job || []).push("stripe");
  }, [setIsStripeEnabled]);

  return (
    <Helmet>
      <title>{title}</title>
      <link rel="icon" href={`${baseUrl}/favicon.ico?${Date.now()}`} />
      <link rel="apple-touch-icon" href={`${baseUrl}/${config?.logo}`} />
      <link rel="manifest" href={`/${relUrl}/manifest.json`} />
      <link rel="stylesheet" href={`/tarteaucitronskin.css`} />
    </Helmet>
  );
}
