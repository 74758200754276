import { Button, Grid2, Typography } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useGetUser, useIsModerator } from "../../../Features/login";

import HorseCard from "./HorseCard";
import HorseFilter from "./HorseFilter";
import { useGetConfig } from "../../../Features/config";
import { useListRiders } from "../../../Features/Mutations/riders";

const HorseTable = ({ horses, onAddHorse, onDeleteHorse, onEditHorse }) => {
  const [sort, setSort] = useState("horse_name_desc");
  const [filters, setFilters] = useState([]);
  const riders = useListRiders();
  const user = useGetUser();
  const isManager = useIsModerator(user);
  const config = useGetConfig();

  const sortedHorse = useMemo(() => {
    return horses.sort((a, b) => {
      if (a.name < b.name) {
        return sort === "horse_name_desc" ? -1 : 1;
      }
      if (a.name > b.name) {
        return sort === "horse_name_desc" ? 1 : -1;
      }
      return 0;
    });
  }, [horses, sort]);

  const filterItems = useMemo(() => {
    let filteredHorse = sortedHorse;

    for (let i = 0; i < filters.length; i++) {
      const filter = filters[i];
      if (filter.type === "free") {
        filteredHorse = filteredHorse.filter((horse) =>
          horse.name.toLowerCase().includes(filter.value.toLowerCase())
        );
      } else if (filter.type === "item") {
        switch (filter.value) {
          case "my_horses":
            filteredHorse = filteredHorse.filter((horse) => {
              if (horse.userIsOwner) {
                return true;
              }

              // Check from horse riders if one match the current riders
              for (let j = 0; j < horse.riders.length; j++) {
                const rider = horse.riders[j];
                if (
                  riders &&
                  riders.riders &&
                  riders.riders.find((r) => r.guid === rider.guid)
                ) {
                  return true;
                }
              }

              return false;
            });
            break;
          case "club_horses":
            filteredHorse = filteredHorse.filter((horse) => !horse.hasOwner);
            break;
          case "today_action":
            console.warn("today_action filter is not implemented");
            break;
          default:
            break;
        }
      }
    }

    return filteredHorse;
  }, [sortedHorse, filters, riders]);

  const canCreateHorse = useMemo(() => {
    return config?.horseManagement === 1 || isManager;
  }, [config?.horseManagement, isManager]);

  const handleSortChange = useCallback((newSort) => {
    setSort(newSort);
  }, []);

  const handleFiltersChange = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  return (
    <>
      <Grid2
        size={12}
        sx={{
          minWidth: { xs: "100%", sm: "100%", md: "auto" },
          mb: 1,
          mt: 1.2,
        }}
      >
        <Grid2 container justifyContent="space-between">
          {canCreateHorse && (
            <Grid2
              size={{ xs: 12, sm: 12, md: 6 }}
              sx={{
                textAlign: { xs: "center", sm: "center", md: "left" },
              }}
            >
              <Button variant="contained" onClick={onAddHorse}>
                Ajouter un cheval
              </Button>
            </Grid2>
          )}
          <Grid2 size={{ xs: 12, sm: 12, md: 6 }}>
            <HorseFilter
              onChange={handleFiltersChange}
              onSort={handleSortChange}
            />
          </Grid2>
        </Grid2>
      </Grid2>
      <Grid2
        size={12}
        sx={{ minWidth: { xs: "100%", sm: "100%", md: "auto" }, mb: 1 }}
      >
        {filterItems && filterItems.length > 0 ? (
          filterItems.map((horse) => (
            <HorseCard
              key={horse.guid}
              horse={horse}
              userRiders={riders.riders}
              onDelete={onDeleteHorse}
              onEdit={onEditHorse}
            />
          ))
        ) : (
          <Typography>Aucun cheval déclaré</Typography>
        )}
      </Grid2>
    </>
  );
};

export default HorseTable;
