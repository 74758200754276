import {
  Badge,
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import React, { useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import HorseCalendarAlert from "./HorseCalendarAlert";

const HorseCalendarViewMobile = ({
  horseGuid,
  onAddEntry,
  onDateChange,
  onMonthChange,
  date,
  isLoading,
  alerts,
  ration,
  canEdit,
  hayAddon,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleAlertsClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleAlertsClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleAlertsClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {!isLoading &&
          alerts?.length !== 0 &&
          alerts?.map((alert) => (
            <MenuItem
              key={alert.eventGuid}
              sx={{ width: "100%" }}
              onClick={handleAlertsClose}
            >
              <HorseCalendarAlert
                alert={alert}
                sx={{ width: "100%" }}
                horseGuid={horseGuid}
              />
            </MenuItem>
          ))}
      </Menu>
      <Stack
        direction="column"
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          my: 2,
          width: "100%",
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
            width: "100%",
            pr: 1,
          }}
          spacing={1}
        >
          {canEdit && (
            <Button variant="contained" onClick={onAddEntry} size="small">
              <AddIcon />
            </Button>
          )}
          {!isLoading && alerts?.length !== 0 && (
            <IconButton onClick={handleAlertsClick}>
              <Badge badgeContent={alerts?.length} color="warning">
                <ErrorIcon color="primary" />
              </Badge>
            </IconButton>
          )}
          {ration && ration !== "x / x / x" && (
            <Chip label={`Ration : ${ration}`} color="secondary" />
          )}
          {hayAddon && <Chip label="Supplément foin" color="info" />}
        </Stack>

        <Box sx={{ pr: 2, width: "100%" }}>
          <MobileDatePicker
            label="Date"
            sx={{ my: 1, width: "100%" }}
            slotProps={{
              toolbar: {
                sx: {
                  display: "none",
                },
              },
            }}
            onChange={onDateChange}
            value={date}
            onMonthChange={onMonthChange}
            selectedSections={"month"}
            views={["year", "month"]}
            openTo="month"
          />
        </Box>
      </Stack>
    </>
  );
};

export default HorseCalendarViewMobile;
