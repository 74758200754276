import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";

import { useListRiders } from "../../Features/Mutations/riders";

const RiderSelect = ({ onChange, onInit, label, multiple, defaultValue }) => {
  const { riders, isLoading } = useListRiders();
  const [value, setValue] = React.useState(multiple ? [] : defaultValue);

  const handleChange = (event, value) => {
    console.log("RiderSelect value", value);
    if (onChange) {
      setValue(value);
      onChange(value);
    }
  };

  useEffect(() => {
    if (riders && !isLoading) {
      if (multiple) {
        setValue(riders.map((rider) => rider.name));
      } else {
        if (riders.length === 1) {
          setValue(riders[0].name);
        }
      }

      if (onInit) {
        onInit(riders);
      }
    }
  }, [riders, isLoading, multiple, onInit]);

  return (
    <Autocomplete
      id="rider-select"
      freeSolo
      multiple={multiple}
      options={riders ? riders.map((option) => option.name) : []}
      onChange={handleChange}
      onInputChange={!multiple ? handleChange : null}
      renderInput={(params) => (
        <TextField {...params} label={label} placeholder="Prénom et nom" />
      )}
      loading={isLoading}
      value={value}
    />
  );
};

export default RiderSelect;
