import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  useCreateRider,
  useUpdateRider,
} from "../../../Features/Mutations/riders";

import DeleteIcon from "@mui/icons-material/Delete";

const RiderEditDialog = ({ currentRider, open, onClose, isCurrentUser }) => {
  const [rider, setRider] = useState({
    lastName: "",
    firstName: "",
    email: "",
  });

  const [isPosting, setIsPosting] = useState(false);
  const [error, setError] = useState(null);

  const riderInit = useMemo(() => {
    console.log("currentRider", currentRider);
    if (currentRider) {
      const tabs = currentRider.name.split(" ");

      // If the name is composed of more than 2 words, we consider last name is the last word
      let lastName = tabs[tabs.length - 1];
      let firstName = tabs.slice(0, tabs.length - 1).join(" ");

      lastName = lastName.trim();
      firstName = firstName.trim();

      return {
        lastName: lastName,
        firstName: firstName,
        login: currentRider.email,
        guid: currentRider.guid,
        email: "",
      };
    }

    return {
      lastName: "",
      firstName: "",
      email: "",
      guid: null,
    };
  }, [currentRider]);

  useEffect(() => {
    setRider({ ...riderInit });
  }, [riderInit]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRider({
      ...rider,
      [name]: value,
    });
  };

  const displayPassword = useMemo(() => {
    // if the rider as a valid email (using regex), we display the password field
    return rider.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/);
  }, [rider.email]);

  const createRider = useCreateRider();
  const updateRider = useUpdateRider();

  const handleDeleteEmail = useCallback(() => {
    setRider({
      ...rider,
      email: "",
      login: "",
    });
  }, [rider]);

  const handleSave = useCallback(async () => {
    setIsPosting(true);
    setError(null);
    try {
      if (rider.lastName === "") {
        setError("Le nom est obligatoire");
        return;
      }
      if (rider.firstName === "") {
        setError("Le prénom est obligatoire");
        return;
      }

      // if email is set and is not valid, we throw an error
      if (
        rider.email &&
        !rider.email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)
      ) {
        setError("L'email n'est pas valide");
        return;
      }

      // if the password is displayed, we check if the password and the confirmation are the same
      if (displayPassword) {
        if (rider.password !== rider.passwordConfirm) {
          setError("Les mots de passe ne correspondent pas");
          return;
        }
      }

      if (!rider.guid) {
        await createRider.mutateAsync({
          newrider: rider,
        });
      } else {
        await updateRider.mutateAsync({
          rider: {
            ...rider,
            email: rider.login || rider.email,
          },
        });
      }

      onClose();
    } catch (e) {
      setError("Erreur lors de l'enregistrement du cavalier");
    } finally {
      setIsPosting(false);
    }
  }, [rider, createRider, updateRider, displayPassword, onClose]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {rider.lastName !== "" ? "Modifier le cavalier" : "Ajouter un cavalier"}
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          name="firstName"
          label="Prénom"
          type="text"
          fullWidth
          value={rider.firstName}
          onChange={handleChange}
          disabled={isPosting}
          required
          error={rider.firstName === ""}
        />
        <TextField
          autoFocus
          margin="dense"
          name="lastName"
          label="Nom"
          type="text"
          fullWidth
          value={rider.lastName}
          onChange={handleChange}
          disabled={isPosting}
          required
          error={rider.lastName === ""}
        />

        {rider.login === "" || rider.login === undefined ? (
          <TextField
            margin="dense"
            name="email"
            label="Email"
            type="email"
            fullWidth
            value={rider.email}
            onChange={handleChange}
            helperText="Si l'email est renseigné, le cavalier pourra se connecter à l'application. Un code d'activation lui sera envoyé."
            disabled={isPosting}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mt: 1,
            }}
          >
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              {rider.login}
            </Typography>
            {!isCurrentUser && (
              <IconButton onClick={handleDeleteEmail}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        )}
        {displayPassword && (
          <>
            <TextField
              margin="dense"
              name="password"
              label="Mot de passe"
              type="password"
              fullWidth
              value={rider.password}
              onChange={handleChange}
              disabled={isPosting}
              helperText="Le mot de passe pourra être modifié par le cavalier une fois connecté"
            />
            <TextField
              margin="dense"
              name="passwordConfirm"
              label="Confirmer le mot de passe"
              type="password"
              fullWidth
              value={rider.passwordConfirm}
              onChange={handleChange}
              disabled={isPosting}
            />
          </>
        )}
        {error && (
          <Typography variant="caption" color="error">
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        {isPosting && <CircularProgress size={24} />}
        <Button onClick={onClose} color="primary" disabled={isPosting}>
          Annuler
        </Button>
        <Button onClick={handleSave} color="primary" disabled={isPosting}>
          {rider.guid ? "Modifier" : "Ajouter"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RiderEditDialog;
