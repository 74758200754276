import { Grid2, Typography } from "@mui/material";

import Footer from "../Footer/Footer";
import React from "react";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";
import { useGetConfig } from "../../Features/config";

export default function Page404() {
  const config = useGetConfig();
  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Grid2
        container
        spacing={0}
        sx={{
          maxWidth: "100%",
          marginTop: "86px",
          minHeight: "calc(100vh - 86px - 140px)",
          backgroundImage: `url(${config?.notFoundBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: (t) =>
            t.palette.mode === "light"
              ? t.palette.grey[50]
              : t.palette.grey[900],
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid2
          textAlign={"center"}
          sx={{ px: 4, mb: 4, display: "flex", justifyContent: "center" }}
          alignItems="flex-end"
          size={12}
        >
          <Typography
            variant="h5"
            align="center"
            sx={{
              p: 2,
              color: "#eeeeee",
            }}
          >
            Oups ! Cette page n'existe pas.
          </Typography>
        </Grid2>
      </Grid2>
      <Footer id="FOOTER" />
    </>
  );
}
