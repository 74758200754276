import { useGetUser, useIsModerator } from "./login";

import { useQuery } from "@tanstack/react-query";
import { useGet } from "./apiFetch";

export const useBillingStatus = () => {
  const user = useGetUser();
  const isModerator = useIsModerator(user);
  const get = useGet();
  console.log("user on billingStatus", user);
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["billingStatus", user?.data?.email],
    queryFn: async () => {
      return get(`/billing/account/status`);
    },
    enabled: isModerator,
  });

  return {
    billingStatus: data?.data,
    isLoading,
    isError,
    error,
  };
};
