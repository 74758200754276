import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import React, { useCallback } from "react";

export default function ConfirmDeleteAccount({ open, onDeleteClub, onCancel }) {
  const [isPosting, setIsPosting] = React.useState(false);
  const handleDeleteClub = useCallback(async () => {
    setIsPosting(true);
    await onDeleteClub();
    setIsPosting(false);
  }, [onDeleteClub]);
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirm-delete-title"
      aria-describedby="confirm-delete-description"
    >
      <DialogTitle id="confirm-delete-title">Suppression du compte</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirm-delete-description">
          Attention ! Cette action est irréversible.
          <br />
          <br />
          Si vous vous êtes inscrit à un ou plusieurs concours, le nom du
          cavalier ne sera pas supprimé. <br />
          <br />
          Les références sur votre e-mail seront supprimées.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Stack direction="column" spacing={2}>
          <Button
            onClick={handleDeleteClub}
            color="warning"
            variant="outlined"
            disabled={isPosting}
          >
            Supprimer mon compte
          </Button>
          <Button
            onClick={onCancel}
            autoFocus
            color="primary"
            variant="contained"
            disabled={isPosting}
          >
            Annuler
          </Button>
          {isPosting && <CircularProgress />}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
