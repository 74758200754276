import {
  Box,
  Button,
  CircularProgress,
  Grid2,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePatch, usePost } from "../../../Features/apiFetch";

import AddCircleIcon from "@mui/icons-material/AddCircle";
import BillingCard from "./BillingCard";
import { errorAtom } from "../../../Features/atom";
import { useGetConfig } from "../../../Features/config";
import { useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";

const PaymentConfig = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [bufferBilling, setBufferBilling] = useState([]);

  const patch = usePatch();
  const post = usePost();
  const queryClient = useQueryClient();
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  const config = useGetConfig();

  useEffect(() => {
    if (config?.defaultBilling) {
      //Create a copy of defaultBilling
      const strCopy = JSON.stringify(config?.defaultBilling);
      const copy = JSON.parse(strCopy);
      setBufferBilling(copy);
    }
  }, [config?.defaultBilling]);

  const handleBillingChange = useCallback(
    (index, field, value) => {
      const newBufferBilling = [...bufferBilling];
      if (field === "Prices.NormalPriceHT" || field === "Prices.TDAPriceHT") {
        const prefix = field.split(".")[0];
        const suffix = field.split(".")[1];
        newBufferBilling[index][prefix][suffix] = parseFloat(value);
      } else {
        newBufferBilling[index][field] = value;
      }
      setBufferBilling(newBufferBilling);
    },
    [bufferBilling]
  );

  const handleBillingDelete = useCallback(
    (index) => {
      const newBufferBilling = [...bufferBilling];
      newBufferBilling.splice(index, 1);
      setBufferBilling(newBufferBilling);
    },
    [bufferBilling]
  );

  const handleBillingAdd = useCallback(() => {
    const newBufferBilling = [...bufferBilling];
    newBufferBilling.push({
      Description: "",
      Prices: {
        NormalPriceHT: "",
        TDAPriceHT: "",
      },
      VAT: 20.0,
    });
    setBufferBilling(newBufferBilling);
  }, [bufferBilling]);

  const isBillingChanged = useMemo(() => {
    if (config?.defaultBilling === undefined) {
      return false;
    }

    if (config?.defaultBilling?.length !== bufferBilling.length) {
      return true;
    }
    for (let i = 0; i < config?.defaultBilling.length; i++) {
      if (
        config?.defaultBilling[i].Description !== bufferBilling[i].Description
      ) {
        return true;
      }
      if (
        config?.defaultBilling[i].Prices.NormalPriceHT !==
        bufferBilling[i].Prices.NormalPriceHT
      ) {
        return true;
      }
      if (
        config?.defaultBilling[i].Prices.TDAPriceHT !==
        bufferBilling[i].Prices.TDAPriceHT
      ) {
        return true;
      }
      if (config?.defaultBilling[i].VAT !== bufferBilling[i].VAT) {
        return true;
      }
      if (
        config?.defaultBilling[i].ApplyOnEachTurn !==
        bufferBilling[i].ApplyOnEachTurn
      ) {
        return true;
      }
      if (
        config?.defaultBilling[i].UserCancelable !==
        bufferBilling[i].UserCancelable
      ) {
        return true;
      }
      if (
        config?.defaultBilling[i].AllowOverwrite !==
        bufferBilling[i].AllowOverwrite
      ) {
        return true;
      }
    }
    return false;
  }, [config?.defaultBilling, bufferBilling]);

  const isChanged = useMemo(() => {
    if (!config?.club) {
      return false;
    }

    if (isBillingChanged) {
      return true;
    }

    return false;
  }, [config?.club, isBillingChanged]);

  const handleReset = useCallback(() => {
    // reset bufferBilling
    const strCopy = JSON.stringify(config?.defaultBilling);
    const copy = JSON.parse(strCopy);
    setBufferBilling(copy);
  }, [config?.defaultBilling]);

  const handleSave = useCallback(async () => {
    setIsLoading(true);
    try {
      if (isBillingChanged) {
        const res = await patch(`/configuration/club-billing`, bufferBilling);
        if (res.statusCode === 200) {
          const strCopy = JSON.stringify(bufferBilling);
          const copy = JSON.parse(strCopy);
          window.config.defaultBilling = copy;
        } else {
          setError({
            message: "Erreur lors de l'enregistrement de la configuration.",
            severity: "error",
          });
          return;
        }
      }

      // Update config
      const generate = await post(`/configuration/generate`);
      if (generate.statusCode === 200) {
        setError({
          message:
            "Configuration enregistrée. Les modifications seront visibles d'ici quelques minutes si vous rechargez la page.",
          severity: "success",
        });
        queryClient.invalidateQueries(["config"], { refetchInactive: true });
      } else {
        setError({
          message: "Erreur lors de l'enregistrement de la configuration.",
          severity: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, [isBillingChanged, patch, setError, queryClient, bufferBilling, post]);

  return (
    <>
      <Grid2 size={12} sx={{ p: 1, mt: 4 }} textAlign={"center"}>
        <Box sx={{ position: "relative" }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!isChanged || isLoading}
            onClick={handleReset}
            sx={{ mr: 1 }}
          >
            Réinitialiser
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            color="primary"
            disabled={!isChanged || isLoading}
            onClick={handleSave}
          >
            Enregistrer
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "calc(100% - 12px)",
                left: "calc(50% - 12px)",
              }}
            />
          )}
        </Box>
      </Grid2>
      <Grid2
        size={12}
        sx={{ p: 1, mt: 2, display: "flex", justifyContent: "center" }}
      >
        <Stack>
          <Typography variant="h5">
            Facturation en ligne des concours
          </Typography>
          <Typography color="text.secondary" sx={{ textAlign: "left", mb: 2 }}>
            Configurez comment seront facturés par défaut vos concours.
            Choisissez ce qui peut être modifié lors de la création d'un
            concours.
          </Typography>
          <Grid2 container spacing={2} sx={{ mb: 1 }}>
            {bufferBilling.map((billing, index) => (
              <BillingCard
                key={`${index}-billing`}
                billing={billing}
                index={index}
                onChange={handleBillingChange}
                onDelete={handleBillingDelete}
              />
            ))}
            <Typography sx={{ mt: 1, ml: 2 }} color="text.secondary">
              (*) Exemple : peut s'appliquer aux propriétaires disposant de leur
              propre moyen de transport. Le club pourra vérifier la validité
              lors de la confirmation de l'inscription.
            </Typography>
          </Grid2>
          <IconButton
            sx={{ mt: 0, display: "inline" }}
            onClick={handleBillingAdd}
          >
            <AddCircleIcon />
          </IconButton>
        </Stack>
      </Grid2>
      <Grid2 size={12} sx={{ p: 1, mt: 4 }} textAlign={"center"}>
        <Box sx={{ position: "relative" }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!isChanged || isLoading}
            onClick={handleReset}
            sx={{ mr: 1 }}
          >
            Réinitialiser
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            color="primary"
            disabled={!isChanged || isLoading}
            onClick={handleSave}
          >
            Enregistrer
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "calc(100% - 12px)",
                left: "calc(50% - 12px)",
              }}
            />
          )}
        </Box>
      </Grid2>
    </>
  );
};

export default PaymentConfig;
