import React, { useMemo } from "react";

import { Stack } from "@mui/material";
import Filter from "../../Filter";
import Sort from "../../Sort";

export default function HorseFilter({ onChange, onSort }) {
  const filterItems = useMemo(() => {
    const filters = [];
    filters.push({ type: "header", label: "Appartenance" });
    filters.push({
      type: "item",
      label: "Mes chevaux/pensions",
      value: "my_horses",
    });
    filters.push({
      type: "item",
      label: "Chevaux du club",
      value: "club_horses",
    });
    filters.push({ type: "header", label: "Calendrier" });
    filters.push({
      type: "item",
      label: "A faire aujourd'hui",
      value: "today_action",
    });
    return filters;
  }, []);

  const filterExclusions = useMemo(() => {
    return ["my_horses, club_horses"];
  }, []);

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        justifyContent: {
          xl: "end",
          lg: "end",
          md: "end",
          sm: "center",
          xs: "center",
        },
        mt: {
          sm: 1,
          md: 0,
          xs: 1,
        },
      }}
    >
      <Filter
        items={filterItems}
        allowFreeValue={true}
        localStorageKey="horses-filter"
        onChange={onChange}
        defaultItems={[]}
        exclusions={filterExclusions}
      />
      <Sort
        defaultSort="horse_name_desc"
        values={["horse_name_desc", "horse_name_asc"]}
        localStorageKey="horses-sort"
        onChange={onSort}
      />
    </Stack>
  );
}
