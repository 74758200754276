import { CssBaseline, Grid2 } from "@mui/material";
import React, { Fragment, useMemo } from "react";

import BillingNotSet from "./BillingNotSet";
import BillingPending from "./BillingPending";
import BillingSet from "./BillingSet";
import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";
import { CLUB_BILLING_STATUS } from "../../../Features/enums";
import { useBillingStatus } from "../../../Features/billing";
import { useGetConfig } from "../../../Features/config";

export default function Billing() {
  const billingStatus = useBillingStatus();
  const config = useGetConfig();

  const breadcrumbItems = useMemo(() => {
    return [
      { label: "Accueil", link: "/" },
      { label: "Administration", link: "/administration" },
      { label: "Facturation" },
    ];
  }, []);

  const isFullWidth = useMemo(() => {
    if (!billingStatus || !billingStatus.billingStatus) return true;

    return (
      billingStatus?.billingStatus?.accountStatus === CLUB_BILLING_STATUS.ACTIVE
    );
  }, [billingStatus]);

  return (
    <Grid2
      container
      spacing={0}
      sx={{
        maxWidth: "100%",
        marginTop: "86px",
      }}
    >
      <CssBaseline />
      <Grid2
        size={12}
        sx={{
          display: "flex",
          alignItems: "baseline",
          xs: { display: "none" },
        }}
      >
        <BreadcrumbComponent items={breadcrumbItems} />
      </Grid2>
      {!isFullWidth && (
        <Fragment>
          <Grid2
            size={{ xs: false, sm: false, md: 4, lg: 6, xl: 7 }}
            sx={{
              pl: 4,
              backgroundImage: `url(${config?.notAllowedBackground})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
              minHeight: "calc(100vh - 86px - 192px)",
              display: {
                xs: "none",
                sm: "none",
                md: "inherit",
              },
            }}
          />
          <Grid2
            textAlign={"center"}
            sx={{ px: 4, mb: 0, display: "flex", justifyContent: "center" }}
            alignItems="center"
            size={{ xs: 12, sm: 12, md: 6, lg: 5, xl: 4 }}
          >
            {billingStatus?.billingStatus?.accountStatus ===
              CLUB_BILLING_STATUS.NOT_CREATED && <BillingNotSet />}
            {billingStatus?.billingStatus?.accountStatus ===
              CLUB_BILLING_STATUS.PENDING && <BillingPending />}
            {billingStatus?.billingStatus?.accountStatus ===
              CLUB_BILLING_STATUS.ACTIVE && <BillingSet />}
          </Grid2>
        </Fragment>
      )}
      {isFullWidth && (
        <Grid2 size={12} sx={{ px: { md: 4, sm: 2, xs: 1 }, mb: 0 }}>
          <BillingSet />
        </Grid2>
      )}
    </Grid2>
  );
}
