import { useDelete, useGet, usePatch, usePost } from "../apiFetch";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { errorAtom } from "../atom";
import { useCallback } from "react";
import { useRecoilState } from "recoil";

const useDisplayError = () => {
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  return useCallback(
    (error) => {
      setError({ message: error, severity: "error" });
    },
    [setError]
  );
};

export const useCreateCalendarEvent = () => {
  const post = usePost();
  const displayError = useDisplayError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ calendarEvent }) => {
      console.log("useCreateCalendarEvent", calendarEvent);
      const res = await post(`/calendar/event`, calendarEvent);
      if (res.statusCode === 200) {
        return calendarEvent;
      } else {
        throw res.error;
      }
    },
    onError: (error) => {
      displayError(
        "Erreur lors de la création de l'événement dans le calendrier"
      );
    },
    onSuccess: (calendarEvent) => {
      queryClient.invalidateQueries([
        `horse_calendar_events_${calendarEvent.horseGuid}`,
        `horse_calendar_alerts_${calendarEvent.horseGuid}`,
      ]);
    },
  });
};

export const useUpdateRecurringCalendarEvent = (horseGuid, eventGuid) => {
  const patch = usePatch();
  const displayError = useDisplayError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ calendarEvent }) => {
      console.log("useCreateCalendarEvent", calendarEvent);
      const res = await patch(
        `/calendar/recurring-event/${horseGuid}/${eventGuid}`,
        calendarEvent
      );
      if (res.statusCode === 200) {
        return calendarEvent;
      } else {
        throw res.error;
      }
    },
    onError: (error) => {
      displayError(
        "Erreur lors de la mise à jour de l'événement dans le calendrier"
      );
    },
    onSuccess: (calendarEvent) => {
      queryClient.invalidateQueries([
        `horse_calendar_events_${calendarEvent.horseGuid}`,
      ]);
    },
  });
};

export const useListCalendarEvents = (horseGuid, startDate) => {
  const get = useGet();

  // date is format yyyy-mm-dd, use yyy-mm as key
  const dateKey = startDate.substring(0, 7);

  const { data, error, isLoading } = useQuery({
    queryKey: [`horse_calendar_events_${horseGuid}`, dateKey],
    queryFn: () => {
      return get(`/calendar/events/${horseGuid}?startDate=${startDate}`);
    },
    enabled: !!horseGuid,
  });

  if (error) {
    console.log("useListCalendarEvents error", data.error);
    return { events: null, isLoading };
  }
  if (data) {
    if (data.data.error) {
      console.log("useListCalendarEvents error", data.data.error);
      return { events: null, isLoading };
    }

    return { events: data?.data || null, isLoading };
  }

  return { events: null, isLoading };
};

export const useListCalendarAlerts = (horseGuid) => {
  const get = useGet();

  const { data, error, isLoading } = useQuery({
    queryKey: [`horse_calendar_alerts_${horseGuid}`],
    queryFn: () => {
      return get(`/calendar/alerts/${horseGuid}`);
    },
    enabled: !!horseGuid,
  });

  if (error) {
    console.log("useListCalendarAlerts error", data.error);
    return { alerts: null, isLoading };
  }
  if (data) {
    if (data.data.error) {
      console.log("useListCalendarAlerts error", data.data.error);
      return { alerts: null, isLoading };
    }

    return { alerts: data?.data || null, isLoading };
  }

  return { alerts: null, isLoading };
};

export const useGetCalendarEvent = (horseGuid, eventGuid) => {
  const get = useGet();

  const { data, error, isLoading } = useQuery({
    queryKey: [`horse_calendar_event_${horseGuid}`, eventGuid],
    queryFn: () => {
      return get(`/calendar/event/${horseGuid}/${eventGuid}`);
    },
    enabled: !!horseGuid && !!eventGuid,
  });

  if (error) {
    console.log("useGetCalendarEvent error", data.error);
    return { event: null, isLoading };
  }
  if (data) {
    if (data.data.error) {
      console.log("useGetCalendarEvent error", data.data.error);
      return { event: null, isLoading };
    }

    return { event: data?.data || null, isLoading };
  }

  return { event: null, isLoading };
};

export const useUpdateUnitCalendarEvent = (horseGuid, eventGuid) => {
  const patch = usePatch();
  const displayError = useDisplayError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ calendarEvent }) => {
      const res = await patch(
        `/calendar/event/${horseGuid}/${eventGuid}`,
        calendarEvent
      );
      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onError: (error) => {
      displayError(
        "Erreur lors de la mise à jour de l'événement dans le calendrier"
      );
    },
    onSuccess: (calendarEvent) => {
      // update data of queryKey: [`horse_calendar_event_${horseGuid}`, eventGuid]
      queryClient.setQueryData(
        [`horse_calendar_event_${horseGuid}`, eventGuid],
        (oldData) => ({
          ...oldData,
          data: calendarEvent,
          error: null,
          status: "success",
        })
      );

      //invalidate data of queryKey: [`horse_calendar_events_${horseGuid}`]
      queryClient.invalidateQueries([`horse_calendar_events_${horseGuid}`]);
    },
  });
};

export const useDeleteCalendarEvent = (horseGuid, eventGuid) => {
  const deleteQuery = useDelete();
  const displayError = useDisplayError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      console.log("useDeleteUnitCalendarEvent", horseGuid, eventGuid);
      const res = await deleteQuery(
        `/calendar/event/${horseGuid}/${eventGuid}`
      );
      if (res.statusCode === 200) {
        return eventGuid;
      } else {
        throw res.error;
      }
    },
    onError: (error) => {
      displayError(
        "Erreur lors de la suppression de l'événement dans le calendrier"
      );
    },
    onSuccess: (eventGuid) => {
      // remove data of queryKey: [`horse_calendar_event_${horseGuid}`, eventGuid]
      queryClient.invalidateQueries([
        `horse_calendar_event_${horseGuid}`,
        eventGuid,
      ]);

      //invalidate data of queryKey: [`horse_calendar_events_${horseGuid}`]
      queryClient.invalidateQueries([`horse_calendar_events_${horseGuid}`]);
    },
  });
};

export const useDeleteRecurringCalendarEventOccurence = (
  horseGuid,
  eventGuid,
  dateKey
) => {
  const deleteQuery = useDelete();
  const displayError = useDisplayError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      console.log(
        "useDeleteRecurringCalendarEventOccurence",
        horseGuid,
        eventGuid
      );
      const res = await deleteQuery(
        `/calendar/event/${horseGuid}/${eventGuid}/${dateKey}`
      );
      if (res.statusCode === 200) {
        return eventGuid;
      } else {
        throw res.error;
      }
    },
    onError: (error) => {
      displayError(
        "Erreur lors de la suppression de l'occurence d'événement dans le calendrier"
      );
    },
    onSuccess: (eventGuid) => {
      queryClient.invalidateQueries([
        `horse_calendar_event_${horseGuid}`,
        eventGuid,
      ]);

      //invalidate data of queryKey: [`horse_calendar_events_${horseGuid}`]
      queryClient.invalidateQueries([`horse_calendar_events_${horseGuid}`]);
    },
  });
};

export const useDeleteCalendarAlert = (horseGuid, eventGuid) => {
  const deleteQuery = useDelete();
  const displayError = useDisplayError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      console.log("useDeleteCalendarAlert", horseGuid, eventGuid);
      const res = await deleteQuery(
        `/calendar/event/${horseGuid}/${eventGuid}`
      );
      if (res.statusCode === 200) {
        return eventGuid;
      } else {
        throw res.error;
      }
    },
    onError: (error) => {
      displayError("Erreur lors de la suppression de l'alerte du calendrier");
    },
    onSuccess: (eventGuid) => {
      // instead of invalidate all events, we can just remove the event from the list
      queryClient.setQueryData(
        [`horse_calendar_alerts_${horseGuid}`],
        (oldData) => {
          return {
            ...oldData,
            data: oldData.data.filter((event) => event.eventGuid !== eventGuid),
          };
        }
      );
    },
  });
};
