import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUser, useIsAllowedForPayment } from "../../../Features/login";

import { useQueryClient } from "@tanstack/react-query";
import { useGet } from "../../../Features/apiFetch";
import useLegacyEffect from "../../../Features/legacyEffect";

export const UserPaymentReturn = () => {
  const navigate = useNavigate();
  const { sessionId } = useParams();

  const user = useGetUser();
  const get = useGet();
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(0);

  const isAllowedForPayment = useIsAllowedForPayment(user);

  useEffect(() => {
    if (user.isLoading) return;

    if (!isAllowedForPayment) {
      navigate("/403");
    }
  }, [user, navigate, isAllowedForPayment]);

  useLegacyEffect(() => {
    const checkPayment = async () => {
      setIsLoading(true);
      const result = await get(
        `/billing/checkout/validate-setup-intent/${sessionId}`
      );
      if (result.statusCode !== 200) {
        setError(1);
      } else if (result.data.status === "open") {
        setError(2);
      } else if (result.data.status === "failed") {
        setError(3);
      } else {
        setError(0);
        queryClient.invalidateQueries({ queryKey: ["payment-method"] });
        setTimeout(() => {
          navigate("/payment-infos");
        }, 5000);
      }
      setIsLoading(false);
    };
    checkPayment();
  }, [sessionId, get, navigate, queryClient]);

  return (
    <Container
      sx={{
        height: "100%",
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        mx: 4,
        minHeight: "calc(100vh - 86px - 450px)",
      }}
    >
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            my: 2,
            flexDirection: "column",
          }}
        >
          <CircularProgress sx={{ my: 4 }} />
          <Typography variant="body" sx={{ textAlign: "center" }}>
            Veuillez patienter...
            <br />
            Nous vérifions vos informations de paiement.
          </Typography>
        </Box>
      ) : !isLoading && error === 1 ? (
        <>
          <Alert severity="error">
            <AlertTitle>
              Aucune autorisation de paiement n'a été trouvée pour cette
              session.
            </AlertTitle>
            Si vous venez de confirmer votre moyen de paiement, veuillez
            contacter le support à l'adresse suivante :{" "}
            <a
              href={`mailto:${process.env.REACT_APP_EMAIL}?subject=SetupIntentId-${sessionId}`}
            >
              {process.env.REACT_APP_EMAIL}
            </a>
          </Alert>
          <Button
            color="primary"
            onClick={() => navigate("/payment-infos")}
            sx={{ mt: 2 }}
          >
            Préférences de paiement
          </Button>
        </>
      ) : !isLoading && error === 2 ? (
        <>
          <Alert severity="warning">
            <AlertTitle>En attente de validation...</AlertTitle>
            Votre moyen de paiement est en cours de validation.
          </Alert>
          <Button
            color="primary"
            onClick={() => navigate("/payment-infos")}
            sx={{ mt: 2 }}
          >
            Préférences de paiement
          </Button>
        </>
      ) : !isLoading && error === 3 ? (
        <>
          <Alert severity="error">
            <AlertTitle>Moyen de paiement refusé</AlertTitle>
            Votre moyen de paiement a été refusé. Vous ne pourrez pas l'utiliser
            pour vous inscrire à des concours.
          </Alert>
          <Button
            color="primary"
            onClick={() => navigate("/payment-infos")}
            sx={{ mt: 2 }}
          >
            Préférences de paiement
          </Button>
        </>
      ) : (
        <Alert severity="success">
          <AlertTitle>Moyen de paiement validé !</AlertTitle>
          Nous allons vous rediriger vers la liste de vos moyen dans quelques
          instants.
          <br />
          Vous allez recevoir un email de confirmation.
          <br />
          <Typography variant="body2" sx={{ mt: 2, fontStyle: "italic" }}>
            Vous pourrez désormais vous inscrire à des concours qui nécessitent
            un paiement en ligne.
          </Typography>
        </Alert>
      )}
    </Container>
  );
};
