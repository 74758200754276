import { Button, CssBaseline, Grid2, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ignoreObjectProperty,
  resetConfiguration,
  useGetCurrentConfiguration,
} from "../../../Features/categories";

import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";
import CardParameters from "./CardParameters";
import { errorAtom } from "../../../Features/atom";
import { usePatch } from "../../../Features/apiFetch";
import { useRecoilState } from "recoil";

export default function ContestParameters() {
  const configurationTemplate = useGetCurrentConfiguration();

  const [config, setConfig] = useState(
    configurationTemplate(window.config.categories)
  );
  const [initialConfig, setInitialConfig] = useState("");
  const patch = usePatch();
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  useEffect(() => {
    const init = JSON.parse(
      JSON.stringify(configurationTemplate(window.config.categories))
    );
    ignoreObjectProperty(init, "isVisible");
    setInitialConfig(JSON.stringify(init));
  }, [configurationTemplate, setInitialConfig]);

  const handleConfigChange = useCallback(
    (key, newConfig) => {
      const c = { ...config };
      c[key] = newConfig;

      if (newConfig.isChecked) {
        c.isChecked = true;
      }
      setConfig(c);
    },
    [setConfig, config]
  );

  const handleReset = useCallback(() => {
    const resetState = JSON.parse(initialConfig);
    resetConfiguration(config, resetState);
    setConfig({ ...config });
  }, [setConfig, config, initialConfig]);

  const handleSave = useCallback(async () => {
    const c = {};
    Object.keys(config).forEach((key) => {
      //horse or pony
      if (key === "isChecked") return;
      if (key === "isVisible") return;
      c[key] = {};
      if (config[key].isChecked) {
        Object.keys(config[key]).forEach((discipline) => {
          if (config[key][discipline].isChecked) {
            c[key][discipline] = {};
            Object.keys(config[key][discipline]).forEach((type) => {
              //club or amateur
              if (type === "isChecked") return;
              if (type === "isVisible") return;
              c[key][discipline][type] = [];
              if (config[key][discipline][type].isChecked) {
                config[key][discipline][type].categories.forEach((category) => {
                  if (category.isChecked) {
                    c[key][discipline][type].push(category.label);
                  }
                });
              }
            });
          }
        });
      }
    });
    const res = await patch(`/configuration/categories`, c);
    if (res.statusCode === 200) {
      setError({
        message:
          "Configuration enregistrée. Les modifications seront visibles d'ici quelques minutes si vous rechargez la page.",
        severity: "success",
      });
      const newConfigInit = configurationTemplate(c);
      ignoreObjectProperty(newConfigInit, "isVisible");
      setInitialConfig(JSON.stringify(newConfigInit));
      window.config.categories = c;
    } else {
      setError({
        message: "Erreur lors de l'enregistrement de la configuration.",
        severity: "error",
      });
    }
  }, [config, patch, configurationTemplate, setInitialConfig, setError]);

  const isChanged = useMemo(() => {
    const v1 = JSON.parse(JSON.stringify(config));
    delete v1.isChecked;
    ignoreObjectProperty(v1, "isVisible");

    return JSON.stringify(v1) !== initialConfig;
  }, [config, initialConfig]);

  const breadcrumbItems = useMemo(() => {
    return [
      { label: "Accueil", link: "/" },
      { label: "Administration", link: "/administration" },
      { label: "Paramètres des concours" },
    ];
  }, []);

  return (
    <Grid2
      container
      spacing={0}
      sx={{
        maxWidth: "100%",
        marginTop: "86px",
      }}
    >
      <CssBaseline />
      <Grid2
        size={12}
        sx={{
          display: "flex",
          alignItems: "baseline",
          xs: { display: "none" },
        }}
      >
        <BreadcrumbComponent items={breadcrumbItems} />
      </Grid2>
      <Grid2 size={12} sx={{ p: 1, mt: 4 }} textAlign={"center"}>
        <Typography variant="body" align="center" color="text.primary">
          Choisissez les disciplines et les catégories proposées sur la page de
          création de concours.
        </Typography>
      </Grid2>
      <Grid2 size={12} sx={{ p: 1, mb: 2 }} textAlign={"center"}>
        <Button
          variant="contained"
          color="secondary"
          disabled={!isChanged}
          onClick={handleReset}
        >
          Réinitialiser
        </Button>
        <Button
          variant="contained"
          sx={{ ml: 2 }}
          color="primary"
          disabled={!isChanged}
          onClick={handleSave}
        >
          Enregistrer
        </Button>
      </Grid2>
      <Grid2 textAlign={"center"} size={{ xs: 12, sm: 6 }} sx={{ p: 1 }}>
        <CardParameters
          params={config.pony}
          onChange={handleConfigChange}
          label="pony"
          elevation={1}
        />
      </Grid2>
      <Grid2 textAlign={"center"} size={{ xs: 12, sm: 6 }} sx={{ p: 1 }}>
        <CardParameters
          params={config.horse}
          onChange={handleConfigChange}
          label="horse"
          elevation={1}
        />
      </Grid2>
    </Grid2>
  );
}
