import {
  FormControl,
  Grid2,
  List,
  ListItemIcon,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Button from "@mui/material/Button";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import { t } from "@lingui/macro";

export default function Sort({
  defaultSort,
  values,
  localStorageKey,
  onChange,
}) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [sort, setSort] = useState(() => {
    const sortValue = localStorage.getItem(localStorageKey);
    if (sortValue) {
      return sortValue;
    }

    return defaultSort || "";
  });

  const mediaQuery = useMediaQuery("(min-width: 900px)");

  const anchor = useMemo(() => {
    return mediaQuery ? "right" : "bottom";
  }, [mediaQuery]);

  const handleOpen = useCallback(() => {
    setIsDrawerOpen(true);
  }, [setIsDrawerOpen]);
  const handleClose = useCallback(() => {
    setIsDrawerOpen(false);
  }, [setIsDrawerOpen]);
  const toggleDrawer = useCallback(
    (open) => (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setIsDrawerOpen(open);
    },
    [setIsDrawerOpen]
  );

  const handleValueClick = useCallback(
    (item) => {
      setSort(item);
      handleClose();
    },
    [setSort, handleClose]
  );

  const isSortActive = useCallback(
    (item) => {
      return sort === item;
    },
    [sort]
  );

  const buttonIcon = useMemo(() => {
    if (sort.includes("_asc")) {
      return <ArrowUpwardIcon />;
    } else {
      return <ArrowDownwardIcon />;
    }
  }, [sort]);

  useEffect(() => {
    localStorage.setItem(localStorageKey, sort);
    if (onChange) {
      onChange(sort);
    }
  }, [sort, localStorageKey, onChange]);

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleOpen} endIcon={buttonIcon}>
        Tri: {t({ id: sort })}
      </Button>
      <Drawer anchor={anchor} open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Grid2 container sx={{ minWidth: "300px" }}>
          <Grid2 size={2} />
          <Grid2 size={8} sx={{ textAlign: "center" }}>
            <Typography variant="h6">Tri</Typography>
          </Grid2>
          <Grid2 size={2} sx={{ textAlign: "right" }}>
            <Button onClick={handleClose}>
              <Close />
            </Button>
          </Grid2>
        </Grid2>
        <FormControl fullWidth>
          <List>
            {values.map((item) => (
              <MenuItem
                key={item}
                value={item}
                onClick={() => handleValueClick(item)}
              >
                {isSortActive(item) && (
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                )}
                {t({ id: `${item}_full` })}
              </MenuItem>
            ))}
          </List>
        </FormControl>
      </Drawer>
    </React.Fragment>
  );
}
