import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  FormControl,
  Grid2,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { usePatch, usePost, usePostFormData } from "../../../Features/apiFetch";

import BadgeIcon from "@mui/icons-material/Badge";
import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";
import BusinessIcon from "@mui/icons-material/Business";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FacebookIcon from "@mui/icons-material/Facebook";
import HttpIcon from "@mui/icons-material/Http";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneIcon from "@mui/icons-material/Phone";
import PictureCard from "./PictureCard";
import ShortTextIcon from "@mui/icons-material/ShortText";
import TwitterIcon from "@mui/icons-material/Twitter";
import { errorAtom } from "../../../Features/atom";
import { useGetConfig } from "../../../Features/config";
import { useQueryClient } from "@tanstack/react-query";
import { useRecoilState } from "recoil";

export default function ClubSettings() {
  const config = useGetConfig();
  const patch = usePatch();
  const postData = usePostFormData();
  const post = usePost();
  const queryClient = useQueryClient();
  const [club, setClub] = useState(config?.club);
  const [bufferPictures, setBufferPictures] = useState({});
  const [connectionMode, setConnectionMode] = useState(0);
  const [schoolZone, setSchoolZone] = useState("A");
  const [startHoliday, setStartHoliday] = useState("monday");
  const [horseManagement, setHorseManagement] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  const handleFieldChange = useCallback(
    (e) => {
      setClub({ ...club, [e.target.name]: e.target.value });
    },
    [club]
  );

  const getPicture = useCallback(
    (key) => {
      return (
        bufferPictures[key]?.link ||
        `${process.env.REACT_APP_CLOUDFRONT}/default/600x400.svg`
      );
    },
    [bufferPictures]
  );

  const pictures = useMemo(() => {
    const pictures = [];
    pictures.push({
      key: "homeBackground",
      name: "Image d'accueil",
      link: getPicture("homeBackground"),
      dimension: "Largeur minimum: 1200px. Poids maximum: 4Mo.",
      description:
        "Affichée sur la page d'accueil, la page de connexion et la page de création de compte",
      square: false,
    });
    pictures.push({
      key: "accountBackground",
      name: "Mon compte",
      link: getPicture("accountBackground"),
      dimension: "Largeur minimum: 1200px. Poids maximum: 4Mo.",
      description: "Affichée sur la page de gestion du compte",
      square: false,
    });
    pictures.push({
      key: "createContestBackground",
      name: "Création de concours",
      link: getPicture("createContestBackground"),
      dimension: "Largeur minimum: 1200px. Poids maximum: 4Mo.",
      description: "Affichée sur la page de création de concours",
      square: false,
    });
    pictures.push({
      key: "registerBackground",
      name: "Inscription concours",
      link: getPicture("registerBackground"),
      dimension: "Largeur minimum: 1200px. Poids maximum: 4Mo.",
      description: "Affichée sur la page d'inscription à un concours",
      square: false,
    });
    pictures.push({
      key: "notFoundBackground",
      name: "Page non trouvée",
      link: getPicture("notFoundBackground"),
      dimension: "Largeur minimum: 1200px. Poids maximum: 4Mo.",
      description: "Affichée lorsqu'une page n'est pas trouvée",
      square: false,
    });
    pictures.push({
      key: "notAllowedBackground",
      name: "Accès interdit",
      link: getPicture("notAllowedBackground"),
      dimension: "Largeur minimum: 1200px. Poids maximum: 4Mo.",
      description:
        "Affichée lorsqu'un accès est interdit et sur les pages d'administration",
      square: false,
    });
    pictures.push({
      key: "horseBackground",
      name: "Suivi et Planning",
      link: getPicture("horseBackground"),
      dimension: "Largeur minimum: 1200px. Poids maximum: 4Mo.",
      description: "Affichée sur la page de gestion des chevaux",
      square: false,
    });
    pictures.push({
      key: "logo",
      name: "Logo",
      link: getPicture("logo"),
      dimension: "Dimension: 512px * 512px. Poids maximum: 1Mo.",
      description: "Logo et favicon du site.",
      square: true,
    });
    return pictures;
  }, [getPicture]);

  useEffect(() => {
    setClub(config?.club);
  }, [config?.club]);

  useEffect(() => {
    if (config?.connectionMode === undefined) {
      setConnectionMode(0);
    } else {
      setConnectionMode(config?.connectionMode);
    }
  }, [config?.connectionMode]);

  useEffect(() => {
    if (config?.horseManagement === undefined) {
      setHorseManagement(0);
    } else {
      setHorseManagement(config?.horseManagement);
    }
  }, [config?.horseManagement]);

  useEffect(() => {
    console.log("SchoolZone", config?.schoolZone);

    if (config?.schoolZone) {
      setSchoolZone(config?.schoolZone);
    }
  }, [config?.schoolZone]);

  useEffect(() => {
    if (config?.startHoliday) {
      setStartHoliday(config?.startHoliday);
    }
  }, [config?.startHoliday]);

  const initBufferPictures = useCallback(
    (key) => {
      setBufferPictures({
        ...bufferPictures,
        [key]: { file: null, link: config?.[key] },
      });
    },
    [bufferPictures, config]
  );

  useEffect(() => {
    if (config?.homeBackground && !bufferPictures.homeBackground?.link) {
      initBufferPictures("homeBackground");
    }
  }, [config?.homeBackground, initBufferPictures, bufferPictures]);

  useEffect(() => {
    if (config?.accountBackground && !bufferPictures.accountBackground?.link) {
      initBufferPictures("accountBackground");
    }
  }, [config?.accountBackground, bufferPictures, initBufferPictures]);

  useEffect(() => {
    if (
      config?.createContestBackground &&
      !bufferPictures.createContestBackground?.link
    ) {
      initBufferPictures("createContestBackground");
    }
  }, [config?.createContestBackground, bufferPictures, initBufferPictures]);

  useEffect(() => {
    if (
      config?.registerBackground &&
      !bufferPictures.registerBackground?.link
    ) {
      initBufferPictures("registerBackground");
    }
  }, [config?.registerBackground, bufferPictures, initBufferPictures]);

  useEffect(() => {
    if (
      config?.notFoundBackground &&
      !bufferPictures.notFoundBackground?.link
    ) {
      initBufferPictures("notFoundBackground");
    }
  }, [config?.notFoundBackground, bufferPictures, initBufferPictures]);

  useEffect(() => {
    if (
      config?.notAllowedBackground &&
      !bufferPictures.notAllowedBackground?.link
    ) {
      initBufferPictures("notAllowedBackground");
    }
  }, [config?.notAllowedBackground, bufferPictures, initBufferPictures]);

  useEffect(() => {
    if (config?.logo && !bufferPictures.logo?.link) {
      initBufferPictures("logo");
    }
  }, [config?.logo, bufferPictures, initBufferPictures]);

  useEffect(() => {
    if (config?.horseBackground && !bufferPictures.horseBackground?.link) {
      initBufferPictures("horseBackground");
    }
  }, [config?.horseBackground, bufferPictures, initBufferPictures]);

  const handleChooseFile = useCallback(
    (pictureKey, file) => {
      setBufferPictures({
        ...bufferPictures,
        [pictureKey]: { file: file, link: URL.createObjectURL(file) },
      });
    },
    [bufferPictures]
  );

  const handleChangeConnectionMode = useCallback(
    (e) => {
      setConnectionMode(e.target.value);
    },
    [setConnectionMode]
  );

  const handleChangeHorseManagement = useCallback(
    (e) => {
      setHorseManagement(e.target.value);
    },
    [setHorseManagement]
  );

  const isPictureChanged = useMemo(() => {
    if (!bufferPictures) {
      return true;
    }

    const picturesKeys = Object.keys(bufferPictures);
    for (const key of picturesKeys) {
      if (bufferPictures[key]?.link !== config[key]) {
        return true;
      }
    }
    return false;
  }, [bufferPictures, config]);

  const isChanged = useMemo(() => {
    if (!config?.club) {
      return false;
    }
    if (!club) {
      return true;
    }

    const keys = Object.keys(club);
    for (const key of keys) {
      if (club[key] !== config?.club[key]) {
        return true;
      }
    }

    if (isPictureChanged) {
      return true;
    }

    if (config?.connectionMode !== connectionMode) {
      return true;
    }

    const defaultSchoolZone = config?.schoolZone || "A";
    if (defaultSchoolZone !== schoolZone) {
      return true;
    }

    const defaultStartHoliday = config?.startHoliday || "monday";
    if (defaultStartHoliday !== startHoliday) {
      return true;
    }

    const defaultHorseManagement = config?.horseManagement || 0;
    if (defaultHorseManagement !== horseManagement) {
      return true;
    }

    return false;
  }, [
    config?.club,
    config?.connectionMode,
    config?.schoolZone,
    config?.startHoliday,
    config?.horseManagement,
    club,
    isPictureChanged,
    connectionMode,
    schoolZone,
    startHoliday,
    horseManagement,
  ]);

  const breadcrumbItems = useMemo(() => {
    return [
      { label: "Accueil", link: "/" },
      { label: "Administration", link: "/administration" },
      { label: "Club et apparence" },
    ];
  }, []);

  const handleReset = useCallback(() => {
    setClub(config?.club);

    // reset all bufferPictures
    const keys = Object.keys(bufferPictures);
    const newBufferPictures = {};
    for (const key of keys) {
      newBufferPictures[key] = { file: null, link: config?.[key] };
    }
    setBufferPictures(newBufferPictures);

    if (config?.connectionMode === undefined) {
      setConnectionMode(0);
    } else {
      setConnectionMode(config?.connectionMode);
    }

    if (config?.schoolZone) {
      setSchoolZone(config?.schoolZone);
    } else {
      setSchoolZone("A");
    }

    if (config?.startHoliday) {
      setStartHoliday(config?.startHoliday);
    } else {
      setStartHoliday("monday");
    }

    if (config?.horseManagement === undefined) {
      setHorseManagement(0);
    } else {
      setHorseManagement(config?.horseManagement);
    }
  }, [config, bufferPictures]);

  const handleSave = useCallback(async () => {
    setIsLoading(true);
    try {
      let isPictureChanged = false;
      let isInfosChanged = false;
      let isSettingsChanged = false;

      //Upload all files
      const pictureKey = Object.keys(bufferPictures);
      let uploadError = false;
      for (const key of pictureKey) {
        if (bufferPictures[key]?.file) {
          const formData = new FormData();
          formData.append("file", bufferPictures[key].file);
          const res = await postData(
            `/configuration/upload?fileKey=${key}`,
            formData
          );
          if (res.statusCode === 200) {
            window.config[key] = res.data.url;
            const newBufferPictures = { ...bufferPictures };
            newBufferPictures[key] = { file: null, link: res.data.url };
            setBufferPictures(newBufferPictures);
            isPictureChanged = true;
          } else {
            uploadError = true;
            setError({
              message: "Erreur lors de l'enregistrement de la configuration.",
              severity: "error",
            });
            return;
          }
        }
      }

      if (uploadError) {
        setError({
          message:
            "Des erreurs sont survenues lors de l'enregistrement des photos. Veuillez réessayer.",
          severity: "error",
        });
        return;
      }

      // Remove all fields equal to config.club
      const keys = Object.keys(club);
      const newClub = {};
      let isChanged = false;
      for (const key of keys) {
        if (club[key] !== config?.club[key]) {
          newClub[key] = club[key];
          isChanged = true;
        }
      }

      // Save new informations
      if (isChanged) {
        const res = await patch(`/configuration/club-informations`, newClub);
        if (res.statusCode === 200) {
          isInfosChanged = true;
          window.config.club = { ...config?.club, ...newClub };
        } else {
          setError({
            message: "Erreur lors de l'enregistrement de la configuration.",
            severity: "error",
          });
          return;
        }
      }

      if (
        connectionMode !== config?.connectionMode ||
        schoolZone !== config?.schoolZone ||
        startHoliday !== config?.startHoliday ||
        horseManagement !== config?.horseManagement
      ) {
        const res = await patch(`/configuration/club-settings`, {
          connectionMode: connectionMode,
          schoolZone: schoolZone,
          startHoliday: startHoliday,
          horseManagement: horseManagement,
        });
        if (res.statusCode === 200) {
          isSettingsChanged = true;
          window.config.connectionMode = connectionMode;
          window.config.schoolZone = schoolZone;
          window.config.startHoliday = startHoliday;
          window.config.horseManagement = horseManagement;
        } else {
          setError({
            message: "Erreur lors de l'enregistrement de la configuration.",
            severity: "error",
          });
          return;
        }
      }

      if (isPictureChanged || isInfosChanged || isSettingsChanged) {
        // Update config
        const res = await post(`/configuration/generate`);
        if (res.statusCode === 200) {
          setError({
            message:
              "Configuration enregistrée. Les modifications seront visibles d'ici quelques minutes si vous rechargez la page.",
            severity: "success",
          });
          queryClient.invalidateQueries(["config"], { refetchInactive: true });
        } else {
          setError({
            message: "Erreur lors de l'enregistrement de la configuration.",
            severity: "error",
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    bufferPictures,
    club,
    connectionMode,
    config?.connectionMode,
    config?.schoolZone,
    config?.startHoliday,
    config?.horseManagement,
    config?.club,
    schoolZone,
    startHoliday,
    horseManagement,
    postData,
    setError,
    patch,
    post,
    queryClient,
  ]);

  return (
    <Grid2
      container
      spacing={0}
      sx={{
        maxWidth: "100%",
        marginTop: "86px",
      }}
    >
      <CssBaseline />
      <Grid2
        size={12}
        sx={{
          display: "flex",
          alignItems: "baseline",
          xs: { display: "none" },
        }}
      >
        <BreadcrumbComponent items={breadcrumbItems} />
      </Grid2>
      <Grid2 size={12} sx={{ p: 1, mt: 4 }} textAlign={"center"}>
        <Box sx={{ position: "relative" }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!isChanged || isLoading}
            onClick={handleReset}
            sx={{ mr: 1 }}
          >
            Réinitialiser
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 1 }}
            color="primary"
            disabled={!isChanged || isLoading}
            onClick={handleSave}
          >
            Enregistrer
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "calc(100% - 12px)",
                left: "calc(50% - 12px)",
              }}
            />
          )}
        </Box>
      </Grid2>
      <Grid2
        size={12}
        sx={{ p: 1, mt: 2, display: "flex", justifyContent: "center" }}
      >
        <Accordion
          sx={{
            margin: "auto",
            maxWidth: { sm: 700, md: 1414 },
            width: "100%",
          }}
          defaultExpanded={true}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant="h6"
              align="left"
              color="text.primary"
              sx={{ m: 1 }}
              display="inline"
            >
              Informations sur le club
            </Typography>
          </AccordionSummary>
          <AccordionDetails fullWidth>
            <Grid2 container spacing={2} sx={{ mb: 1 }}>
              <Grid2 size={{ xs: 12, sm: 6, lg: 4 }}>
                <OutlinedInput
                  required
                  fullWidth
                  id="name"
                  placeholder="Nom"
                  name="name"
                  autoComplete="nom"
                  autoFocus
                  startAdornment={
                    <InputAdornment position="start">
                      <BadgeIcon />
                    </InputAdornment>
                  }
                  value={club?.name}
                  onChange={handleFieldChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="shortName"
                  type="text"
                  id="abbreviation"
                  autoComplete="abbreviation"
                  placeholder="Nom abrégé"
                  startAdornment={
                    <InputAdornment position="start">
                      <ShortTextIcon />
                    </InputAdornment>
                  }
                  value={club?.shortName}
                  onChange={handleFieldChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 12, lg: 5 }}>
                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="address"
                  type="text"
                  id="address"
                  autoComplete="address"
                  placeholder="Adresse"
                  startAdornment={
                    <InputAdornment position="start">
                      <BusinessIcon />
                    </InputAdornment>
                  }
                  value={club?.address}
                  onChange={handleFieldChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, lg: 4 }}>
                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="email"
                  type="text"
                  id="email"
                  autoComplete="email"
                  placeholder="Email"
                  startAdornment={
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  }
                  value={club?.email}
                  onChange={handleFieldChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, lg: 3 }}>
                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="phone"
                  type="text"
                  id="phone"
                  autoComplete="phone"
                  placeholder="Téléphone"
                  startAdornment={
                    <InputAdornment position="start">
                      <PhoneIcon />
                    </InputAdornment>
                  }
                  value={club?.phone}
                  onChange={handleFieldChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 12, lg: 5 }}>
                <OutlinedInput
                  margin="normal"
                  required
                  fullWidth
                  name="website"
                  type="text"
                  id="website"
                  autoComplete="website"
                  placeholder="Site web"
                  startAdornment={
                    <InputAdornment position="start">
                      <HttpIcon />
                    </InputAdornment>
                  }
                  value={club?.website}
                  onChange={handleFieldChange}
                />
              </Grid2>
            </Grid2>
          </AccordionDetails>
        </Accordion>
      </Grid2>
      <Grid2 size={12} sx={{ p: 1, display: "flex", justifyContent: "center" }}>
        <Accordion
          sx={{
            margin: "auto",
            maxWidth: { sm: 700, md: 1414 },
            width: "100%",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant="h6"
              align="left"
              color="text.primary"
              sx={{ m: 1 }}
              display="inline"
            >
              Réseaux sociaux
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container spacing={2} sx={{ mb: 1 }}>
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                <OutlinedInput
                  required
                  fullWidth
                  id="facebook"
                  placeholder="Facebook"
                  name="facebook"
                  startAdornment={
                    <InputAdornment position="start">
                      <FacebookIcon />
                    </InputAdornment>
                  }
                  value={club?.facebook}
                  onChange={handleFieldChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                <OutlinedInput
                  required
                  fullWidth
                  id="instagram"
                  placeholder="Instagram"
                  name="instagram"
                  startAdornment={
                    <InputAdornment position="start">
                      <InstagramIcon />
                    </InputAdornment>
                  }
                  value={club?.instagram}
                  onChange={handleFieldChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 4 }}>
                <OutlinedInput
                  required
                  fullWidth
                  id="twitter"
                  placeholder="Twitter"
                  name="twitter"
                  startAdornment={
                    <InputAdornment position="start">
                      <TwitterIcon />
                    </InputAdornment>
                  }
                  value={club?.twitter}
                  onChange={handleFieldChange}
                />
              </Grid2>
            </Grid2>
          </AccordionDetails>
        </Accordion>
      </Grid2>
      <Grid2 size={12} sx={{ p: 1, display: "flex", justifyContent: "center" }}>
        <Accordion
          sx={{
            margin: "auto",
            maxWidth: { sm: 700, md: 1414 },
            width: "100%",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant="h6"
              align="left"
              color="text.primary"
              sx={{ m: 1 }}
              display="inline"
            >
              Comportement du site
            </Typography>
          </AccordionSummary>
          <AccordionDetails fullWidth>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <FormControl sx={{ pr: 1, m: 1 }} fullWidth>
                  <InputLabel id="connection-mode-select-label">
                    Mode de connexion des utilisateurs
                  </InputLabel>
                  <Select
                    labelId="connection-mode-select-label"
                    id="connection-mode-select"
                    value={connectionMode}
                    label="Mode de connexion des utilisateurs"
                    onChange={handleChangeConnectionMode}
                    fullWidth
                  >
                    <MenuItem value={0}>
                      Création de compte facultative
                    </MenuItem>
                    <MenuItem value={1}>
                      Création de compte obligatoire
                    </MenuItem>
                    <MenuItem value={2}>
                      Création de compte obligatoire et validation par le club
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <FormControl sx={{ pr: 1, m: 1 }} fullWidth>
                  <InputLabel id="horse-management-select-label">
                    Mode de gestion des chevaux
                  </InputLabel>
                  <Select
                    labelId="horse-management-select-label"
                    value={horseManagement}
                    label="Mode de gestion des chevaux"
                    onChange={handleChangeHorseManagement}
                    fullWidth
                  >
                    <MenuItem value={0}>
                      Seul le club peut créer les chevaux
                    </MenuItem>
                    <MenuItem value={1}>
                      Tous les utilisateurs peuvent créer des chevaux
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
          </AccordionDetails>
        </Accordion>
      </Grid2>
      <Grid2 size={12} sx={{ p: 1, display: "flex", justifyContent: "center" }}>
        <Accordion
          sx={{
            margin: "auto",
            maxWidth: { sm: 700, md: 1414 },
            width: "100%",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant="h6"
              align="left"
              color="text.primary"
              sx={{ m: 1 }}
              display="inline"
            >
              Vacances scolaires
            </Typography>
          </AccordionSummary>
          <AccordionDetails fullWidth>
            <Grid2 container spacing={2} sx={{ mb: 1 }}>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <FormControl sx={{ pr: 1, m: 1 }} fullWidth>
                  <InputLabel id="holiday-zone-label">Zone</InputLabel>
                  <Select
                    labelId="holiday-zone-label"
                    id="holiday-zone-select"
                    value={schoolZone}
                    label="Zone scolaire"
                    onChange={(e) => setSchoolZone(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                    <MenuItem value="C">C</MenuItem>
                    <MenuItem value="Corse">Corse</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <FormControl sx={{ pr: 1, m: 1 }} fullWidth>
                  <InputLabel id="holiday-start-label">
                    Jour de début
                  </InputLabel>
                  <Select
                    labelId="holiday-start-label"
                    id="holiday-start-select"
                    value={startHoliday}
                    label="Jour de début"
                    onChange={(e) => setStartHoliday(e.target.value)}
                    fullWidth
                  >
                    <MenuItem value="monday">Lundi</MenuItem>
                    <MenuItem value="saturday">Samedi</MenuItem>
                  </Select>
                </FormControl>
              </Grid2>
            </Grid2>
          </AccordionDetails>
        </Accordion>
      </Grid2>
      <Grid2 size={12} sx={{ p: 1, display: "flex", justifyContent: "center" }}>
        <Accordion
          sx={{
            margin: "auto",
            maxWidth: { sm: 700, md: 1414 },
            width: "100%",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              variant="h6"
              align="left"
              color="text.primary"
              sx={{ m: 1 }}
              display="inline"
            >
              Photos
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container spacing={2} sx={{ mb: 1 }}>
              {pictures.map((picture) => (
                <Grid2 size={{ sm: 12, md: 6 }}>
                  <PictureCard
                    key={picture.key}
                    picture={picture}
                    onChange={handleChooseFile}
                  />
                </Grid2>
              ))}
            </Grid2>
          </AccordionDetails>
        </Accordion>
      </Grid2>
      <Grid2 size={12} sx={{ p: 1, my: 2 }} textAlign={"center"}>
        <Box sx={{ position: "relative" }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={!isChanged || isLoading}
            onClick={handleReset}
          >
            Réinitialiser
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 2 }}
            color="primary"
            disabled={!isChanged || isLoading}
            onClick={handleSave}
          >
            Enregistrer
          </Button>
          {isLoading && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "-12px",
                left: "calc(50% - 12px)",
              }}
            />
          )}
        </Box>
      </Grid2>
    </Grid2>
  );
}
