import {
  Avatar,
  Box,
  CssBaseline,
  Grid2,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router";
import { useGetUser, useIsAllowedForPayment } from "../../../Features/login";

import { useGetConfig } from "../../../Features/config";
import Footer from "../../Footer/Footer";
import ResponsiveAppBar from "../../ResponsiveAppBar/ResponsiveAppBar";

export default function UserPaymentHome() {
  const user = useGetUser();
  const navigate = useNavigate();
  const config = useGetConfig();

  const isAllowedForPayment = useIsAllowedForPayment(user);

  useEffect(() => {
    if (user.isLoading) return;

    if (!isAllowedForPayment) {
      navigate("/403");
    }
  }, [user, navigate, isAllowedForPayment]);

  if (user.isLoading) return null;

  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Grid2
        container
        component="main"
        sx={{ height: "100%", marginTop: "86px" }}
      >
        <CssBaseline />
        <Grid2
          size={{ xs: false, sm: 1, md: 4, lg: 6, xl: 7 }}
          sx={{
            backgroundImage: `url(${config?.accountBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "calc(100vh - 86px - 140px)",
          }}
        />
        <Grid2
          size={{ xs: 12, sm: 11, md: 8, lg: 6, xl: 5 }}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              mt: 4,
              mb: 0,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ m: 1, border: "1px solid #000" }}
              src={config?.logo}
            />
            <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
              Informations de paiement
            </Typography>

            <Outlet />
          </Box>
        </Grid2>
      </Grid2>

      <Footer id="FOOTER" />
    </>
  );
}
