import {
  Avatar,
  Box,
  Button,
  CssBaseline,
  Grid2,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  useDeleteRider,
  useDetachRider,
  useListRiders,
} from "../../Features/Mutations/riders";
import { useGetUser, useIsAuthenticated } from "../../Features/login";

import IconAdd from "@mui/icons-material/Add";
import { useNavigate } from "react-router";
import { useRecoilState } from "recoil";
import { errorAtom } from "../../Features/atom";
import { useGetConfig } from "../../Features/config";
import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";
import RiderCard from "./Riders/RiderCard";
import RiderDetachConfirmDialog from "./Riders/RiderDetachConfirmDialog";
import RiderEditDialog from "./Riders/RiderEditDialog";

export default function Riders() {
  const [editOpen, setEditOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [editedRider, setEditedRider] = useState(null);
  const [expanded, setExpanded] = useState(null);

  const user = useGetUser();
  const navigate = useNavigate();
  const config = useGetConfig();
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  const riders = useListRiders();
  const deleteRider = useDeleteRider();
  const detachRider = useDetachRider();

  const userIsAuthenticated = useIsAuthenticated(user);
  useEffect(() => {
    if (user.isLoading) return;

    if (!userIsAuthenticated) {
      navigate("/403");
    }
  }, [user, navigate, userIsAuthenticated]);

  const handleAddRider = useCallback(() => {
    console.log("handleAddRider");
    setEditedRider(null);
    setEditOpen(true);
  }, []);

  const handleEditRider = useCallback((rider) => {
    console.log("handleEditRider", rider);
    setEditedRider(rider);
    setEditOpen(true);
  }, []);

  const handleCloseEdit = useCallback(() => {
    setEditOpen(false);
    setEditedRider(null);
  }, []);

  const handleExpandRider = useCallback(
    (rider) => {
      if (expanded === rider.guid) {
        setExpanded(null);
      } else {
        setExpanded(rider.guid);
      }
    },
    [expanded]
  );

  const handleDeleteRider = useCallback(
    async (rider) => {
      console.log("handleDeleteRider", rider);
      try {
        await deleteRider.mutateAsync({ rider: rider });
      } catch (e) {
        console.error("handleDeleteRider", e);
      }
    },
    [deleteRider]
  );

  const handleStartDetach = useCallback((rider) => {
    setEditOpen(false);
    setEditedRider(rider);
    setConfirmOpen(true);
  }, []);

  const handleCloseConfirm = useCallback(() => {
    setConfirmOpen(false);
    setEditedRider(null);
  }, []);

  const handleConfirmDetach = useCallback(async () => {
    try {
      await detachRider.mutateAsync({ rider: editedRider });
      setConfirmOpen(false);
      setEditedRider(null);
    } catch (e) {
      console.error("handleConfirmDetach", e);
    }
  }, [detachRider, editedRider]);

  if (user.isLoading) return null;

  return (
    <>
      <RiderEditDialog
        currentRider={editedRider}
        open={editOpen}
        onClose={handleCloseEdit}
        isCurrentUser={editedRider?.guid === user?.guid}
      />
      <RiderDetachConfirmDialog
        open={confirmOpen}
        handleClose={handleCloseConfirm}
        handleConfirm={handleConfirmDetach}
      />
      <ResponsiveAppBar id="HEADER" />
      <Grid2
        container
        component="main"
        sx={{ height: "100%", marginTop: "86px" }}
      >
        <CssBaseline />
        <Grid2
          size={{ xs: false, sm: 1, md: 4, lg: 6, xl: 7 }}
          sx={{
            backgroundImage: `url(${config?.accountBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "calc(100vh - 86px - 140px)",
          }}
        />
        <Grid2
          size={{ xs: 12, sm: 11, md: 8, lg: 6, xl: 5 }}
          component={Paper}
          elevation={6}
          square
          sx={{
            maxHeight: "calc(100vh - 86px - 140px)",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              mt: 4,
              mb: 1,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar
              sx={{ m: 1, border: "1px solid #000" }}
              src={config?.logo}
            />
            <Typography component="h1" variant="h5">
              Mes cavaliers
            </Typography>

            <Box sx={{ mx: "auto", width: { xs: "90%", sm: "70%" } }}>
              {riders && riders.riders?.length === 0 && (
                <Typography variant="body1">
                  Vous n'avez pas encore de cavalier.
                </Typography>
              )}

              <Stack spacing={1} sx={{ width: "100%", mt: 3 }}>
                <Button
                  variant="contained"
                  startIcon={<IconAdd />}
                  onClick={handleAddRider}
                >
                  Ajouter un cavalier
                </Button>
                {riders?.riders?.length > 0 &&
                  riders.riders.map((rider) => (
                    <RiderCard
                      rider={rider}
                      key={rider.guid}
                      onEdit={handleEditRider}
                      expanded={expanded}
                      onExpand={handleExpandRider}
                      onDelete={handleDeleteRider}
                      onDetach={handleStartDetach}
                    />
                  ))}
              </Stack>
            </Box>
          </Box>
        </Grid2>
      </Grid2>

      <Footer id="FOOTER" />
    </>
  );
}
