import React, { useCallback, useMemo } from "react";

import Box from "@mui/material/Box";
import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";
import CircularProgress from "@mui/material/CircularProgress";
import ContestCard from "./ContestCard/ContestCard";
import ContestFilter from "../../ContestFilter";
import Grid2 from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import moment from "moment";
import { useGetConfig } from "../../../Features/config";
import { useListContests } from "../../../Features/apiFetch";

export default function Contests() {
  const [filters, setFilters] = React.useState([]);
  const [sort, setSort] = React.useState("contest_date_asc");
  const contests = useListContests();
  const config = useGetConfig();
  const handleFiltersChange = useCallback((newFilters) => {
    setFilters(newFilters);
  }, []);

  const handleSortChange = useCallback((newSort) => {
    setSort(newSort);
  }, []);

  const contestsFiltered = useMemo(() => {
    const sortedContests = contests;
    if (!sortedContests) return null;
    sortedContests.sort((a, b) => {
      if (sort === "contest_date_desc") {
        return moment(b.date) - moment(a.date);
      } else if (sort === "contest_date_asc") {
        return moment(a.date) - moment(b.date);
      } else if (sort === "contest_name_desc") {
        return b.place.localeCompare(a.place);
      }
      return a.place.localeCompare(b.place);
    });
    return sortedContests;
  }, [contests, sort]);

  const mainGridSx = useMemo(() => {
    if (contests?.length === 0) {
      return {
        minHeight: "calc(100vh - 86px - 140px)",
        backgroundImage: `url(${config?.notFoundBackground})`,
        backgroundRepeat: "no-repeat",
        backgroundColor: (t) =>
          t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
        backgroundSize: "cover",
        backgroundPosition: "center",
      };
    }
  }, [contests, config?.notFoundBackground]);

  const breadcrumbItems = useMemo(() => {
    return [{ label: "Accueil", link: "/" }, { label: "Concours" }];
  }, []);

  return (
    <>
      <Grid2
        container
        spacing={0}
        sx={{
          maxWidth: "100%",
          marginTop: "86px",
          ...mainGridSx,
        }}
      >
        <Grid2
          sx={{
            display: "flex",
            alignItems: "baseline",
            xs: { display: "none" },
          }}
          size={{ xs: 0, sm: 4 }}
        >
          <BreadcrumbComponent items={breadcrumbItems} />
        </Grid2>
        {contestsFiltered?.length > 0 && (
          <Grid2
            textAlign={"right"}
            sx={{
              px: 4,
              display: "flex",
              justifyContent: "right",
              marginTop: "14px",
            }}
            size={{ xs: 12, sm: 8 }}
          >
            <ContestFilter
              onChange={handleFiltersChange}
              onSort={handleSortChange}
            />
          </Grid2>
        )}
        {contestsFiltered === null && (
          <Grid2
            size={12}
            sx={{ margin: "auto", height: "30vh", display: "flex" }}
            item
          >
            <Box sx={{ display: "flex", margin: "auto" }}>
              <CircularProgress />
            </Box>
          </Grid2>
        )}
        {contestsFiltered?.length === 0 && (
          <Grid2
            textAlign={"center"}
            sx={{ px: 4, mb: 4, display: "flex", justifyContent: "center" }}
            alignItems="flex-end"
            size={12}
          >
            <Typography
              variant="h5"
              align="center"
              sx={{
                p: 2,
                color: "#eeeeee",
              }}
            >
              Aucun concours n'est prévu pour le moment.
            </Typography>
          </Grid2>
        )}
      </Grid2>
      {contestsFiltered != null &&
        contestsFiltered?.map((contest) => (
          <ContestCard key={contest.guid} contest={contest} filters={filters} />
        ))}
    </>
  );
}
