import Avatar from "@mui/material/Avatar";
import React from "react";
import event from "../../../../Assets/icons/event.png";
import event_Dressage from "../../../../Assets/icons/event_Dressage.png";
import event_Jumping from "../../../../Assets/icons/event_Jumping.png";
import health from "../../../../Assets/icons/health.png";
import health_Dentist from "../../../../Assets/icons/health_Dentist.png";
import health_Marechal from "../../../../Assets/icons/health_Marechal.png";
import health_Vax from "../../../../Assets/icons/health_Vax.png";
import health_Veterinarian from "../../../../Assets/icons/health_Veterinarian.png";
import horse from "../../../../Assets/icons/horse.png";
import lesson from "../../../../Assets/icons/lesson.png";
import lesson_Dressage from "../../../../Assets/icons/lesson_Dressage.png";
import lesson_Jumping from "../../../../Assets/icons/lesson_Jumping.png";
import other from "../../../../Assets/icons/other.png";
import other_FootWork from "../../../../Assets/icons/other_FootWork.png";
import other_FreeHorse from "../../../../Assets/icons/other_FreeHorse.png";
import other_Longe from "../../../../Assets/icons/other_Longe.png";
import other_Ride from "../../../../Assets/icons/other_Ride.png";
import other_WalkRide from "../../../../Assets/icons/other_WalkRide.png";

const HorseCalendarAvatar = ({ eventType }) => {
  return eventType === "lesson_Jumping" ? (
    <Avatar
      src={lesson_Jumping}
      alt="Jumping"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "lesson_Dressage" ? (
    <Avatar
      src={lesson_Dressage}
      alt="Dressage"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType.startsWith("lesson") ? (
    <Avatar
      src={lesson}
      alt="Lesson"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "event_Jumping" ? (
    <Avatar
      src={event_Jumping}
      alt="Jumping"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "event_Dressage" ? (
    <Avatar
      src={event_Dressage}
      alt="Dressage"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType.startsWith("event") ? (
    <Avatar
      src={event}
      alt="Event"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "other_Ride" ? (
    <Avatar
      src={other_Ride}
      alt="Ride"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "other_FreeHorse" ? (
    <Avatar
      src={other_FreeHorse}
      alt="Free horse"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "other_Longe" ? (
    <Avatar
      src={other_Longe}
      alt="Longe"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "other_WalkRide" ? (
    <Avatar
      src={other_WalkRide}
      alt="Walk ride"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "other_FootWork" ? (
    <Avatar
      src={other_FootWork}
      alt="Foot work"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType.startsWith("other") ? (
    <Avatar
      src={other}
      alt="Other"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "health_Dentist" ? (
    <Avatar
      src={health_Dentist}
      alt="Dentist"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "health_Marechal" ? (
    <Avatar
      src={health_Marechal}
      alt="Marechal"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "health_Vax" ? (
    <Avatar
      src={health_Vax}
      alt="Vax"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType === "health_Veterinarian" ? (
    <Avatar
      src={health_Veterinarian}
      alt="Veterinarian"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : eventType.startsWith("health") ? (
    <Avatar
      src={health}
      alt="Health"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  ) : (
    <Avatar
      src={horse}
      alt="Horse"
      sx={{
        boxShadow: "2px 3px 4px rgba(0, 0, 0, 0.5)",
        width: 48,
        height: 48,
      }}
    />
  );
};

export default HorseCalendarAvatar;
