import { CssBaseline, Grid2, Typography } from "@mui/material";
import React, { useMemo } from "react";

import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";
import { useRouteError } from "react-router-dom";

export function AppErrorBoundary() {
  let error = useRouteError();

  const stack = useMemo(() => {
    if (!error?.stack) return "Unknow error";
    const tab = error?.stack.split("\n");

    //Create jsx for the two first lines
    let jsx = [];
    for (let i = 0; i < 2; i++) {
      if (tab[i]) {
        jsx.push(<>{tab[i]}</>);
        if (i === 0) jsx.push(<br />);
      }
    }
    return jsx;
  }, [error?.stack]);

  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Grid2
        container
        spacing={0}
        sx={{
          maxWidth: "100%",
          marginTop: "86px",
        }}
      >
        <CssBaseline />
        <Grid2
          sx={{
            pl: 4,
            pr: { md: 0, lg: 0, xl: 0, sm: 4, xs: 4 },
            margin: "auto",
            mt: 3,
            height: { md: "calc(100vh - 86px - 165px)" },
          }}
          size={{ xs: 12, sm: 12, md: 8, lg: 7, xl: 6 }}
        >
          <Typography
            variant="h6"
            color="text.primary"
            component="div"
            sx={{ my: 2 }}
          >
            Oups ! Une erreur inattendue est survenue...
          </Typography>
          <Typography variant="body2" color="text.primary" component="div">
            Essayez de recharger la page.
            <br /> Si le problème persiste, n'hésitez pas à nous contacter par
            mail à{" "}
            <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
              {process.env.REACT_APP_EMAIL}
            </a>{" "}
            pour nous indiquer le message d'erreur ci-dessous ainsi que les
            actions que vous avez effectué.
          </Typography>
          <Typography
            variant="body2"
            color="text.primary"
            component="div"
            sx={{ pt: 1, pb: 2 }}
          >
            Merci pour votre compréhension.
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            component="div"
            sx={{ py: 2 }}
          >
            {stack}
          </Typography>
        </Grid2>
      </Grid2>
      <Footer id="FOOTER" />
    </>
  );
}
