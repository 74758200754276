import { Box, Button, CircularProgress, Container, Stack } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import {
  useListUsers,
  useUpdateUserRight,
} from "../../../Features/Mutations/user";

import DashboardIcon from "@mui/icons-material/Dashboard";
import { USER_RIGHT } from "../../../Features/enums";
import PopoverCounters from "../../PopoverCounters/PopoverCounters";
import AdministrationBar from "./AdministrationBar";
import UserCard from "./UserCard";

export default function UserManagament() {
  const [filters, setFilters] = useState([]);
  const [sort, setSort] = useState("first_name_asc");
  const [anchorCounter, setAnchorCounter] = useState(null);

  const listUsers = useListUsers();

  const users = useMemo(() => {
    const sortedUsers = listUsers.users;
    if (!sortedUsers) return [];
    sortedUsers?.sort((a, b) => {
      if (sort === "first_name_desc") {
        const aFirstName = `${a.firstName} ${a.lastName}`;
        const bFirstName = `${b.firstName} ${b.lastName}`;
        return bFirstName.localeCompare(aFirstName);
      } else if (sort === "first_name_asc") {
        const aFirstName = `${a.firstName} ${a.lastName}`;
        const bFirstName = `${b.firstName} ${b.lastName}`;
        return aFirstName.localeCompare(bFirstName);
      } else if (sort === "last_name_desc") {
        const aLastName = `${a.lastName} ${a.firstName}`;
        const bLastName = `${b.lastName} ${b.firstName}`;
        return bLastName.localeCompare(aLastName);
      } else if (sort === "last_name_asc") {
        const aLastName = `${a.lastName} ${a.firstName}`;
        const bLastName = `${b.lastName} ${b.firstName}`;
        return aLastName.localeCompare(bLastName);
      } else if (sort === "email_desc") {
        return b.email.localeCompare(a.email);
      } else if (sort === "email_asc") {
        return a.email.localeCompare(b.email);
      } else {
        return 0;
      }
    });
    return sortedUsers;
  }, [listUsers, sort]);

  const countersOpen = useMemo(() => {
    return Boolean(anchorCounter);
  }, [anchorCounter]);

  const counters = useMemo(() => {
    const counters = {
      users: 0,
      members: 0,
      employee: 0,
      admin: 0,
    };
    counters.users = users?.length;
    counters.members = users?.filter((u) => u.right === USER_RIGHT.USER).length;
    counters.employee = users?.filter(
      (u) => u.right === USER_RIGHT.MODERATOR
    ).length;
    counters.admin = users?.filter((u) => u.right === USER_RIGHT.ADMIN).length;
    counters.child = users?.filter((u) => u.right === USER_RIGHT.CHILD).length;
    return counters;
  }, [users]);

  const updateMutation = useUpdateUserRight(users);

  const handleFiltersChange = useCallback(
    (filters) => {
      setFilters(filters);
    },
    [setFilters]
  );

  const handleSortChange = useCallback(
    (sortValue) => {
      setSort(sortValue);
    },
    [setSort]
  );

  const handleOpenCounters = useCallback((event) => {
    setAnchorCounter(event.currentTarget);
  }, []);

  const handleCloseCounters = useCallback(() => {
    setAnchorCounter(null);
  }, []);

  return (
    <>
      <PopoverCounters
        open={countersOpen}
        onClose={handleCloseCounters}
        anchorEl={anchorCounter}
        counters={counters}
      />
      <AdministrationBar
        id="ADMINISTRATION_BAR"
        onFilterChange={handleFiltersChange}
        onSort={handleSortChange}
      />
      {listUsers.isLoading && (
        <Box sx={{ display: "flex" }}>
          <CircularProgress sx={{ margin: "auto" }} />
        </Box>
      )}
      <Container sx={{ mx: "auto", mt: 4, mb: 4 }}>
        <Button
          onClick={handleOpenCounters}
          sx={{ minWidth: "36px", float: "right" }}
        >
          <DashboardIcon />
        </Button>
        <Stack spacing={1} sx={{ width: "100%" }}>
          {!listUsers.isLoading &&
            users?.map((user) => (
              <UserCard
                key={user.email}
                user={user}
                updateMutation={updateMutation}
                filters={filters}
              />
            ))}
        </Stack>
      </Container>
    </>
  );
}
