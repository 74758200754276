import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useGetUser, useIsAuthenticated } from "../../Features/login";

import { useGetConfig } from "../../Features/config";
import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";

export default function ContestsHome() {
  const user = useGetUser();
  const config = useGetConfig();
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated(user);

  useEffect(() => {
    if (user.isLoading || !config) return;
    if (!config?.connectionMode) return;

    if (!isAuthenticated) {
      navigate("/403");
    }
  }, [user, navigate, config, isAuthenticated]);

  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Outlet />
      <Footer id="FOOTER" />
    </>
  );
}
