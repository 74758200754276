import React, { useMemo, useState } from "react";

import HorseCalendarViewDesktop from "./HorseCalendarViews/HorseCalendarViewDesktop";
import HorseCalendarViewMobile from "./HorseCalendarViews/HorseCalendarViewMobile";
import moment from "moment/moment";
import { useCallback } from "react";
import { useListCalendarAlerts } from "../../../Features/Mutations/horsecalendar";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const HorseCalendarView = ({
  horsePicture,
  horseGuid,
  onDateChange,
  horseRation,
  canEdit,
  hayAddon,
}) => {
  const isMobile = useMediaQuery("(max-width:900px)");
  const navigate = useNavigate();
  const { alerts, isLoading } = useListCalendarAlerts(horseGuid);

  //init date state with moment and today date
  const [date, setDate] = useState(moment());

  const handleDateChange = (date) => {
    console.log(date.format("YYYY-MM-DD"));
    setDate(date);
    if (onDateChange) {
      onDateChange(date);
    }
  };

  const handleMonthChange = (date) => {
    console.log("month change:" + date.format("YYYY-MM-DD"));
  };

  const handleAddEntry = useCallback(() => {
    navigate(`/horse-calendar/${horseGuid}/add`);
  }, [horseGuid, navigate]);

  const picture = useMemo(() => {
    if (horsePicture) {
      return `${process.env.REACT_APP_CLOUDFRONT}/${horsePicture}`;
    }
    return `${process.env.REACT_APP_CLOUDFRONT}/placeholder.webp`;
  }, [horsePicture]);

  return !isMobile ? (
    <HorseCalendarViewDesktop
      picture={picture}
      onAddEntry={handleAddEntry}
      onDateChange={handleDateChange}
      date={date}
      onMonthChange={handleMonthChange}
      isLoading={isLoading}
      alerts={alerts}
      ration={horseRation}
      canEdit={canEdit}
      horseGuid={horseGuid}
      hayAddon={hayAddon}
    />
  ) : (
    <HorseCalendarViewMobile
      onAddEntry={handleAddEntry}
      onDateChange={handleDateChange}
      date={date}
      isLoading={isLoading}
      alerts={alerts}
      ration={horseRation}
      canEdit={canEdit}
      horseGuid={horseGuid}
      hayAddon={hayAddon}
    />
  );
};

export default HorseCalendarView;
