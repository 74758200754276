import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDelete, useGet, usePatch, usePost } from "../apiFetch";

import { useCallback } from "react";
import { useRecoilState } from "recoil";
import { errorAtom } from "../atom";

const useDisplayError = () => {
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  return useCallback(
    (error) => {
      setError({ message: error, severity: "error" });
    },
    [setError]
  );
};

export const useListRiders = () => {
  const get = useGet();

  const { data, error, isLoading } = useQuery({
    queryKey: ["riders"],
    queryFn: () => {
      return get(`/users/riders`);
    },
  });

  if (error) {
    console.log("useListRiders error", data.error);
    return { riders: null, isLoading };
  }
  if (data) {
    if (data.data.error) {
      console.log("useListRiders error", data.data.error);
      return { riders: null, isLoading };
    }
    return { riders: data?.data || null, isLoading };
  }

  return { riders: null, isLoading };
};

export const useListClubRiders = () => {
  const get = useGet();

  const { data, error, isLoading } = useQuery({
    queryKey: ["club_riders"],
    queryFn: () => {
      return get(`/rider`);
    },
  });

  if (error) {
    console.log("useListClubRiders error", data.error);
    return { riders: null, isLoading };
  }
  if (data) {
    console.log("useListClubRiders result", data.data);
    if (data.data.error) {
      console.log("useListClubRiders error", data.data.error);
      return { riders: null, isLoading };
    }
    return { riders: data?.data || null, isLoading };
  }

  return { riders: null, isLoading };
};

export const useCreateRider = () => {
  const post = usePost();
  const queryClient = useQueryClient();
  const displayError = useDisplayError();

  return useMutation({
    mutationFn: async ({ newrider }) => {
      const res = await post(`/rider/add`, newrider);
      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (rider) => {
      queryClient.setQueryData(["riders"], (oldData) => {
        return {
          data: [...oldData.data, rider],
          statusCode: 200,
        };
      });
      queryClient.invalidateQueries(["users", "club_riders"]);
    },
    onError: () => {
      displayError(
        "Erreur lors de la création du cavalier, vérifiez que le cavalier n'existe pas déjà"
      );
    },
  });
};

export const useUpsertRiders = () => {
  const post = usePost();
  const queryClient = useQueryClient();
  const displayError = useDisplayError();

  return useMutation({
    mutationFn: async ({ newList, email }) => {
      const res = await post(`/rider`, { riders: newList, userEmail: email });
      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["riders", "club_riders", "users"]);
    },
    onError: () => {
      displayError(
        "Vous ne pouvez pas supprimer un cavalier qui est propriétaire d'un cheval"
      );
    },
  });
};

export const useDeleteRider = () => {
  const deleteQuery = useDelete();
  const queryClient = useQueryClient();
  const displayError = useDisplayError();

  return useMutation({
    mutationFn: async ({ rider }) => {
      const res = await deleteQuery(`/rider/${rider.guid}`);
      if (res.statusCode === 200) {
        return rider.guid;
      } else {
        throw res.error;
      }
    },
    onSuccess: (riderGuid) => {
      queryClient.setQueryData(["riders"], (oldData) => {
        return {
          data: oldData.data.filter((r) => r.guid !== riderGuid),
          statusCode: 200,
        };
      });
      queryClient.invalidateQueries(["users", "club_riders"]);
    },
    onError: () => {
      displayError(
        "Vous ne pouvez pas supprimer un cavalier qui est propriétaire d'un cheval"
      );
    },
  });
};

export const useUpdateRider = () => {
  const post = usePost();
  const queryClient = useQueryClient();
  const displayError = useDisplayError();

  return useMutation({
    mutationFn: async ({ rider }) => {
      const res = await post(`/rider/${rider.guid}/update`, rider);
      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onSuccess: (rider) => {
      queryClient.setQueryData(["riders"], (oldData) => {
        return {
          data: oldData.data.map((r) => {
            if (r.guid === rider.guid) {
              return rider;
            }
            return r;
          }),
          statusCode: 200,
        };
      });
      queryClient.invalidateQueries(["users", "club_riders"]);
    },
    onError: () => {
      displayError(
        "Erreur lors de la modification du cavalier, vérifiez que le cavalier n'existe pas déjà"
      );
    },
  });
};

export const useDetachRider = () => {
  const patch = usePatch();
  const queryClient = useQueryClient();
  const displayError = useDisplayError();

  return useMutation({
    mutationFn: async ({ rider }) => {
      const res = await patch(`/rider/${rider.guid}/detach`);
      if (res.statusCode === 200) {
        return rider.guid;
      } else {
        throw res.error;
      }
    },
    onSuccess: (riderGuid) => {
      queryClient.setQueryData(["riders"], (oldData) => {
        return {
          data: oldData.data.filter((r) => r.guid !== riderGuid),
          statusCode: 200,
        };
      });
      queryClient.invalidateQueries(["users", "club_riders"]);
    },
    onError: () => {
      displayError(
        "Erreur lors de la suppression du cavalier, vérifiez que vous avez inialisé l'email et le mot de passe du cavalier"
      );
    },
  });
};
