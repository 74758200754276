import {
  Card,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  Grid2,
  IconButton,
  MenuItem,
  Switch,
  TextField,
} from "@mui/material";
import React, { useCallback } from "react";

import DeleteIcon from "@mui/icons-material/Delete";

export default function BillingCard({ billing, onChange, onDelete, index }) {
  const handleChange = useCallback(
    (e) => {
      const { name, value } = e.target;
      if (
        name === "ApplyOnEachTurn" ||
        name === "UserCancelable" ||
        name === "AllowOverwrite"
      ) {
        onChange(index, name, e.target.checked);
      } else {
        onChange(index, name, value);
      }
    },
    [index, onChange]
  );

  const handleDelete = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);

  return (
    <Grid2 size={12}>
      <Card variant="outlined" sx={{ display: "flex" }}>
        <CardContent sx={{ flex: 1, textAlign: "left" }}>
          <FormControl fullWidth>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, sm: 12, md: 6, lg: 5 }}>
                <TextField
                  required
                  placeholder="Description"
                  name="Description"
                  value={billing.Description}
                  label="Description"
                  variant="outlined"
                  sx={{ minWidth: 200 }}
                  fullWidth
                  onChange={handleChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 4, sm: 4, md: 2, lg: 2 }}>
                <TextField
                  required
                  placeholder="Prix HT"
                  name="Prices.NormalPriceHT"
                  value={billing.Prices.NormalPriceHT}
                  label="Prix HT"
                  variant="outlined"
                  fullWidth
                  type="number"
                  onChange={handleChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 4, sm: 4, md: 2, lg: 3 }}>
                <TextField
                  placeholder="Prix HT TDA"
                  name="Prices.TDAPriceHT"
                  value={billing.Prices.TDAPriceHT || ""}
                  label="Prix HT TDA"
                  variant="outlined"
                  fullWidth
                  type="number"
                  onChange={handleChange}
                />
              </Grid2>
              <Grid2 size={{ xs: 4, sm: 4, md: 2, lg: 2 }}>
                <TextField
                  value={billing.VAT || 0}
                  label="TVA"
                  onChange={handleChange}
                  name="VAT"
                  select
                >
                  <MenuItem value={20}>20%</MenuItem>
                  <MenuItem value={10}>10%</MenuItem>
                  <MenuItem value={5.5}>5,5%</MenuItem>
                  <MenuItem value={2.1}>2,1%</MenuItem>
                  <MenuItem value={0}>0%</MenuItem>
                </TextField>
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                <FormControlLabel
                  control={<Switch />}
                  label="S'applique à chaque tour"
                  onChange={handleChange}
                  name="ApplyOnEachTurn"
                  checked={billing.ApplyOnEachTurn || false}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 6, lg: 4 }}>
                <FormControlLabel
                  control={<Switch />}
                  label="Configurable par concours"
                  onChange={handleChange}
                  name="AllowOverwrite"
                  checked={billing.AllowOverwrite || false}
                />
              </Grid2>
              <Grid2 size={{ xs: 12, sm: 6, md: 12, lg: 4 }}>
                <FormControlLabel
                  control={<Switch />}
                  label="Annulable par l'utilisateur (*)"
                  onChange={handleChange}
                  name="UserCancelable"
                  checked={billing.UserCancelable || false}
                />
              </Grid2>
            </Grid2>
          </FormControl>
        </CardContent>
        <CardActions>
          <IconButton aria-label="delete" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Card>
    </Grid2>
  );
}
