import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useGet } from "./apiFetch";
import { USER_RIGHT } from "./enums";
import { useLocalStorage } from "./localStorage";

export const useGetAccessToken = () => {
  const [accessToken] = useLocalStorage("accessToken", null);
  console.log("accessToken", accessToken);
  return accessToken;
};

export const useGetUser = () => {
  const OneHourInMs = 1000 * 60 * 60 * 1;
  let accessToken = localStorage.getItem("accessToken");
  const get = useGet();
  const data = useQuery({
    queryKey: [accessToken],
    queryFn: () => {
      if (!accessToken) {
        return null;
      }

      return get(`/account/user`);
    },
    enabled: !!accessToken,
    refetchOnMount: false,
    staleTime: OneHourInMs,
  });
  if (!accessToken) {
    return { right: 0, isLoading: false };
  }

  if (data.error) {
    console.log("useGetUser error", data.error);
  }
  if (data) {
    if (data.isLoading) {
      return { isLoading: true };
    }

    return data.data?.data;
  }

  return { right: 0, isLoading: false };
};

export const useFacebookLogin = () => {
  const [_, setAccessToken] = useLocalStorage("accessToken", null); // eslint-disable-line no-unused-vars
  return useCallback(
    async (code) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/account/login/facebook?club=${window?.config?.club.guid}`,
          {
            method: "POST",
            body: JSON.stringify({ token: code }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();
        setAccessToken(data.accessToken);
        return true;
      } catch (e) {
        console.error("An error occur while loading", e);
        return false;
      }
    },
    [setAccessToken]
  );
};

export const useInternalLogin = () => {
  const [_, setAccessToken] = useLocalStorage("accessToken", null); // eslint-disable-line no-unused-vars
  return useCallback(
    async (email, password) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/account/login/internal?club=${window?.config?.club.guid}`,
          {
            method: "POST",
            body: JSON.stringify({ email, password }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status !== 200) {
          return false;
        }

        const data = await response.json();
        setAccessToken(data.accessToken);
        return true;
      } catch (e) {
        console.error("An error occur while loading", e);
        return false;
      }
    },
    [setAccessToken]
  );
};

export const useResetPassword = () => {
  const [_, setAccessToken] = useLocalStorage("accessToken", null); // eslint-disable-line no-unused-vars
  return useCallback(
    async (password, code) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/account/password-reset`,
          {
            method: "POST",
            body: JSON.stringify({
              code,
              password,
              club: window?.config?.club.guid,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status !== 200) {
          return false;
        }

        const data = await response.json();
        setAccessToken(data.accessToken);
        return true;
      } catch (e) {
        console.error("An error occur while loading", e);
        return false;
      }
    },
    [setAccessToken]
  );
};

export const useIsAuthenticated = (user) => {
  if (!user || user.isLoading) {
    return false;
  }

  return (
    user.right &&
    user.right !== USER_RIGHT.GUEST &&
    user.right !== USER_RIGHT.BANNED
  );
};

export const useIsAllowedForPayment = (user) => {
  if (!user || user.isLoading) {
    return false;
  }

  return (
    user.right &&
    user.right !== USER_RIGHT.GUEST &&
    user.right !== USER_RIGHT.BANNED &&
    user.right !== USER_RIGHT.CHILD
  );
};

export const useIsAdmin = (user) => {
  if (!user || user.isLoading) {
    return false;
  }

  return user?.right === USER_RIGHT.ADMIN;
};

export const useIsModerator = (user) => {
  if (!user || user.isLoading) {
    return false;
  }

  return (
    user?.right === USER_RIGHT.MODERATOR || user?.right === USER_RIGHT.ADMIN
  );
};

export const useIsChild = (user) => {
  if (!user || user.isLoading) {
    return false;
  }

  return user?.right === USER_RIGHT.CHILD;
};

export const useCanRegisterToContest = (user) => {
  if (!user || user.isLoading) {
    return false;
  }

  return (
    user?.right === USER_RIGHT.USER ||
    user?.right === USER_RIGHT.MODERATOR ||
    user?.right === USER_RIGHT.ADMIN
  );
};
