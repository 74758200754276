import { CssBaseline, Grid2 } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";
import HorseCalendarDetail from "./HorseCalendarDetail";
import HorseCalendarView from "./HorseCalendarView";
import moment from "moment";
import { useGetHorse } from "../../../Features/Mutations/horse";
import { useParams } from "react-router-dom";

export default function HorsePage() {
  const { horseGuid } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { horse, isLoading } = useGetHorse(horseGuid);
  const [date, setDate] = useState(moment());

  const handleDateChange = useCallback((date) => {
    setDate(date);
  }, []);

  const breadcrumbItems = useMemo(() => {
    return [
      { label: "Accueil", link: "/" },
      { label: "Suivi et Planning", link: "/horse-calendar" },
      { label: horse?.name || "Chargement..." },
    ];
  }, [horse]);

  return (
    <Grid2
      container
      component="main"
      sx={{
        maxWidth: "100%",
        marginTop: "86px",
      }}
    >
      <CssBaseline />
      <Grid2
        size={12}
        sx={{
          position: { md: "sticky", sm: "fixed", xs: "fixed" },
          zIndex: 1000,
          backgroundColor: "white",
        }}
      >
        <BreadcrumbComponent items={breadcrumbItems} />
      </Grid2>
      <Grid2
        size={{ xs: 12, sm: 12, md: 4 }}
        sx={{
          pl: 4,
          maxHeight: { md: "calc(100vh - 300px)", sm: "100%" },
          overflow: "auto",
          position: { md: "sticky", sm: "fixed", xs: "fixed" },
          zIndex: 1000,
          backgroundColor: "white",
          marginTop: { md: 0, sm: "50px", xs: "50px" },
        }}
      >
        <HorseCalendarView
          horsePicture={horse?.pictureUrl}
          onDateChange={handleDateChange}
          horseGuid={horse?.guid}
          horseRation={horse?.ration}
          canEdit={horse?.canEdit || false}
          hayAddon={horse?.hayAddon}
        />
      </Grid2>
      <Grid2
        size={{ xs: 12, sm: 12, md: 8 }}
        sx={{
          px: 4,
          maxHeight: { md: "calc(100vh - 300px)", sm: "100%" },
          overflow: "auto",
          marginTop: { md: 0, sm: "200px", xs: "200px" },
        }}
      >
        <HorseCalendarDetail
          horse={horse}
          startDate={date.format("YYYY-MM-DD")}
          canEdit={horse?.canEdit || false}
        />
      </Grid2>
    </Grid2>
  );
}
