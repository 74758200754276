import { CssBaseline, Grid2 } from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import {
  useCreateHorse,
  useDeleteHorse,
  useListHorses,
  useUpdateHorse,
} from "../../../Features/Mutations/horse";

import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";
import HorseEdit from "./HorseEdit";
import HorseTable from "./HorseTable";
import ModalDialog from "../../ModalDialog/ModalDialog";
import { errorAtom } from "../../../Features/atom";
import { useGetConfig } from "../../../Features/config";
import { useRecoilState } from "recoil";

export default function HorseList() {
  const [isEditing, setIsEditing] = useState(false);
  const [horseToEdit, setHorseToEdit] = useState(null);
  const [deleteModalConfig, setDeleteModalConfig] = useState(null);
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  const { horses, isLoading } = useListHorses();
  const createHorse = useCreateHorse(horses);
  const deleteHorse = useDeleteHorse(horses);
  const updateHorse = useUpdateHorse(horses);

  const config = useGetConfig();

  const breadcrumbItems = useMemo(() => {
    return [{ label: "Accueil", link: "/" }, { label: "Suivi et Planning" }];
  }, []);

  const backgroundImage = useMemo(() => {
    let backgroundImage = "";
    if (!config?.horseBackground) {
      backgroundImage = `${process.env.REACT_APP_CLOUDFRONT}/${window.location.hostname}/horseBackground.jpg`;
    } else {
      backgroundImage = `${config?.horseBackground}`;
    }
    console.log("backgroundImage", backgroundImage);
    return backgroundImage;
  }, [config]);

  const handleAddHorse = useCallback(() => {
    setHorseToEdit(null);
    setIsEditing(true);
  }, [setIsEditing]);

  const handleCreateHorse = useCallback(
    (isCreate, horse) => {
      if (isCreate) {
        createHorse.mutate({ horse });
      } else {
        // Update horse
        updateHorse.mutate({ horse });
      }
      setIsEditing(false);
    },
    [createHorse, updateHorse]
  );

  const handleDeleteHorse = useCallback(
    (horse) => {
      setDeleteModalConfig(null);
      deleteHorse.mutate({ horse });
    },
    [deleteHorse]
  );

  const handleDeleteHorseConfirm = useCallback(
    (horse) => {
      setDeleteModalConfig({
        title: "Suppression d'un cheval",
        content: `Voulez-vous vraiment supprimer le cheval ${horse.name} ?`,
        onConfirm: () => handleDeleteHorse(horse),
      });
    },
    [handleDeleteHorse]
  );

  const handleEditHorse = useCallback(
    (horse) => {
      setHorseToEdit(horse);
      setIsEditing(true);
    },
    [setIsEditing]
  );

  return (
    <Grid2
      container
      component="main"
      sx={{
        maxWidth: "100%",
        marginTop: "86px",
      }}
    >
      <CssBaseline />
      <ModalDialog
        config={deleteModalConfig}
        onClose={() => setDeleteModalConfig(null)}
      />
      <Grid2 size={12}>
        <BreadcrumbComponent items={breadcrumbItems} />
      </Grid2>
      <Grid2
        size={{ xs: false, sm: false, md: 3, lg: 5, xl: 6 }}
        sx={{
          pl: 4,
          backgroundImage: `url(${backgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          minHeight: "calc(100vh - 86px - 192px)",
          display: {
            xs: "none",
            sm: "none",
            md: "inherit",
          },
        }}
      />
      <Grid2
        textAlign={"center"}
        sx={{
          px: 4,
          mb: 0,
          display: "flex",
          justifyContent: "center",
          maxHeight: { md: "calc(100vh - 86px - 202px)", sm: "100%" },
          overflow: "auto",
        }}
        alignItems="center"
        size={{ xs: 12, sm: 12, md: 9, lg: 7, xl: 6 }}
      >
        <Grid2
          container
          spacing={2}
          sx={{
            maxWidth: 700,
            margin: "auto",
            mt: { sm: 3, xs: 3, md: "initial" },
          }}
        >
          {isEditing ? (
            <HorseEdit
              horse={horseToEdit}
              onSave={handleCreateHorse}
              onCancel={() => setIsEditing(false)}
            />
          ) : isLoading ? (
            <div>Veuillez patienter durant le chargement</div>
          ) : (
            <HorseTable
              horses={horses}
              onAddHorse={handleAddHorse}
              onDeleteHorse={handleDeleteHorseConfirm}
              onEditHorse={handleEditHorse}
            />
          )}
        </Grid2>
      </Grid2>
    </Grid2>
  );
}
