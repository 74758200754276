import { Alert, AlertTitle, CircularProgress, IconButton } from "@mui/material";
import React, { useCallback, useState } from "react";

import { Delete } from "@mui/icons-material";
import { useDeleteCalendarAlert } from "../../../../Features/Mutations/horsecalendar";

const HorseCalendarAlert = ({ horseGuid, alert, sx }) => {
  const [isPosting, setIsPosting] = useState(false);
  const deleteAlert = useDeleteCalendarAlert(horseGuid, alert.eventGuid);

  const handleDelete = useCallback(async () => {
    setIsPosting(true);
    try {
      await deleteAlert.mutateAsync();
    } finally {
      setIsPosting(false);
    }
  }, [deleteAlert]);

  return (
    <Alert
      severity="warning"
      sx={{ ...sx }}
      action={
        <IconButton
          color="inherit"
          size="small"
          onClick={handleDelete}
          disabled={isPosting}
        >
          {!isPosting ? (
            <Delete />
          ) : (
            <CircularProgress size={24} color="inherit" />
          )}
        </IconButton>
      }
    >
      <AlertTitle>{alert.title}</AlertTitle>
      {alert.description && alert.description}
    </Alert>
  );
};

export default HorseCalendarAlert;
