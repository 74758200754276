import { useCallback } from "react";

export const USER_RIGHT = {
  CHILD: 4,
  ADMIN: 3,
  MODERATOR: 2,
  USER: 1,
  GUEST: 0,
  BANNED: -1,
};

export const CONTEST_STATE = {
  CREATED: 0,
  CANCELED: 1,
  ARCHIVED: 2,
  BLOCKED: 3,
  WAITING_FOR_PAYMENT: 4,
};

export const SUBSCRIPTION_STATE = {
  CREATED: 0,
  ACCEPTED: 1,
  DECLINED_CLUB: 2,
  DECLINED_USER: 3,
};

export const BILLING_STATE = {
  WAITING_REGISTRATION: 0,
  TO_BILL: 1,
  PAID: 2,
  OFFERED: 3,
  REFUNDED: 4,
  CANCELED: 5,
  FAILED: 6,
  PROCESSING: 7,
  REQUIRES_ACTION: 8,
  REQUIRES_CAPTURE: 9,
  REQUIRES_PAYMENT_METHOD: 10,
  REQUIRES_CONFIRMATION: 11,
  PROCESSING_REFUND: 12,
  FAILED_REFUND: 13,
};

export const CLUB_BILLING_STATUS = {
  NOT_CREATED: 0,
  PENDING: 1,
  ACTIVE: 2,
  REJECTED: 3,
};

export const useBillingStateDescription = () => {
  return useCallback((state) => {
    switch (state) {
      case BILLING_STATE.WAITING_REGISTRATION:
        return "En attente de validation";
      case BILLING_STATE.TO_BILL:
        return "À facturer";
      case BILLING_STATE.PAID:
        return "Payé";
      case BILLING_STATE.OFFERED:
        return "Offert";
      case BILLING_STATE.REFUNDED:
        return "Remboursé";
      case BILLING_STATE.CANCELED:
        return "Annulé";
      case BILLING_STATE.FAILED:
        return "Échec de paiement";
      case BILLING_STATE.PROCESSING:
        return "En cours de traitement";
      case BILLING_STATE.REQUIRES_ACTION:
        return "Action requise";
      case BILLING_STATE.REQUIRES_CAPTURE:
        return "Capture requise";
      case BILLING_STATE.REQUIRES_PAYMENT_METHOD:
        return "Moyen de paiement requis";
      case BILLING_STATE.REQUIRES_CONFIRMATION:
        return "Confirmation requise";
      case BILLING_STATE.PROCESSING_REFUND:
        return "Remboursement en cours";
      case BILLING_STATE.FAILED_REFUND:
        return "Échec de remboursement";
      default:
        return "Inconnu";
    }
  }, []);
};
