import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useGetUser, useIsAuthenticated } from "../../Features/login";

import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";

export default function ManageSubscriptions() {
  const user = useGetUser();
  const isAuthenticated = useIsAuthenticated(user);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.isLoading) return;

    if (!isAuthenticated) {
      navigate("/403");
    }
  }, [user, navigate, isAuthenticated]);

  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Outlet />
      <Footer id="FOOTER" />
    </>
  );
}
