import { Box, Button, Chip, Stack } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import HorseCalendarAlert from "./HorseCalendarAlert";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import React from "react";

const HorseCalendarViewDesktop = ({
  horseGuid,
  picture,
  onAddEntry,
  onDateChange,
  onMonthChange,
  date,
  isLoading,
  alerts,
  ration,
  canEdit,
  hayAddon,
}) => {
  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: "space-between",
          alignItems: "flex-start",
          my: 2,
        }}
      >
        <Box
          sx={{
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={picture}
            alt="horse"
            style={{
              width: "100%",
              borderRadius: 5,
              maxWidth: 128,
              maxHeight: 128,
            }}
          />
        </Box>

        <Stack
          direction="column"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            mr: 2,
          }}
          spacing={2}
        >
          {canEdit && (
            <Button
              color="primary"
              onClick={onAddEntry}
              startIcon={<AddIcon />}
              variant="contained"
            >
              Ajouter
            </Button>
          )}

          {ration && ration !== "x / x / x" && (
            <Chip label={`Ration : ${ration}`} color="secondary" />
          )}
          {hayAddon && <Chip label="Supplément foin" color="info" />}
        </Stack>
      </Stack>

      <Box sx={{ pr: 2 }}>
        <MobileDatePicker
          label="Date"
          sx={{ my: 1, width: "100%" }}
          slotProps={{
            toolbar: {
              sx: {
                display: "none",
              },
            },
          }}
          onChange={onDateChange}
          value={date}
          onMonthChange={onMonthChange}
          selectedSections={"month"}
          views={["year", "month"]}
          openTo="month"
        />
      </Box>
      <Stack direction="column" sx={{ mr: 2 }}>
        {!isLoading &&
          alerts?.length !== 0 &&
          alerts?.map((alert) => (
            <HorseCalendarAlert
              key={alert.eventGuid}
              alert={alert}
              sx={{ mb: 1 }}
              horseGuid={horseGuid}
            />
          ))}
      </Stack>
    </>
  );
};

export default HorseCalendarViewDesktop;
