import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import {
  useDeleteCalendarEvent,
  useUpdateUnitCalendarEvent,
} from "../../../../Features/Mutations/horsecalendar";

import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { useNavigate } from "react-router";

const HorseCalendarEditUnit = ({ calendarEvent, horseGuid, currentDate }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [isPosting, setIsPosting] = useState(false);

  const [description, setDescription] = useState(calendarEvent.description);
  const [initialDescription, setInitialDescription] = useState(
    calendarEvent.description
  );

  const [rankHorse, setRankHorse] = useState(calendarEvent.rankHorse);
  const [rankRider, setRankRider] = useState(calendarEvent.rankRider);
  const [isPrivate, setIsPrivate] = useState(calendarEvent.isPrivate);

  const [initialRankHorse, setInitialRankHorse] = useState(
    calendarEvent.rankHorse
  );
  const [initialRankRider, setInitialRankRider] = useState(
    calendarEvent.rankRider
  );

  const mutationUpdateEvent = useUpdateUnitCalendarEvent(
    horseGuid,
    calendarEvent.eventGuid
  );
  const mutationDeleteEvent = useDeleteCalendarEvent(
    horseGuid,
    calendarEvent.eventGuid
  );

  const navigate = useNavigate();

  const isPastEvent = useMemo(() => {
    //event.date format is yyyy-MM-dd
    return currentDate <= new Date().toISOString().split("T")[0];
  }, [currentDate]);

  const isSavedRequired = useMemo(() => {
    return (
      initialDescription !== description ||
      initialRankHorse !== rankHorse ||
      initialRankRider !== rankRider ||
      isPrivate !== calendarEvent.isPrivate
    );
  }, [
    initialDescription,
    description,
    initialRankHorse,
    rankHorse,
    initialRankRider,
    rankRider,
    isPrivate,
    calendarEvent.isPrivate,
  ]);

  const formattedDate = useMemo(() => {
    return moment(currentDate).format("DD MMMM YYYY");
  }, [currentDate]);

  const handleEdit = useCallback(() => {
    setIsEditMode(!isEditMode);
  }, [isEditMode]);

  const handleHorseRankingChange = useCallback((event) => {
    setRankHorse(event.target.value);
  }, []);

  const handleRiderRankingChange = useCallback((event) => {
    setRankRider(event.target.value);
  }, []);

  const handleSave = useCallback(
    async (evt) => {
      evt.preventDefault();
      setIsEditMode(false);
      setIsPosting(true);

      const data = new FormData(evt.currentTarget);
      const isPrivate = data.get("isPrivate");
      const updatedEvent = {
        eventGuid: calendarEvent.eventGuid,
        description,
        rankHorse: parseInt(rankHorse, 10),
        rankRider: parseInt(rankRider, 10),
        isPrivate: isPrivate === "on" || isPrivate === "true",
        overrideDate: currentDate,
      };

      try {
        await mutationUpdateEvent.mutateAsync({ calendarEvent: updatedEvent });
      } catch (error) {
        console.log("Error updating event", error);
      }

      setIsPosting(false);
      setInitialDescription(description);
      setInitialRankHorse(rankHorse);
      setInitialRankRider(rankRider);
    },
    [
      calendarEvent.eventGuid,
      description,
      rankHorse,
      rankRider,
      currentDate,
      mutationUpdateEvent,
    ]
  );

  const handleDelete = useCallback(async () => {
    try {
      setIsPosting(true);
      await mutationDeleteEvent.mutateAsync();
      setIsPosting(false);
      navigate(`/horse-calendar/${horseGuid}`);
    } catch (error) {
      console.log("Error deleting event", error);
      setIsPosting(false);
    }
  }, [horseGuid, mutationDeleteEvent, navigate]);

  const handlePrivateChange = useCallback((event) => {
    setIsPrivate(event.target.checked);
  }, []);

  return (
    <Box
      sx={{ width: "80%", alignSelf: "center" }}
      component="form"
      noValidate
      onSubmit={handleSave}
    >
      <Typography variant="body2" sx={{ mb: 1, textAlign: "center" }}>
        {formattedDate}
      </Typography>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: 1,
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                value={isPrivate}
                disabled={isPosting}
                name="isPrivate"
                onChange={handlePrivateChange}
              />
            }
            label="Contenu privé"
          />
        </FormGroup>
      </Box>
      <Divider textAlign="center" sx={{ mb: 1 }}>
        Commentaires
        <IconButton onClick={handleEdit} sx={{ ml: 1 }} disabled={isPosting}>
          <EditIcon />
        </IconButton>
      </Divider>
      {isEditMode ? (
        <TextField
          label="Description"
          multiline
          maxRows={4}
          value={description}
          onChange={(event) => setDescription(event.target.value)}
          fullWidth
        />
      ) : description ? (
        <>
          {description.split("\n").map((line, index) => (
            <Typography
              variant="body2"
              color="text.secondary"
              key={index}
              align="center"
            >
              {line}
            </Typography>
          ))}
        </>
      ) : (
        <Typography
          variant="body2"
          align="center"
          sx={{ color: "gray", fontStyle: "italic" }}
        >
          Pas de commentaire
        </Typography>
      )}
      {isPastEvent && (
        <>
          <Divider textAlign="center" sx={{ mt: 2, mb: 1 }}>
            Note du cavalier
          </Divider>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%", // Ensure it takes the full width of the parent container
            }}
          >
            <Rating
              defaultValue={rankRider ? rankRider : 0}
              disabled={isPosting}
              name="riderRanking"
              onChange={handleRiderRankingChange}
            />
          </Box>
          <Divider textAlign="center" sx={{ mt: 2, mb: 1 }}>
            Note du cheval
          </Divider>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%", // Ensure it takes the full width of the parent container
            }}
          >
            <Rating
              defaultValue={rankHorse ? rankHorse : 0}
              disabled={isPosting}
              name="horseRanking"
              onChange={handleHorseRankingChange}
            />
          </Box>
        </>
      )}
      <Divider textAlign="center" sx={{ mt: 2, mb: 1 }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%", // Ensure it takes the full width of the parent container
        }}
      >
        <Box
          sx={{
            position: "relative",
            mt: 1,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            disabled={!isSavedRequired || isPosting}
            sx={{ mt: 2 }}
            type="submit"
          >
            Enregistrer
          </Button>
          {isPosting && (
            <CircularProgress
              size={24}
              sx={{
                position: "absolute",
                top: "0%",
                left: "50%",
                marginTop: "-6px",
                marginLeft: "-10px",
              }}
            />
          )}
          <Button
            variant="contained"
            color="error"
            sx={{ mt: 2, ml: 2 }}
            onClick={handleDelete}
            disabled={isPosting}
          >
            Supprimer
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default HorseCalendarEditUnit;
