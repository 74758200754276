import {
  Chip,
  FormControl,
  Grid2,
  List,
  ListItemIcon,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import Drawer from "@mui/material/Drawer";
import TuneIcon from "@mui/icons-material/Tune";

export default function Filter({
  items,
  defaultItems,
  allowFreeValue,
  onChange,
  localStorageKey,
  exclusions,
}) {
  const [filters, setFilters] = useState(() => {
    const saveFilters = localStorage.getItem(localStorageKey);
    if (saveFilters) {
      const saved = JSON.parse(saveFilters);
      const newFilters = [];
      for (const filter of saved) {
        if (items.findIndex((i) => i.value === filter.value) !== -1) {
          newFilters.push(filter);
        }
      }

      return newFilters;
    }

    return defaultItems || [];
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const mediaQuery = useMediaQuery("(min-width: 900px)");

  const anchor = useMemo(() => {
    return mediaQuery ? "right" : "bottom";
  }, [mediaQuery]);

  const toggleDrawer = useCallback(
    (open) => (event) => {
      if (
        event &&
        event.type === "keydown" &&
        (event.key === "Tab" || event.key === "Shift")
      ) {
        return;
      }

      setIsDrawerOpen(open);
    },
    [setIsDrawerOpen]
  );

  const handleOpen = useCallback(() => {
    setIsDrawerOpen(true);
  }, [setIsDrawerOpen]);

  const handleClose = useCallback(() => {
    setIsDrawerOpen(false);
  }, [setIsDrawerOpen]);

  const handleFilterClick = useCallback(
    (item) => {
      const index = filters.findIndex((f) => f.value === item.value);
      let newFilters = [...filters];
      if (index === -1) {
        newFilters = [...filters, item];

        if (exclusions && exclusions.length > 0) {
          // Check if the new filter is in the exclusions
          for (let i = 0; i < exclusions.length; i++) {
            const exclusion = exclusions[i];
            const values = exclusion.split(",");

            // trim all values
            for (let j = 0; j < values.length; j++) {
              values[j] = values[j].trim();
            }

            // only if the new filter is in the exclusion
            if (values.indexOf(item.value) !== -1) {
              let found = false;
              for (let j = 0; j < values.length; j++) {
                if (newFilters.findIndex((f) => f.value === values[j]) !== -1) {
                  found = true;
                  break;
                }
              }

              if (found) {
                // Remove all filters that are in the exclusion except the new one
                for (let j = 0; j < newFilters.length; j++) {
                  if (values.indexOf(newFilters[j].value) !== -1) {
                    if (newFilters[j].value !== item.value) {
                      newFilters.splice(j, 1);
                      j--;
                    }
                  }
                }
              }
            }
          }
        }
      } else {
        newFilters = filters.filter((f) => f.value !== item.value);
      }

      setFilters(newFilters);
    },
    [filters, setFilters, exclusions]
  );

  const handleAddFreeValue = useCallback(() => {
    const value = document.getElementsByName("freeValue")[0].value;
    if (!value) return;
    document.getElementsByName("freeValue")[0].value = "";

    const item = {
      label: value,
      value: value,
      type: "free",
    };
    setFilters([...filters, item]);
  }, [filters, setFilters]);

  const isFilterActive = useCallback(
    (item) => {
      return filters.findIndex((f) => f.value === item.value) !== -1;
    },
    [filters]
  );

  useEffect(() => {
    localStorage.setItem(localStorageKey, JSON.stringify(filters));
    if (onChange) {
      onChange(filters);
    }
  }, [filters, localStorageKey, onChange]);

  return (
    <React.Fragment>
      <Badge color="secondary" badgeContent={filters.length}>
        <Button
          variant="outlined"
          startIcon={<TuneIcon />}
          onClick={handleOpen}
        >
          Filtres
        </Button>
      </Badge>
      <Drawer anchor={anchor} open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Grid2 container sx={{ minWidth: "300px" }}>
          <Grid2 size={2} />
          <Grid2 size={8} sx={{ textAlign: "center" }}>
            <Typography variant="h6">Filtres</Typography>
          </Grid2>
          <Grid2 size={2} sx={{ textAlign: "right" }}>
            <Button onClick={handleClose}>
              <Close />
            </Button>
          </Grid2>
        </Grid2>
        {allowFreeValue && (
          <FormControl fullWidth>
            <List>
              <ListSubheader>Recherche</ListSubheader>
              <MenuItem>
                <OutlinedInput
                  fullWidth
                  placeholder="Rechercher"
                  size="small"
                  name="freeValue"
                />
                <Button
                  variant="contained"
                  sx={{ ml: 1 }}
                  onClick={handleAddFreeValue}
                >
                  ok
                </Button>
              </MenuItem>
            </List>
          </FormControl>
        )}
        <FormControl fullWidth>
          <List>
            {filters.map((filter) => {
              if (filter.type === "free") {
                return (
                  <MenuItem key={filter.label} value={filter.value}>
                    <Chip
                      label={filter.label}
                      onDelete={() => handleFilterClick(filter)}
                      color="primary"
                    ></Chip>
                  </MenuItem>
                );
              } else {
                return null;
              }
            })}
            {items.map((item) => {
              if (item.type === "header") {
                return (
                  <ListSubheader key={item.label}>{item.label}</ListSubheader>
                );
              } else {
                return (
                  <MenuItem
                    key={item.label}
                    value={item.value}
                    onClick={() => handleFilterClick(item)}
                  >
                    {isFilterActive(item) && (
                      <ListItemIcon>
                        <Check />
                      </ListItemIcon>
                    )}
                    {item.label}
                  </MenuItem>
                );
              }
            })}
          </List>
        </FormControl>
      </Drawer>
    </React.Fragment>
  );
}
