import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo } from "react";
import { useGet, usePatch, usePut } from "../../Features/apiFetch";
import { CONTEST_STATE } from "../../Features/enums";
import { useGetUser, useIsModerator } from "../../Features/login";

import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import moment from "moment/moment";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { errorAtom } from "../../Features/atom";
import Layout from "./Layout";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import Step3 from "./Steps/Step3";
import StepFacturation from "./Steps/StepFacturation";

export default function CreateContest() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [contest, setContest] = React.useState({});
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  const put = usePut();
  const get = useGet();
  const patch = usePatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const user = useGetUser();
  const isModerator = useIsModerator(user);
  const { contestGuid } = useParams();

  useEffect(() => {
    if (user.isLoading) return;

    if (!isModerator) {
      navigate("/403");
    }
  }, [user, navigate, isModerator]);

  const isModification = useMemo(() => {
    return contestGuid !== undefined;
  }, [contestGuid]);

  const handleNext = useCallback(
    (stepObject) => {
      setContest({ ...contest, ...stepObject });
      console.log(contest);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    },
    [contest]
  );

  const handleBack = useCallback(
    (stepObject) => {
      if (stepObject) {
        setContest({ ...contest, ...stepObject });
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    },
    [contest]
  );

  const handleCreate = useCallback(
    async (stepObject) => {
      setContest({ ...contest, ...stepObject });
      try {
        let updatedContest = { ...contest, ...stepObject };
        if (isModification) {
          const response = await patch(
            `/contest/${contestGuid}`,
            updatedContest,
            false
          );
          if (response.statusCode !== 200) {
            setError({
              message: "Erreur lors de la modification du concours",
              severity: "error",
            });
            return;
          }
        } else {
          const response = await put("/contest", updatedContest, false);
          if (response.statusCode !== 201) {
            setError({
              message: "Erreur lors de la création du concours",
              severity: "error",
            });
            return;
          }
          updatedContest = response.data;

          if (updatedContest.state === CONTEST_STATE.WAITING_FOR_PAYMENT) {
            // Redirect to payment page
            navigate(`/create-contest/payment/${updatedContest.guid}`);
            return;
          }
        }

        queryClient.invalidateQueries({ queryKey: ["contests"] });
        setError({
          message: "Concours créé avec succès",
          severity: "success",
        });

        if (isModification) {
          queryClient.setQueryData([`contest_${updatedContest.guid}`], {
            data: updatedContest,
            statusCode: 200,
          });
          navigate(`/contests/${updatedContest.guid}/manage`);
        } else {
          navigate("/contests");
        }
      } catch (e) {
        console.log("Error while creating contest", e);
        setError({
          message: "Erreur lors de la création ou la modification du concours",
          severity: "error",
        });
      }
    },
    [
      contest,
      isModification,
      queryClient,
      setError,
      navigate,
      patch,
      contestGuid,
      put,
    ]
  );

  const contestQuery = useQuery({
    queryKey: [`contest_${contestGuid}`],
    queryFn: () => {
      return get(`/contest/${contestGuid}`);
    },
    enabled: isModification,
  });

  useEffect(() => {
    if (contestQuery?.data?.data) {
      console.log("Setting contest", contestQuery?.data?.data);
      setContest(contestQuery?.data?.data);
    } else {
      setContest({});
    }
  }, [contestQuery?.data?.data]);

  const steps = useMemo(() => {
    const steps = [];
    steps.push({
      label: "Configuration générale",
      component: <Step1 handleNext={handleNext} contest={contest} />,
    });
    steps.push({
      label: "Jour et lieu",
      component: (
        <Step2
          index={1}
          handleNext={handleNext}
          handleBack={handleBack}
          contest={contest}
        />
      ),
    });

    if (contest.multiDays && contest.date && contest.endDate) {
      // Create one step per day from date to endDate
      const date = new Date(contest.date);
      const endDate = new Date(contest.endDate);
      while (date <= endDate) {
        steps.push({
          label:
            "Catégories du " +
            date.toLocaleDateString("fr-FR", {
              weekday: "long",
              day: "numeric",
              month: "long",
            }),
          component: (
            <Step3
              handleNext={handleNext}
              handleBack={handleBack}
              contest={contest}
              dateKey={moment(date).format("YYYY-MM-DD")}
              buttonLabel="Continuer"
            />
          ),
        });
        date.setDate(date.getDate() + 1);
      }
    } else {
      steps.push({
        label: "Catégories",
        component: (
          <Step3
            handleNext={handleNext}
            handleBack={handleBack}
            contest={contest}
            isLastDay={true}
            buttonLabel={"Continuer"}
          />
        ),
      });
    }

    steps.push({
      label: "Facturation",
      component: (
        <StepFacturation
          handleNext={handleCreate}
          handleBack={handleBack}
          contest={contest}
          isModification={isModification}
        />
      ),
    });

    return steps;
  }, [contest, handleBack, handleCreate, handleNext, isModification]);

  const breadcrumb = useMemo(() => {
    const tab = [];
    tab.push({
      label: "Accueil",
      link: "/",
    });
    if (isModification) {
      tab.push({
        label: "Concours",
        link: "/contests",
      });
      tab.push({
        label: contest?.place,
        link: `/contests/${contest.guid}/manage`,
      });
      tab.push({
        label: "Édition",
        link: null,
      });
    } else {
      tab.push({
        label: "Créer un concours",
        link: null,
      });
    }
    return tab;
  }, [isModification, contest]);

  return (
    <Layout breadcrumb={breadcrumb}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        sx={{ width: "100%" }}
      >
        {steps.map((step, index) => (
          <Step key={`step${index}`}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>{step.component}</StepContent>
          </Step>
        ))}
      </Stepper>
    </Layout>
  );
}
