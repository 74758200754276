import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Grid2,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUser, useIsModerator } from "../../Features/login";

import { useGet } from "../../Features/apiFetch";
import useLegacyEffect from "../../Features/legacyEffect";
import BreadcrumbComponent from "../BreadcrumbComponent/BreadcrumbComponent";
import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";

export default function Payment() {
  const navigate = useNavigate();
  const get = useGet();
  const { contestGuid } = useParams();
  const user = useGetUser();
  const isModerator = useIsModerator(user);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [invoiceStatus, setInvoiceStatus] = useState(null);
  const [getStatusCounter, setGetStatusCounter] = useState(0);

  useLegacyEffect(() => {
    const getContestStatus = async () => {
      setError(null);
      setInvoiceStatus(null);
      setIsLoading(true);

      const result = await get(`/billing/contest/${contestGuid}/status`);

      if (result.statusCode === 200) {
        if (result.data.invoiceStatus === "paid") {
          // redirect to contest list
          setInvoiceStatus(result.data);
          setIsLoading(false);

          setTimeout(() => {
            navigate(`/contests`);
          }, 5000);
        } else if (
          result.data.invoiceStatus === "open" ||
          result.data.invoiceStatus === "processing"
        ) {
          // retry after 5 seconds
          setInvoiceStatus(result.data);

          setTimeout(() => {
            setGetStatusCounter(getStatusCounter + 1);
          }, 5000);
        } else if (
          result.data.invoiceStatus === "void" ||
          result.data.invoiceStatus === "uncollectible" ||
          result.data.invoiceStatus === "no_payment_method"
        ) {
          setInvoiceStatus(result.data);
          setIsLoading(false);
        }
      } else if (result.statusCode === 404) {
        setError(1); // Contest not found - generic error
        setIsLoading(false);
      } else {
        setError(2); // Generic error
        setIsLoading(false);
      }
    };

    if (getStatusCounter < 4) {
      getContestStatus();
    } else {
      setIsLoading(false);
    }
  }, [contestGuid, get, getStatusCounter]);

  useEffect(() => {
    if (user.isLoading) return;

    if (!isModerator) {
      navigate("/403");
    }
  }, [user, navigate, isModerator]);

  const breadcrumb = useMemo(() => {
    const tab = [];
    tab.push({
      label: "Accueil",
      link: "/",
    });

    tab.push({
      label: "Créer un concours",
      link: null,
    });

    return tab;
  }, []);

  const handleShowInvoice = useCallback(() => {
    window.open(invoiceStatus.invoicePublicUrl, "_blank");
  }, [invoiceStatus]);

  return (
    <>
      <ResponsiveAppBar />
      <Grid2
        container
        component="main"
        sx={{ height: "100%", marginTop: "86px" }}
      >
        <CssBaseline />
        <Grid2 size={12}>
          <BreadcrumbComponent items={breadcrumb} />
        </Grid2>
        <Container
          sx={{
            height: "100%",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            margin: "auto",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 2,
                flexDirection: "column",
              }}
            >
              <CircularProgress sx={{ my: 4 }} />
              <Typography variant="body" sx={{ textAlign: "center" }}>
                Veuillez patienter...
                <br />
                Nous procédons au paiement de votre concours.
              </Typography>
            </Box>
          ) : !isLoading && error ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "top",
                my: 2,
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Alert severity="error">
                Une erreur est survenue lors du paiement de votre concours.
                <br />
                Veuillez réeassayer de le créer à nouveau.
                <br />
                Si le problème persiste, veuillez contacter le support à
                l'adresse suivante :{" "}
                <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
                  {process.env.REACT_APP_EMAIL}
                </a>
              </Alert>
            </Box>
          ) : invoiceStatus.invoiceStatus === "void" ||
            invoiceStatus.invoiceStatus === "uncollectible" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "top",
                my: 2,
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Alert severity="warning">
                Le facture de votre concours a été annulée, elle n'a pas été
                facturée.
              </Alert>
            </Box>
          ) : invoiceStatus.invoiceStatus === "paid" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 2,
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Alert severity="success">
                <AlertTitle>Paiement effectué</AlertTitle>
                Votre concours a été créé avec succès !
                <br />
                Vous allez être redirigé vers la liste de vos concours dans
                quelques secondes.
              </Alert>
            </Box>
          ) : invoiceStatus.invoiceStatus === "processing" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                my: 2,
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Alert severity="info">
                <AlertTitle>Paiement en cours de traitement</AlertTitle>
                Si le moyen de paiement sélectionné est le prélèvement SEPA, le
                paiement peut prendre jusqu'à 5 jours ouvrés.
                <br />
                Le concours sera visible dans la liste des concours une fois le
                paiement effectué.
                <br />
                <br />
                Vous pouvez suivre l'état de votre paiement sur la page
                "Administration / Paiements", ou en cliquant sur le bouton
                ci-dessous.
                <br />
                <br />
                <Button
                  onClick={handleShowInvoice}
                  variant="contained"
                  sx={{ display: "flex", minWidth: "50%", margin: "auto" }}
                >
                  Voir la facture
                </Button>
              </Alert>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "top",
                my: 2,
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Alert severity="info">
                <AlertTitle>Facture à régler</AlertTitle>
                Afin de finaliser la création de votre concours, vous devez
                procéder au paiement.
                <br />
                <br />
                Cliquer sur le bouton ci-dessous pour ouvrir la facture. Vous
                pouvez également retrouver la facture sur la page
                "Administration&nbsp;/&nbsp;Paiements".
                <br />
                Une fois le paiement effectué, veuillez recharger cette page.
                <br />
                <br />
                <Box sx={{ width: "100%" }}>
                  <Button
                    onClick={handleShowInvoice}
                    variant="contained"
                    sx={{ display: "flex", minWidth: "50%", margin: "auto" }}
                  >
                    Voir la facture
                  </Button>
                </Box>
              </Alert>
            </Box>
          )}
        </Container>
      </Grid2>
      <Footer />
    </>
  );
}
