import {
  Box,
  Chip,
  ListItemIcon,
  ListItemText,
  Rating,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import HorseCalendarAvatar from "./HorseCalendarAvatar/HorseCalendarAvatar";
import RepeatIcon from "@mui/icons-material/Repeat";

const HorseCalendarDetailItem = ({ event }) => {
  const [chipColors, setChipColors] = useState({});

  const getChipColor = useCallback(
    (riderName) => {
      if (!chipColors[riderName]) {
        //Create a color based on the rider name : instead of random, calculate a hash of the name
        const hash = riderName
          .split("")
          .reduce((acc, char) => acc + char.charCodeAt(0), 0);
        const color = `hsl(${hash % 360}, 50%, 50%)`;

        setChipColors((prev) => ({ ...prev, [riderName]: color }));
      }
      return chipColors[riderName];
    },
    [chipColors]
  );

  const rank = useMemo(() => {
    let total = 0;
    if (event.rankHorse) {
      total += 5;
    }

    if (event.rankRider) {
      total += 5;
    }

    let rank = 0;
    if (event.rankHorse) {
      rank += event.rankHorse;
    }
    if (event.rankRider) {
      rank += event.rankRider;
    }

    //max value is 5
    const value = total === 0 ? null : (rank / total) * 5;
    return value;
  }, [event.rankHorse, event.rankRider]);

  return (
    <>
      <ListItemIcon sx={{ mr: 2 }}>
        <HorseCalendarAvatar eventType={event.eventType} />
      </ListItemIcon>
      <ListItemText
        primary={
          <Box
            display="flex"
            alignItems="center"
            sx={{
              justifyContent: "space-between",
            }}
          >
            <Box display="flex" alignItems="center">
              {event.isRecurring && <RepeatIcon sx={{ fontSize: 16, mr: 1 }} />}
              <Typography>{event.title}</Typography>
            </Box>

            {event.riderName && (
              <Chip
                label={event.riderName}
                sx={{ ml: 1 }}
                size="small"
                style={{
                  backgroundColor: getChipColor(event.riderName),
                  color: "white",
                }}
              />
            )}
          </Box>
        }
        secondary={
          event.description ? (
            <>
              {event.description.split("\n").map((line, index) => (
                <Typography variant="body2" color="text.secondary" key={index}>
                  {line}
                </Typography>
              ))}
              {rank !== null && (
                <Rating value={rank} readOnly precision={0.5} sx={{ mt: 1 }} />
              )}
            </>
          ) : (
            rank !== null && (
              <Rating value={rank} readOnly precision={0.5} sx={{ mt: 1 }} />
            )
          )
        }
      />
    </>
  );
};

export default HorseCalendarDetailItem;
