import {
  Box,
  Breadcrumbs,
  Chip,
  CircularProgress,
  CssBaseline,
  Grid2,
  Stack,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useGetHorse } from "../../../Features/Mutations/horse";
import { useGetCalendarEvent } from "../../../Features/Mutations/horsecalendar";
import HorseCalendarAvatar from "./HorseCalendarAvatar/HorseCalendarAvatar";
import HorseCalendarEditRecurrent from "./HorseCalendarEdit/HorseCalendarEditRecurrent";
import HorseCalendarEditUnit from "./HorseCalendarEdit/HorseCalendarEditUnit";

const HorseCalendarEdit = ({ detachEvent }) => {
  const { horseGuid, entryGuid } = useParams();
  const [query, setQuery] = useSearchParams(); // eslint-disable-line no-unused-vars
  const currentDate = query.get("date");
  const { horse, isLoading } = useGetHorse(horseGuid); // eslint-disable-line no-unused-vars
  const { event, isLoading: isEventLoading } = useGetCalendarEvent(
    horseGuid,
    entryGuid
  );
  const navigate = useNavigate();

  const picture = useMemo(() => {
    if (horse?.pictureUrl) {
      return `${process.env.REACT_APP_CLOUDFRONT}/${horse?.pictureUrl}`;
    }
    return `${process.env.REACT_APP_CLOUDFRONT}/placeholder.webp`;
  }, [horse?.pictureUrl]);

  return (
    <Grid2
      container
      component="main"
      sx={{
        maxWidth: "100%",
        marginTop: "86px",
      }}
    >
      <CssBaseline />
      <Grid2 size={12}>
        <Breadcrumbs
          separator=""
          aria-label="breadcrumb"
          sx={{ pl: 4, pt: "14px", pb: "7px" }}
        >
          <Link
            underline="hover"
            onClick={() => navigate(`/horse-calendar/${horseGuid}`)}
            sx={{ cursor: "pointer" }}
          >
            <ArrowBackIosNewIcon
              sx={{ color: "GrayText", height: "16px", mt: 0.8 }}
            />
          </Link>
          <Typography variant="h6" align="left" color="text.primary">
            {isEventLoading ? "Chargement..." : event?.title}
          </Typography>
        </Breadcrumbs>
      </Grid2>
      <Grid2
        size={{ xs: 0, sm: 0, md: 4 }}
        sx={{
          pl: 4,
        }}
      >
        <img
          src={picture}
          alt={horse?.name}
          style={{ width: "100%", borderRadius: 6 }}
        />
      </Grid2>
      <Grid2
        size={{ xs: 12, sm: 12, md: 8 }}
        sx={{
          px: 4,
        }}
      >
        {!isEventLoading ? (
          <Box>
            <Stack
              spacing={2}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%", // Ensure it takes the full width of the parent container
              }}
            >
              <HorseCalendarAvatar eventType={event?.eventType} />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%", // Ensure it takes the full width of the parent container }}
                }}
              >
                <Typography variant="h6">{event?.title}</Typography>
                {event?.riderName && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mb: 2,
                    }}
                  >
                    <Chip label={event.riderName} color="primary" />
                  </Box>
                )}
                {!event?.isRecurring || detachEvent ? (
                  <HorseCalendarEditUnit
                    calendarEvent={event}
                    horseGuid={horseGuid}
                    currentDate={currentDate}
                  />
                ) : (
                  <HorseCalendarEditRecurrent
                    calendarEvent={event}
                    horseGuid={horseGuid}
                    currentDate={currentDate}
                  />
                )}
              </Box>
            </Stack>
          </Box>
        ) : (
          <CircularProgress />
        )}
      </Grid2>
    </Grid2>
  );
};

export default HorseCalendarEdit;
