import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid2,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useGetUser, useIsModerator } from "../../../Features/login";

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import RiderSelect from "../../RiderSelect/RiderSelect";
import moment from "moment/moment";
import { useGetConfig } from "../../../Features/config";
import { useListClubRiders } from "../../../Features/Mutations/riders";
import { useListUsers } from "../../../Features/Mutations/user";

const HorseEdit = ({ horse, onSave, onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [birthDate, setBirthDate] = useState(horse?.birthDate);
  const [ownerType, setOwnerType] = useState(() => {
    if (horse === null) {
      return "user";
    }
    if (horse?.ownerGuid === null) {
      return "club";
    }

    if (horse?.userIsOwner) {
      return "user";
    } else {
      return "other";
    }
  });
  const [rider, setRider] = useState(null);
  const [owner, setOwner] = useState(horse?.ownerGuid || null);
  const [riders, setRiders] = useState(horse?.riders || []);
  const [error, setError] = useState(null);

  const config = useGetConfig();
  const user = useGetUser();
  const { riders: clubRiders, isLoading: isRidersLoading } =
    useListClubRiders();
  const { users, isLoading: isUsersLoading } = useListUsers();

  const horseOwner = useMemo(() => {
    if (isUsersLoading) return null;

    if (ownerType === "other") {
      // get owner from horse
      const o = users.find((u) => u.guid === horse?.ownerGuid);
      return o;
    }
    return null;
  }, [horse?.ownerGuid, isUsersLoading, ownerType, users]);

  const isManager = useIsModerator(user);

  const rationMorning = useMemo(() => {
    if (horse && horse.ration) {
      const tab = horse.ration.split("/");
      const morning = tab[0].trim();
      if (morning !== "x") {
        return morning;
      }
    }
    return null;
  }, [horse]);

  const rationLunch = useMemo(() => {
    if (horse && horse.ration) {
      const tab = horse.ration.split("/");
      if (tab.length < 2) {
        return null;
      }
      const lunch = tab[1].trim();
      if (lunch !== "x") {
        return lunch;
      }
    }
    return null;
  }, [horse]);

  const rationEvening = useMemo(() => {
    if (horse && horse.ration) {
      const tab = horse.ration.split("/");

      if (tab.length < 3) {
        return null;
      }

      const evening = tab[2].trim();
      if (evening !== "x") {
        return evening;
      }
    }
    return null;
  }, [horse]);

  const canEdit = useMemo(() => {
    if (isManager) return true;
    if (!horse) return true;

    if (horse && horse.userIsOwner) return true;

    return false;
  }, [horse, isManager]);

  const handleSave = useCallback(
    (event) => {
      setIsLoading(true);
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const name = canEdit ? formData.get("name") : horse.name;
      const sire = canEdit ? formData.get("sire") : horse.sire;
      const rationMorning = formData.get("rationMorning");
      const rationLunch = formData.get("rationLunch");
      const rationEvening = formData.get("rationEvening");
      const hayAddon = canEdit
        ? formData.get("hayAddon") === "on"
        : horse.hayAddon;

      let ration = "";
      if (canEdit) {
        if (rationMorning) {
          ration += `${rationMorning} / `;
        } else {
          ration += "x / ";
        }

        if (rationLunch) {
          ration += `${rationLunch} / `;
        } else {
          ration += "x / ";
        }

        if (rationEvening) {
          ration += `${rationEvening}`;
        } else {
          ration += "x";
        }
      } else {
        ration = horse.ration;
      }

      const updatedHorse = {
        ...horse,
        name,
        sire,
        birthDate,
        ownerType,
        riderName: rider,
        riders: (riders || []).map((rider) => rider.guid),
        owner: ownerType === "other" ? owner : null,
        ration,
        hayAddon: hayAddon,
      };

      if (riders && riders.length > 0) {
        // verify riders down not contain owner when ownerType is not club
        if (ownerType !== "club" && riders.find((r) => r.guid === owner)) {
          setError("Le propriétaire ne peut pas être un cavalier en pension");
          setIsLoading(false);
          return;
        }
      }

      onSave(horse === null, updatedHorse);
      setIsLoading(false);
    },
    [canEdit, horse, birthDate, ownerType, rider, riders, owner, onSave]
  );

  const handleDateChange = useCallback((date) => {
    const dateStr = date.format("YYYY-MM-DD");
    setBirthDate(dateStr);
  }, []);

  const handleIsMyHorse = useCallback((event) => {
    const value = event.target.value;
    setOwnerType(value);
  }, []);

  const handleRiderChange = useCallback((rider) => {
    setError(null);
    setRider(rider);
  }, []);

  const handleOwnerChange = useCallback((owner) => {
    setError(null);
    setOwner(owner?.guid || null);
  }, []);

  return (
    <Grid2
      size={12}
      sx={{ minWidth: { xs: "100%", sm: "100%", md: "auto" }, mb: 1 }}
    >
      <Box
        sx={{
          mb: 4,
          mt: 2,
          mx: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, border: "1px solid #000" }} src={config?.logo} />
        <Typography component="h1" variant="h5">
          {horse ? "Modifier un cheval" : "Ajouter un cheval"}
        </Typography>
        <Box
          component="form"
          noValidate
          onSubmit={handleSave}
          sx={{ mt: 3, width: "100%", maxWidth: "500px" }}
        >
          <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <TextField
                name="name"
                required
                fullWidth
                id="name"
                label="Nom"
                autoFocus
                defaultValue={horse?.name}
                disabled={!canEdit}
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <MobileDatePicker
                sx={{ width: "100%" }}
                label="Date de naissance"
                fullWidth
                slotProps={{
                  toolbar: {
                    sx: {
                      display: "none",
                    },
                    textField: { fullWidth: true, sx: { width: "100%" } },
                  },
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
                onChange={handleDateChange}
                value={horse?.birthDate ? moment(horse.birthDate) : null}
                disabled={!canEdit}
              />
            </Grid2>
            <Grid2 size={12}>
              <TextField
                fullWidth
                id="sire"
                label="SIRE"
                name="sire"
                defaultValue={horse?.sire}
                maxLength={9}
                disabled={!canEdit}
              />
            </Grid2>

            {(horse === null || isManager) && (
              <>
                <Grid2 size={12}>
                  <RadioGroup
                    aria-labelledby="radio-buttons-owner"
                    defaultValue={ownerType}
                    name="radio-buttons-group"
                    onChange={handleIsMyHorse}
                  >
                    <FormControlLabel
                      value="user"
                      control={<Radio />}
                      label="Mon cheval"
                    />
                    <FormControlLabel
                      value="club"
                      control={<Radio />}
                      label="Cheval du club"
                    />
                    {isManager && (
                      <FormControlLabel
                        disabled={!isManager}
                        value="other"
                        control={<Radio />}
                        label="Cheval de propriétaire"
                      />
                    )}
                  </RadioGroup>
                </Grid2>

                <Grid2
                  size={12}
                  sx={{ display: ownerType === "user" ? "" : "none" }}
                >
                  <RiderSelect
                    onChange={handleRiderChange}
                    label="Prénom et nom du cavalier"
                    multiple={false}
                    defaultValue={horse?.ownerName}
                  />
                </Grid2>
              </>
            )}

            <Grid2 size={12}>
              <Autocomplete
                multiple
                options={
                  clubRiders?.filter(
                    (rider) =>
                      !riders?.some((selected) => selected.guid === rider.guid)
                  ) || []
                }
                onChange={(event, value) => {
                  setError(null);
                  setRiders(value);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Cavalier en pension" />
                )}
                clearIcon={canEdit ? undefined : null}
                loading={isRidersLoading}
                getOptionLabel={(option) => option.name}
                defaultValue={horse?.riders || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      {...getTagProps({ index })}
                      label={option.name}
                      onDelete={
                        canEdit || option.guid === user?.guid
                          ? getTagProps({ index }).onDelete
                          : undefined
                      }
                    />
                  ))
                }
              />
            </Grid2>
            {isManager && ownerType === "other" && !isUsersLoading && (
              <Grid2 size={12}>
                <Autocomplete
                  options={users || []}
                  onChange={(event, value) => {
                    handleOwnerChange(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Propriétaire" />
                  )}
                  loading={isUsersLoading}
                  getOptionLabel={(option) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  defaultValue={horseOwner}
                />
              </Grid2>
            )}
            <Grid2 size={12}>
              <Divider textAlign="left">Ration</Divider>
            </Grid2>
            <Grid2 size={4}>
              <TextField
                fullWidth
                name="rationMorning"
                id="rationMorning"
                label="Matin"
                type="number"
                slotProps={{
                  htmlInput: {
                    min: 0,
                    max: 5,
                  },
                }}
                defaultValue={rationMorning}
                disabled={!canEdit}
              />
            </Grid2>
            <Grid2 size={4}>
              <TextField
                fullWidth
                name="rationLunch"
                label="Midi"
                type="number"
                slotProps={{
                  htmlInput: {
                    min: 0,
                    max: 5,
                  },
                }}
                defaultValue={rationLunch}
                disabled={!canEdit}
              />
            </Grid2>
            <Grid2 size={4}>
              <TextField
                fullWidth
                name="rationEvening"
                label="Soir"
                type="number"
                slotProps={{
                  htmlInput: {
                    min: 0,
                    max: 5,
                  },
                }}
                defaultValue={rationEvening}
                disabled={!canEdit}
              />
            </Grid2>
            <Grid2 size={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked={horse?.hayAddon || false}
                      disabled={!canEdit}
                      name="hayAddon"
                    />
                  }
                  label="Supplément foin"
                />
              </FormGroup>
            </Grid2>
            <Grid2 size={12}>
              <Divider />
            </Grid2>
            {error && (
              <Grid2 size={12}>
                <Typography color="error">{error}</Typography>
              </Grid2>
            )}

            <Box
              sx={{
                position: "relative",
                mt: error ? 0 : 3,
                ml: 2,
                width: "100%",
              }}
            >
              <ButtonGroup fullWidth>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  disabled={isLoading}
                >
                  {horse ? "Modifier" : "Ajouter"}
                </Button>
                <Button
                  color="secondary"
                  variant="contained"
                  sx={{ width: "initial" }}
                  onClick={onCancel}
                  disabled={isLoading}
                >
                  Annuler
                </Button>
              </ButtonGroup>
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-6px",
                    marginLeft: "-10px",
                  }}
                />
              )}
            </Box>
          </Grid2>
        </Box>
      </Box>
    </Grid2>
  );
};

export default HorseEdit;
