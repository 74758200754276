import { CssBaseline, Grid2, Typography } from "@mui/material";
import React, { useMemo } from "react";

import BreadcrumbComponent from "../BreadcrumbComponent/BreadcrumbComponent";
import Footer from "../Footer/Footer";
import ResponsiveAppBar from "../ResponsiveAppBar/ResponsiveAppBar";
import { useGetConfig } from "../../Features/config";

export default function Confidentiality() {
  const config = useGetConfig();

  const breadcrumbItems = useMemo(() => {
    return [
      { label: "Accueil", link: "/" },
      { label: "Politique de confidentialité" },
    ];
  }, []);

  return (
    <>
      <ResponsiveAppBar id="HEADER" />
      <Grid2
        container
        spacing={0}
        sx={{
          maxWidth: "100%",
          marginTop: "86px",
        }}
      >
        <CssBaseline />
        <Grid2 size={12}>
          <BreadcrumbComponent items={breadcrumbItems} />
        </Grid2>
        <Grid2
          size={{ xs: false, false: 12, md: 4, lg: 5, xl: 6 }}
          sx={{
            pl: 4,
            backgroundImage: `url(${config?.homeBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "calc(100vh - 86px - 192px)",
            display: {
              xs: "none",
              sm: "none",
              md: "inherit",
            },
          }}
        ></Grid2>
        <Grid2
          sx={{
            pl: 4,
            pr: { md: 0, lg: 0, xl: 0, sm: 4, xs: 4 },
            margin: "auto",
            mt: 3,
            overflow: { md: "scroll" },
            height: { md: "calc(100vh - 86px - 218px)" },
          }}
          size={{ xs: 12, sm: 12, md: 8, lg: 7, xl: 6 }}
        >
          <Typography variant="h5" component="div">
            Politique de confidentialité
          </Typography>
          <Typography
            variant="h6"
            color="text.primary"
            component="div"
            sx={{ mt: 2 }}
          >
            Dernière mise à jour : 23 avril 2024
          </Typography>
          <Typography variant="body" sx={{ mr: 2 }}>
            Nous vous remercions de faire confiance à {window.location.hostname}{" "}
            pour la gestion de vos inscriptions aux concours équestres. Nous
            accordons une grande importance à la protection de vos informations
            personnelles et nous nous engageons à respecter votre vie privée. La
            présente Politique de Confidentialité a pour but de vous informer
            sur la manière dont nous collectons, utilisons et protégeons vos
            données.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Collecte des Informations
          </Typography>
          <Typography variant="body">
            Nous ne collectons que les informations strictement nécessaires à la
            gestion des inscriptions aux concours équestres. Ces informations
            peuvent inclure, sans s'y limiter, votre nom, prénom, adresse e-mail
            et les détails liés à la participation aux concours.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Utilisation des Informations
          </Typography>
          <Typography variant="body">
            Les informations que vous fournissez sont utilisées exclusivement
            dans le but de faciliter le processus d'inscription aux concours
            équestres. Elles ne seront en aucun cas vendues, louées ou partagées
            avec des tiers à des fins commerciales.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Sécurité des Informations
          </Typography>
          <Typography variant="body">
            Nous mettons en œuvre des mesures de sécurité appropriées pour
            protéger vos informations contre tout accès non autorisé,
            altération, divulgation ou destruction. Cependant, veuillez noter
            qu'aucune méthode de transmission sur Internet ou de stockage
            électronique n'est totalement sécurisée, et nous ne pouvons garantir
            une sécurité absolue.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Cookies
          </Typography>
          <Typography variant="body">
            Notre site n'utilise pas de cookies. Cela signifie que nous ne
            stockons aucune information sur votre appareil sans votre
            consentement explicite.
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Cookies Tiers
          </Typography>
          <Typography variant="body">
            Nous utilisons Stripe pour les paiements et d'autres services
            commerciaux. Stripe collecte et traite des données personnelles, y
            compris des informations d'identification sur les appareils qui se
            connectent à ses services. Stripe utilise ces informations pour
            exploiter et améliorer les services qu'il nous fournit, notamment
            pour la détection et la prévention de la fraude. Vous pouvez en
            savoir plus sur Stripe et ses activités de traitement via sa
            politique de confidentialité à l'adresse{" "}
            <a
              href="https://stripe.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Stripe - Politique de confidentialité
            </a>
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Droit d'Accès et de Modification
          </Typography>
          <Typography variant="body">
            Vous avez le droit d'accéder à vos informations personnelles et de
            demander des modifications si nécessaire. Pour exercer ces droits,
            veuillez nous contacter à {process.env.REACT_APP_EMAIL}
          </Typography>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Modification de la Politique de Confidentialité
          </Typography>
          <Typography variant="body">
            Nous nous réservons le droit de modifier cette Politique de
            Confidentialité à tout moment. Les modifications prendront effet
            immédiatement après leur publication sur le site. Nous vous
            encourageons à consulter régulièrement cette page pour rester
            informé(e) des éventuelles mises à jour.
          </Typography>
          <Typography variant="body" sx={{ mt: 2 }} component="div">
            En utilisant {window.location.hostname}, vous acceptez les termes de
            cette Politique de Confidentialité. Si vous avez des questions ou
            des préoccupations concernant notre politique de confidentialité,
            n'hésitez pas à nous contacter à {process.env.REACT_APP_EMAIL}
            <br />
            Merci de votre confiance.
          </Typography>
        </Grid2>
      </Grid2>
      <Footer id="FOOTER" />
    </>
  );
}
