import { useDelete, useGet, usePost, usePut } from "../apiFetch";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { errorAtom } from "../atom";
import { useCallback } from "react";
import { useRecoilState } from "recoil";

const useDisplayError = () => {
  const [error, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars
  return useCallback(
    (error) => {
      setError({ message: error, severity: "error" });
    },
    [setError]
  );
};

export const useListHorses = () => {
  const get = useGet();

  const { data, error, isLoading } = useQuery({
    queryKey: ["horses"],
    queryFn: () => {
      return get(`/horse`);
    },
  });

  if (error) {
    console.log("useListHorses error", data.error);
    return { horses: null, isLoading };
  }
  if (data) {
    if (data.data.error) {
      console.log("useListHorses error", data.data.error);
      return { horses: null, isLoading };
    }

    return { horses: data?.data || null, isLoading };
  }

  return { horses: null, isLoading };
};

export const useCreateHorse = (horses) => {
  const post = usePost();
  const displayError = useDisplayError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ horse }) => {
      const res = await post(`/horse`, horse);
      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onError: (error) => {
      displayError("Erreur lors de la création du cheval");
    },
    onSuccess: (horse) => {
      queryClient.invalidateQueries(["riders", "club_riders"]);
      queryClient.setQueryData(["horses"], () => {
        return {
          data: [...horses, horse],
          statusCode: 200,
        };
      });
    },
  });
};

export const useDeleteHorse = (horses) => {
  const del = useDelete();
  const displayError = useDisplayError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ horse }) => {
      const res = await del(`/horse/${horse.guid}`);
      if (res.statusCode !== 204) {
        throw res.error;
      } else {
        return horse;
      }
    },
    onError: (error) => {
      displayError("Erreur lors de la suppression du cheval");
    },
    onSuccess: (horse) => {
      queryClient.invalidateQueries(["riders", `horse_${horse.guid}`]);
      queryClient.setQueryData(["horses"], () => {
        const newHorses = horses.filter((h) => h.guid !== horse.guid);
        return {
          data: newHorses,
          statusCode: 200,
        };
      });
    },
  });
};

export const useUpdateHorse = (horses) => {
  const put = usePut();
  const displayError = useDisplayError();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ horse }) => {
      const res = await put(`/horse/${horse.guid}`, horse);
      if (res.statusCode === 200) {
        return res.data;
      } else {
        throw res.error;
      }
    },
    onError: (error) => {
      displayError("Erreur lors de la mise à jour du cheval");
    },
    onSuccess: (horse) => {
      queryClient.invalidateQueries([
        "riders",
        `horse_${horse.guid}`,
        `horse_riders_${horse.guid}`,
      ]);
      queryClient.setQueryData(["horses"], () => {
        const newHorses = horses.map((h) =>
          h.guid === horse.guid ? horse : h
        );
        return {
          data: newHorses,
          statusCode: 200,
        };
      });
    },
  });
};

export const useGetHorse = (horseGuid) => {
  const get = useGet();

  const { data, error, isLoading } = useQuery({
    queryKey: [`horse_${horseGuid}`],
    queryFn: () => {
      return get(`/horse/${horseGuid}`);
    },
  });

  if (error) {
    console.log("useGetHorse error", data.error);
    return { horse: null, isLoading };
  }
  if (data) {
    if (data.data.error) {
      console.log("useGetHorse error", data.data.error);
      return { horse: null, isLoading };
    }

    return { horse: data?.data || null, isLoading };
  }

  return { horse: null, isLoading };
};

export const useListHorseRiders = (horseGuid) => {
  const get = useGet();

  const { data, error, isLoading } = useQuery({
    queryKey: [`horse_riders_${horseGuid}`],
    queryFn: () => {
      return get(`/horse/${horseGuid}/riders`);
    },
  });

  if (error) {
    console.log("useListHorseRiders error", data.error);
    return { riders: null, isLoading };
  }
  if (data) {
    if (data.data.error) {
      console.log("useListHorseRiders error", data.data.error);
      return { riders: null, isLoading };
    }

    return { riders: data?.data || null, isLoading };
  }

  return { riders: null, isLoading };
};
