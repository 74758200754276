import { Grid2, Stack } from "@mui/material";
import React, { useCallback, useMemo } from "react";

import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";
import ContestManageActionBar from "./ContestManageActionBar";
import Filter from "../../Filter";
import Sort from "../../Sort";
import { t } from "@lingui/macro";
import { useNavigate } from "react-router";

export default function ContestManageBar({
  onChange,
  contest,
  onCancelContest,
  onArchiveContest,
  onCloseRegistrations,
  onChangeMailingMode,
  isUserAllowedToArchive,
  onChangeFoldings,
  folds,
  onSort,
  counters,
}) {
  const navigate = useNavigate();

  const categories = useMemo(() => {
    const categories = [];
    if (!contest) return categories;

    if (contest.multiDays) {
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(contest?.categoriesMultiDays)) {
        for (const category of value) {
          if (!categories.includes(category)) {
            categories.push(category);
          }
        }
      }
    } else {
      for (const category of contest?.categories) {
        if (!categories.includes(category)) {
          categories.push(category);
        }
      }
    }
    return categories;
  }, [contest]);

  const filterItems = useMemo(() => {
    const filters = [];
    filters.push({ type: "header", label: "Inscriptions" });
    filters.push({ type: "item", label: "En attentes", value: "sub_0" });
    filters.push({ type: "item", label: "Enregistrées", value: "sub_1" });
    filters.push({
      type: "item",
      label: "Refusées par le club",
      value: "sub_2",
    });
    filters.push({
      type: "item",
      label: "Annulées par le cavalier",
      value: "sub_3",
    });
    filters.push({ type: "header", label: "Catégories" });
    for (const category of categories) {
      filters.push({
        type: "item",
        label: t({ id: category }),
        value: category,
      });
    }
    filters.push({ type: "header", label: "Facturation" });
    filters.push({ type: "item", label: "A facturer", value: "bill_0" });
    filters.push({ type: "item", label: "Payées", value: "bill_1" });
    filters.push({ type: "item", label: "Offertes", value: "bill_2" });
    filters.push({ type: "item", label: "Remboursées", value: "bill_3" });

    return filters;
  }, [categories]);

  const breadcrumbItems = useMemo(() => {
    return [
      { label: "Accueil", link: "/" },
      { label: "Concours", link: "/contests" },
      { label: contest?.place },
    ];
  }, [contest]);

  const handleEdit = useCallback(() => {
    navigate(`/modify-contest/${contest.guid}`);
  }, [navigate, contest]);

  return (
    <>
      <Grid2
        sx={{
          alignItems: "baseline",
        }}
        size={{ xs: 12, sm: 12, md: 7, lg: 5, xl: 4 }}
      >
        <Stack spacing={1} sx={{ width: "100%" }}>
          <BreadcrumbComponent items={breadcrumbItems} />
          <ContestManageActionBar
            contest={contest}
            onCancelContest={onCancelContest}
            onArchiveContest={onArchiveContest}
            onChangeMailingMode={onChangeMailingMode}
            onEdit={handleEdit}
            isUserAllowedToArchive={isUserAllowedToArchive}
            folds={folds}
            onChangeFoldings={onChangeFoldings}
            onCloseResgistrations={onCloseRegistrations}
            counters={counters}
          />
        </Stack>
      </Grid2>
      <Grid2
        textAlign={"right"}
        sx={{ pr: 4, pl: 4, alignSelf: "end" }}
        size={{ xs: 12, sm: 12, md: 5, lg: 7, xl: 8 }}
      >
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: {
              xl: "end",
              lg: "end",
              md: "end",
              sm: "center",
              xs: "center",
            },
            mt: {
              sm: 1,
              md: 0,
              xs: 1,
            },
          }}
        >
          <Filter
            items={filterItems}
            allowFreeValue={true}
            localStorageKey="contest-manage-filter"
            onChange={onChange}
          />
          <Sort
            defaultSort="sub_date_desc"
            values={[
              "sub_date_desc",
              "sub_date_asc",
              "first_name_desc",
              "first_name_asc",
              "last_name_desc",
              "last_name_asc",
            ]}
            localStorageKey="contest-manage-sort"
            onChange={onSort}
          />
        </Stack>
      </Grid2>
    </>
  );
}
