import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  CircularProgress,
  CssBaseline,
  Grid2,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";
import { useGetConfig, useIsPCO } from "../../../Features/config";

import BreadcrumbComponent from "../../BreadcrumbComponent/BreadcrumbComponent";
import CategoryIcon from "@mui/icons-material/Category";
import GroupIcon from "@mui/icons-material/Group";
import PaletteIcon from "@mui/icons-material/Palette";
import PaymentIcon from "@mui/icons-material/Payment";
import StoreIcon from "@mui/icons-material/Store";
import { errorAtom } from "../../../Features/atom";
import { useNavigate } from "react-router";
import { usePost } from "../../../Features/apiFetch";
import { useRecoilState } from "recoil";

export default function AdministrationHome() {
  const navigate = useNavigate();
  const post = usePost();
  const isPCO = useIsPCO();
  const [isLoading, setIsLoading] = useState(false);
  const [_, setError] = useRecoilState(errorAtom); // eslint-disable-line no-unused-vars

  const handleManageUsers = useCallback(() => {
    navigate("/administration/users");
  }, [navigate]);

  const handleManageContests = useCallback(() => {
    navigate("/administration/contest-parameters");
  }, [navigate]);

  const handleManageSettings = useCallback(() => {
    navigate("/administration/club-settings");
  }, [navigate]);

  const handleManageBilling = useCallback(() => {
    navigate("/administration/billing");
  }, [navigate]);

  const handleManagePayments = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await post("/billing/account/club-client-session");
      if (res.statusCode === 200) {
        window.location.href = res.data.url;
      } else {
        setError({
          message:
            "Erreur lors de la redirection vers le compte Stripe. Veuillez réessayer.",
          severity: "error",
        });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, [post, setError]);

  const breadcrumbItems = useMemo(() => {
    return [{ label: "Accueil", link: "/" }, { label: "Administration" }];
  }, []);

  const config = useGetConfig();

  return (
    <>
      <Grid2
        container
        component="main"
        sx={{
          maxWidth: "100%",
          marginTop: "86px",
        }}
      >
        <CssBaseline />
        <Grid2 size={12}>
          <BreadcrumbComponent items={breadcrumbItems} />
        </Grid2>
        <Grid2
          size={{ xs: false, sm: false, md: 3, lg: 5, xl: 6 }}
          sx={{
            pl: 4,
            backgroundImage: `url(${config?.notAllowedBackground})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "calc(100vh - 86px - 192px)",
            display: {
              xs: "none",
              sm: "none",
              md: "inherit",
            },
          }}
        />
        <Grid2
          textAlign={"center"}
          sx={{ px: 4, mb: 0, display: "flex", justifyContent: "center" }}
          alignItems="center"
          size={{ xs: 12, sm: 12, md: 9, lg: 7, xl: 6 }}
        >
          <Grid2
            container
            spacing={2}
            sx={{
              maxWidth: 700,
              margin: "auto",
              mt: { sm: 3, xs: 3, md: "initial" },
            }}
          >
            <Grid2
              size={12}
              sx={{ minWidth: { xs: "100%", sm: "100%", md: "auto" }, mb: 1 }}
            >
              <Card sx={{ height: "100%" }} variant="outlined">
                <CardActionArea onClick={handleManageUsers}>
                  <CardHeader
                    title={
                      <>
                        <GroupIcon sx={{ verticalAlign: "text-top" }} />{" "}
                        Utilisateurs
                      </>
                    }
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      Gérer la liste des utilisateurs ainsi que leurs droits
                      d'accès.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid2>
            <Grid2
              size={6}
              sx={{ minWidth: { xs: "100%", sm: "100%", md: "auto" }, my: 1 }}
            >
              <Card sx={{ height: "100%" }} variant="outlined">
                <CardActionArea onClick={handleManageContests}>
                  <CardHeader
                    title={
                      <>
                        <CategoryIcon sx={{ verticalAlign: "text-top" }} />{" "}
                        Disciplines et catégories
                      </>
                    }
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      Gérer la liste des disciplines et des catégories
                      disponibles lors de la création des concours.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid2>
            <Grid2
              size={6}
              sx={{ minWidth: { xs: "100%", sm: "100%", md: "auto" }, mt: 1 }}
            >
              <Card sx={{ height: "100%" }} variant="outlined">
                <CardActionArea onClick={handleManageSettings}>
                  <CardHeader
                    title={
                      <>
                        <PaletteIcon sx={{ verticalAlign: "text-top" }} /> Club
                        et apparence
                      </>
                    }
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      Gérer les informations sur le club.
                      <br />
                      Gérer les visuels du site.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid2>
            <Grid2
              size={isPCO ? 12 : 6}
              sx={{
                minWidth: { xs: "100%", sm: "100%", md: "auto" },
                mt: 1,
                mb: 2,
              }}
            >
              <Card sx={{ height: "100%" }} variant="outlined">
                <CardActionArea onClick={handleManageBilling}>
                  <CardHeader
                    title={
                      <>
                        <StoreIcon sx={{ verticalAlign: "text-top" }} />{" "}
                        Facturation
                      </>
                    }
                  />
                  <CardContent>
                    <Typography variant="body2" color="text.secondary">
                      Gérer le compte Stripe du club pour percevoir les
                      paiements en ligne.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid2>
            {!isPCO && (
              <Grid2
                size={6}
                sx={{
                  minWidth: { xs: "100%", sm: "100%", md: "auto" },
                  mt: 1,
                  mb: 2,
                }}
              >
                <Box sx={{ position: "relative" }}>
                  <Card sx={{ height: "100%" }} variant="outlined">
                    <CardActionArea
                      onClick={handleManagePayments}
                      disabled={isLoading}
                    >
                      <CardHeader
                        title={
                          <>
                            <PaymentIcon
                              sx={{ verticalAlign: "text-top" }}
                              color={isLoading ? "disabled" : ""}
                            />{" "}
                            Paiements
                          </>
                        }
                      />
                      <CardContent>
                        <Typography variant="body2" color="text.secondary">
                          Informations de paiement du club.
                          <br />
                          Historique des paiements et factures.
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                  {isLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-6px",
                        marginLeft: "-10px",
                      }}
                    />
                  )}
                </Box>
              </Grid2>
            )}
          </Grid2>
        </Grid2>
      </Grid2>
    </>
  );
}
