import { Button, CircularProgress, Typography } from "@mui/material";
import { useCallback, useMemo } from "react";
import {
  useDeleteCalendarEvent,
  useDeleteRecurringCalendarEventOccurence,
} from "../../../../Features/Mutations/horsecalendar";

import { Stack } from "@mui/system";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const HorseCalendarEditRecurrent = ({
  calendarEvent,
  horseGuid,
  currentDate,
}) => {
  const [isPosting, setIsPosting] = useState(false);

  const navigate = useNavigate();
  const mutationDeleteOccurence = useDeleteRecurringCalendarEventOccurence(
    horseGuid,
    calendarEvent.eventGuid,
    currentDate
  );
  const mutationDeleteEvent = useDeleteCalendarEvent(
    horseGuid,
    calendarEvent.eventGuid
  );

  const recurrencePatternText = useMemo(() => {
    let text = "A lieu";
    const recurrences = calendarEvent.recurrenceInterval;

    if (calendarEvent.recurrencePattern === "Daily") {
      if (recurrences > 1) {
        text = `Tous les ${recurrences} jours`;
      } else {
        text = "Tous les jours";
      }
    } else if (calendarEvent.recurrencePattern === "Weekly") {
      if (recurrences > 1) {
        text = `Toutes les ${recurrences} semaines`;
      } else {
        text = "Toutes les semaines";
      }
    } else if (calendarEvent.recurrencePattern === "Monthly") {
      if (recurrences > 1) {
        text = `Tous les ${recurrences} mois`;
      } else {
        text = "Tous les mois";
      }
    } else if (calendarEvent.recurrencePattern === "Yearly") {
      if (recurrences > 1) {
        text = `Tous les ${recurrences} ans`;
      } else {
        text = "Tous les ans";
      }
    }
    return text;
  }, [calendarEvent.recurrenceInterval, calendarEvent.recurrencePattern]);

  const formatDate = useMemo(() => {
    return moment(currentDate).format("DD MMMM YYYY");
  }, [currentDate]);

  const handleEditAllOccurrences = useCallback(() => {
    navigate(
      `/horse-calendar/${horseGuid}/edit-recurrent/${calendarEvent.eventGuid}`
    );
  }, [calendarEvent?.eventGuid, horseGuid, navigate]);

  const handleEditThisOccurrence = useCallback(() => {
    navigate(
      `/horse-calendar/${horseGuid}/override-recurrent/${calendarEvent.eventGuid}?date=${currentDate}`
    );
  }, [calendarEvent?.eventGuid, currentDate, horseGuid, navigate]);

  const handleDeleteOccurence = useCallback(async () => {
    setIsPosting(true);
    try {
      await mutationDeleteOccurence.mutateAsync();
    } catch (error) {
      console.log("Error deleting event", error);
    }
    setIsPosting(false);

    navigate(`/horse-calendar/${horseGuid}`);
  }, [horseGuid, mutationDeleteOccurence, navigate]);

  const handleDeleteEvent = useCallback(async () => {
    setIsPosting(true);
    try {
      await mutationDeleteEvent.mutateAsync();
    } catch (error) {
      console.log("Error deleting event", error);
    }
    setIsPosting(false);

    navigate(`/horse-calendar/${horseGuid}`);
  }, [horseGuid, mutationDeleteEvent, navigate]);

  return (
    <>
      {calendarEvent.description &&
        calendarEvent.description.split("\n").map((line, index) => (
          <Typography key={index} variant="body2">
            {line}
          </Typography>
        ))}
      <Typography variant="body2">{formatDate}</Typography>
      <Typography variant="body2" sx={{ color: "gray", fontStyle: "italic" }}>
        {recurrencePatternText}
      </Typography>
      {isPosting && (
        <CircularProgress
          size={24}
          sx={{
            m: 1,
          }}
        />
      )}
      <Stack
        direction="column"
        spacing={2}
        sx={{
          mt: isPosting ? 0 : 4,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleEditThisOccurrence}
          disabled={isPosting}
        >
          Modifier cette occurrence
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleEditAllOccurrences}
          disabled={isPosting}
        >
          Modifier toutes les occurrences
        </Button>
        <Button
          color="warning"
          variant="contained"
          onClick={handleDeleteOccurence}
          disabled={isPosting}
        >
          Supprimer cette occurrence
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={handleDeleteEvent}
          disabled={isPosting}
        >
          Supprimer toutes les occurrences
        </Button>
      </Stack>
    </>
  );
};

export default HorseCalendarEditRecurrent;
