import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useCallback, useMemo, useState } from "react";

import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import PersonIcon from "@mui/icons-material/Person";

const RiderCard = ({
  rider,
  onEdit,
  onDelete,
  expanded,
  onExpand,
  onDetach,
}) => {
  const [isPosting, setIsPosting] = useState(false);

  const isParent = useMemo(
    () => rider.parentGuid === null || rider.parentGuid === rider.guid,
    [rider]
  );

  const handleEdit = useCallback(() => {
    onEdit(rider);
  }, [onEdit, rider]);

  const handleDelete = useCallback(async () => {
    setIsPosting(true);
    await onDelete(rider);
    setIsPosting(false);
  }, [onDelete, rider]);

  const handleDetach = useCallback(async () => {
    setIsPosting(true);
    await onDetach(rider);
    setIsPosting(false);
  }, [onDetach, rider]);

  return (
    <Accordion
      sx={{
        minWidth: 275,
        ml: 4,
        mr: 4,
        width: "100%",
      }}
      variant="outlined"
      expanded={expanded === rider.guid}
      onChange={() => {
        onExpand(rider);
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {isParent ? <AdminPanelSettingsIcon /> : <PersonIcon />}
        <Typography sx={{ textTransform: "capitalize", ml: 1 }}>
          {rider.name}
        </Typography>
      </AccordionSummary>
      {rider.email && (
        <AccordionDetails>
          <Typography variant="subtitle1" sx={{ ml: 2 }}>
            {rider.email}
          </Typography>
        </AccordionDetails>
      )}
      <AccordionActions sx={{ justifyContent: "space-between" }}>
        <div>
          <IconButton onClick={handleEdit} disabled={isPosting}>
            <EditIcon />
          </IconButton>
          {!isParent && (
            <IconButton
              disabled={isPosting || !rider.email}
              onClick={handleDetach}
            >
              <LinkOffIcon />
            </IconButton>
          )}
          {!isParent && (
            <IconButton onClick={handleDelete} disabled={isPosting}>
              <DeleteIcon />
            </IconButton>
          )}
        </div>
      </AccordionActions>
    </Accordion>
  );
};

export default RiderCard;
